import i18n from '@/libs/i18n'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import { AvailableFields } from '@/store/search/AvailableFields'
import Notifications from '@/store/notifications/notifications'

interface SuggestionField {
  label: string,
  status: boolean,
  useAggregate: boolean
}
interface SuggestionFieldList {
  [key: string]: SuggestionField
}

export default {
  namespaced: true,
  state: {
    suggestion: {
    },
    aggregationsToSuggest: {
    },
    availableFields: [],
  },
  mutations: {
    setSuggestions(state:any, data:any) {
      data.forEach((suggestionLabel:any) => {
        state.suggestion[suggestionLabel.label].status = suggestionLabel.status
        state.suggestion[suggestionLabel.label].useAggregate = suggestionLabel.useAggregate
      })
    },
    setSuggestionFields(state:any, data:any) {
      const suggestions: SuggestionFieldList = {}
      data.push('suggestions')
      data.forEach((field : string) => {
        suggestions[field] = {
          label: field,
          status: false,
          useAggregate: false,
        }
      })
      state.suggestion = suggestions
    },
  },
  actions: {
    getData(context: any, searchId: string) {
      SearchApiRequest.getAvailableFields(searchId).then((result: any) => {
        context.commit('setSuggestionFields', AvailableFields.getAvailableFieldsFromResponse(result.data))
        SearchApiRequest.getSuggestions(searchId)
          .then(suggestionResult => {
            context.commit('setSuggestions', suggestionResult.data)
          })
          .catch(error => {
            Notifications.commit('addError', error, { root: true })
          })
      })
    },
    async store(context:any, data:any) {
      SearchApiRequest.setSuggestions({
        searchId: data.searchId,
        suggestions: data.suggestions,
      })
        .then(() => {
          Notifications.commit('addPopupInfo', {
            name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
            message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
          }, { root: true })
          context.dispatch('getData', data.searchId)
        })
        .catch(error => {
          Notifications.commit('addPopupError', error, { root: true })
        })
    },
  },

}
