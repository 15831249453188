import Vue from 'vue'
// @ts-ignore
import Vuesax from 'vuesax'

import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Vuesax styles
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(Vuesax)

// Composition API
Vue.use(VueCompositionAPI)

require('dotenv').config()

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
// Vue.config.errorHandler = (err, vm, info) => {
//   console.error('Error handler')
//   console.error(err)
//   console.error(vm)
//   console.error(info)
//   return false
// }

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
