export default {
  menu: {
    smartSearch: 'Smart Search',
    smartSearchV3: 'Search',
    smartSearchDashboard: 'Pulpit',
    searchCatalog: 'Katalog',
    searchReports: 'Raporty',
    searchPhrasesReport: 'Frazy',
    searchPhrasesReportWithNoResults: 'Puste wyszukiwania',
    searchProductsReport: 'Znalezionych produktów',
    searchSynonyms: 'Synonimy',
    searchSettings: 'Ustawienia',
    searchImprovements: 'Udoskonalenia',
    searchInstanceSettings: 'Zarządzanie instancjami',
    searchInstancesList: 'Lista instancji',
    searchTester: 'Testowanie wyników',
    searchSubscription: 'Subskrypcja',
    searchBlockedPhrase: 'Frazy blokowane',
    searchImmutablePhrase: 'Frazy nieodmienne',
    mailing: 'Mailing',
    htmlTemplate: 'Szablon html',
    searchWeights: 'Istotność pól',
    searchSort: 'Sortowanie',
    searchFacetedMenu: 'Menu fasetowe',
    searchAuthorizationSettings: 'Dostęp do API',
    searchInstances: 'Instancje wyszukiwarek',
    searchSuggestion: 'Sugestie',
    searchSharedFields: 'Udostępnione pola',
    searchTry: 'Testowanie wyników',
    appV3Settings: 'Ustawienia',
    appV3Labels: 'Etykiety',
    searchV3: {
      reports: {
        main: 'Raporty',
        emptySearchPhrasesReport: 'Puste wyszukiwania',
        allPhrasesReport: 'Raport fraz',
        allReports: 'Pulpit',
      },
    },
    smartStylist: {
      main: 'Stylista',
      reports: 'Raporty',
      stylizationsList: 'Lista stylizacji',
    },
    catalogInstances: 'Lista katalogów',
    searchApp: 'Aplikacja search',
  },
  suggestion: {
    header: 'Ustawienia sugestii',
    subHeader: 'Pola z których są generowane sugestie',
    shortDescription: 'Wskaż pola z których system będzie generował sugestie',
    description: 'Funkcjonalność pozwalająca na określenie pól z których generowane będą sugestie dla wpisywanej frazy.\n'
      + '              Dzięki niemu można w prosty sposób wskazać pola, które odpowiadają za wyświetlanie propozycji dopasowanych do wpisanej frazy.\n'
      + '              Dzięki temu użytkownicy łatwiej znajdą to, czego szukają, co wpłynie pozytywnie na ich zadowolenie z korzystania z wyszukiwarki.',
    warningHeader: 'Uwaga',
    warningDescription: 'Zmiany, będą widoczne po ponownym przetworzeniu katalogu.',
    tableHeaders: {
      field: 'Pole katalogu',
      dataFromAggregate: 'Użyć danych z filtrów do sugestii?',
      status: 'Włączone',
      actions: 'Zmień',
    },
    changeTooltip: 'Zmień',
    descriptionButton: {
      hide: 'Ukryj',
      show: 'Pokaż',
      description: 'opis',
    },
  },
  sharedFields: {
    header: 'Ustawienia udostępnionych pól',
    description: 'Tutaj możesz zdecydować jakie pola produktów będą udostępnione przez API.',
    tableHeaders: {
      field: 'Pole katalogu',
      status: 'Włączone',
      actions: 'Zmień',
    },
    selectCatalog: 'Wybierz katalog: ',
    changeTooltip: 'Zmień',
  },
  searchSelect: {
    description: 'Wybierz search',
  },
  alert: {
    success: 'Sukces',
    error: 'Bład',
    warning: 'Uwaga',
  },
  notifications: {
    copiedKey: {
      name: 'Klucz do API',
      message: 'Skopiowano do schowka',
    },
    copiedCustomerSymbol: {
      name: 'Symbol Klienta',
      message: 'Skopiowano do schowka',
    },
    copiedUrl: {
      name: 'Adres URL',
      message: 'Skopiowano do schowka',
    },
    apiKeyReset: {
      name: 'Klucz do API',
      message: 'Zresetowano',
    },
  },
  search: {
    dataSources: {
      allData: 'wszystkie dane',
      autocomplete: 'autocomplete',
      serp: 'wyniki wyszukiwania',
      bestseller: 'bestsellery',
      popular: 'popularne produkty',
    },
    header: {
      dataSource: 'Źródło',
      dateRange: 'Zakres dat',
    },
    headers: {
      populars: 'Najpopularniejsze frazy',
      typeDistribution: 'Źródło wyszukiwania',
      zeroSearch: 'Frazy bez wyników',
      mostClicked: 'Najczęściej klikane frazy',
      bestConversion: 'Frazy z najliczniejszą sprzedażą',
      bestConversionAmount: 'Frazy z najwyższą sprzedażą',
      searchCount: 'Liczba wyszukań',
      searchInstances: 'Instancje wyszukiwarek',
    },
    statistics: {
      conversions: 'Konwersja',
      phrase: 'Fraza',
      count: 'Liczba wyszukań',
      clicks_count: 'Liczba kliknięć',
      saleSum: 'Suma sprzedaży',
      saleAmount: 'Liczba sprzedaży',
    },
    searchGraphBar: {
      headers: {
        searchCount: 'Wyszukania',
        searchClickCount: 'Kliknięcia',
        searchClickThroughRate: 'CTR',
        salesAmount: 'Zamówienia',
        salesTotal: 'Sprzedaż',
        salesConversion: 'Konwersja',
      },
      tooltip: {
        searchCount: 'Wyszukania',
        searchClickCount: 'Kliknięcia',
        searchClickThroughRate: 'CTR',
        salesAmount: 'Zamówienia',
        salesTotal: 'Sprzedaż',
        salesConversion: 'Konwersja',
      },
    },
    searchPhrases: {
      header: 'Analiza wyszukiwanych fraz',
      download: 'Pobierz raport',
      phrase: 'Fraza',
      searchCount: 'Wyszukania',
      clicks: 'Kliknięcia',
      ctr: 'CTR',
      conversion: 'Konwersja',
      salesCount: 'Zakupy',
      salesUnitsSold: 'Sprzedane',
      salesTotal: 'Suma',
    },
    searchPhrasesWithNoResults: {
      header: 'Lista wyszukiwanych fraz które nie zwróciły wyników',
      download: 'Pobierz raport',
      phrase: 'Fraza',
      searchCount: 'Wyszukania',
    },
    searchProducts: {
      header: 'Analiza wyszukiwanych produktów',
      download: 'Pobierz raport',
      productId: 'ID produktu',
      productName: 'Nazwa produktu',
      views: 'Wyświetlenia',
      clicks: 'Kliknięcia',
      conversion: 'Konwersja',
      salesCount: 'Zakupy',
      salesUnitsSold: 'Sprzedane',
      salesTotal: 'Suma',
    },
    synonyms: {
      header: 'Synonimy',
      addNew: 'Dodaj synonim',

      createPopupHeader: 'Dodaj synonim',
      createPopupSynonymsLabel: 'Jeśli użytkownik wprowadzi frazę',
      addSynonymInputTooltip: 'Dodaj kolejny synonim',
      createPopupBaseWordLabel: 'wyszukaj frazę',
      or: 'lub',

      insertSuccessfulNotificationTitle: 'Świetnie!',
      insertSuccessfulNotification: 'Dane zostały poprawnie zapisane',

      synonymColumnHeader: 'Po wpisanu frazy',
      baseWordColumnHeader: 'Wyszukaj',
      optionsHeader: 'Opcje',
      editTooltip: 'Edytuj',
      deleteTooltip: 'Usuń',

      deleteConfirmationHeader: 'Usuwanie synonimu',
      deleteConfirmationText: 'Czy na pewno chcesz usunąć synonim?',
      deleteConfirmationAcceptText: 'Tak',
      deleteConfirmationCancelText: 'Anuluj',

      editPopupHeader: 'Edycja synonimu',
      editPopupSynonymLabel: 'Jeśli użytkownik wprowadzi frazę',
      editPopupBaseWordLabel: 'wyszukaj',
      editPopupSave: 'Zapisz',
      editPopupCancel: 'Anuluj',

      saveSuccessfulNotificationTitle: 'Świetnie!',
      saveSuccessfulNotification: 'Dane zostały poprawnie zapisane',

      popupSynonymInvalid: 'Błędny synonim',
      popupBaseWordInvalid: 'Błędna fraza',
    },
    blockedPhrase: {
      header: 'Zarządzaj frazami blokowanymi',
      addNew: 'Dodaj blokowaną frazę',

      createPopupHeader: 'Dodaj blokowaną frazę',
      createPopupPhraseLabel: 'Jeśli użytkownik wprowadzi tę frazę, system pominie ją podczas wyszukiwania (nie wyświetli dla niej żadnych wyników)',

      addInputTooltip: 'Dodaj kolejną blokowaną frazę',
      or: 'lub',

      insertSuccessfulNotificationTitle: 'Świetnie!',
      insertSuccessfulNotification: 'Dane zostały poprawnie zapisane',

      phraseColumnHeader: 'Frazy blokowane',
      optionsHeader: 'Opcje',
      editTooltip: 'Edytuj',
      deleteTooltip: 'Usuń',

      deleteConfirmationHeader: 'Usuwanie blokowanej frazy',
      deleteConfirmationText: 'Czy na pewno chcesz usunąć blokowaną frazę?',
      deleteConfirmationAcceptText: 'Tak',
      deleteConfirmationCancelText: 'Anuluj',

      editPopupHeader: 'Edycja blokowanej frazy',
      editPopupBlockedPhraseLabel: 'Fraza do zablokowania',
      editPopupSave: 'Zapisz',
      editPopupCancel: 'Anuluj',

      saveSuccessfulNotificationTitle: 'Świetnie!',
      saveSuccessfulNotification: 'Dane zostały poprawnie zapisane',

      popupInvalid: 'Nieprawidłowa blokowana fraza',
    },
    immutablePhrase: {
      header: 'Zarządzaj frazami nieodmiennymi',
      addNew: 'Dodaj frazę nieodmienną',

      createPopupHeader: 'Dodaj frazę nieodmienną',
      createPopupLabel: 'Jeśli użytkownik wprowadzi tę frazę, system nie odmieni jej podczas wyszukiwania (rekomendowane dla słów nieodmiennych, nazw marek, wyrazów obcych etc.)',

      addInputTooltip: 'Dodaj kolejną frazę nieodmienną',
      or: 'lub',

      insertSuccessfulNotificationTitle: 'Świetnie!',
      insertSuccessfulNotification: 'Dane zostały poprawnie zapisane',

      phraseColumnHeader: 'Nieodmienne frazy',
      optionsHeader: 'Opcje',
      editTooltip: 'Edytuj',
      deleteTooltip: 'Usuń',

      deleteConfirmationHeader: 'Usuwanie frazy nieodmiennej',
      deleteConfirmationText: 'Czy na pewno chcesz usunąć nieodmienną frazę?',
      deleteConfirmationAcceptText: 'Tak',
      deleteConfirmationCancelText: 'Anuluj',

      editPopupHeader: 'Edycja frazy nieodmiennej',
      editPopupLabel: 'Fraza nieodmienna',
      editPopupSave: 'Zapisz',
      editPopupCancel: 'Anuluj',

      saveSuccessfulNotificationTitle: 'Świetnie!',
      saveSuccessfulNotification: 'Dane zostały poprawnie zapisane',

      popupInvalid: 'Nieprawidłowa nieodmienna fraza',
    },
    settings: {
      header: 'Ustawienia',
      generalTabTitle: 'Ogólne',
      gaTabTitle: 'Google Analytics',
      enabled_on: 'Włączony',
      enabled_off: 'Wyłączony',
      showUnavailable: 'Pokaż także produkty niedostępne',
      showOnlyWithImages: 'Pokaż tylko produkty z obrazkiem',
      showVariantsOfProduct: 'Pokaż zgrupowany produkt z wariantami',
      showAllVariantsOfProducts: 'Pokaż wszystkie pasujące warianty produktów',
      itemsPerPage: 'Liczba produktów na stronę',
      analyticsEnabled: 'Raportowanie w Google Analytics włączone',
      analyticsDisabled: 'Raportowanie w Google Analytics wyłączone',
      analyticsMeasurementId: 'Identyfikator śledzenia',
      analyticsMeasurementIdTooltip: 'Np.: UA-000000-1',
      analyticsUseSiteSearchHeader: 'Sposób wysyłania statystyk',
      analyticsUseSiteSearchTooltip: 'Wybierz "event", jeśli chcesz skorzystać ze zdarzeń. Wybierz "pageview", jeśli chcesz korzystać z GA Site Search.',
      analyticsUseCustomEvents: 'event',
      analyticsUseSiteSearch: 'pageview',

      save: 'Zapisz ustawienia',
      unsaved: 'Masz niezapisane ustawienia.',
      errorGet: 'Wystąpił problem podczas pobierania ustawień. Spróbuj później.',
      success: 'Ustawienia zostały pomyślnie zapisane. Zmiany na twojej stronie powinny sie pojawić do 15 minut.',
      errorSet: 'Wystąpił problem podczas zapisywania ustawień. Spróbuj później.',

      recentQueries: {
        title: 'Ostatnie wyszukiwania',
        on: 'Włączone',
        off: 'Wyłączone',
        amount: 'Liczba wyświetlanych zapytań',
      },
      popularQueries: {
        title: 'Najpopularniejsze wyszukiwania',
        on: 'Włączone',
        off: 'Wyłączone',
        durationInfo: 'Pokaż wyszukiwania z ostatnich:',
        duration: {
          1: '1 dnia',
          3: '3 dni',
          7: '7 dni',
          30: '30 dni',
          60: '60 dni',
          90: '90 dni',
        },
        amount: 'Liczba wyświetlanych zapytań',
      },
      functionNotAvailable: 'Ta funkcja jest dla Ciebie niedostępna',
      functionNotAvailableContact: 'Jeżeli chcesz ją uruchomić, napisz na',
      functionNotAvailableContactOr: 'lub',
      functionNotAvailableContactScheduleMeeting: 'umów się z naszym doradcą',
    },
    tester: {
      inputPlaceholder: 'Wpisz frazę do testów',
      noResultsHeader: 'Brak wyników',
      noResults: 'Dla tej frazy nie znaleźliśmy żadnych pasujących wyników. Może to być spowodowane brakiem produktów lub błędną konfiguracją.',
    },
    instanceList: {
      mainHeader: 'Lista wyszukiwarek',
      mainDescription: 'Wyszukiwarki pozwalają Ci na zdefiniowanie podstawowych parametrów przeszukiwania katalogu. Dzięki liście wyszukiwarek możesz ustawić różne zachowania wyszukiwania dla wspólnego katalogu. ',
      addNew: 'Utwórz nową wyszukiwarkę',
      header: {
        id: 'Identyfikator wyszukiwarki',
        name: 'Nazwa wyszukiwarki',
        catalog: 'Podłaczony katalog',
      },
    },
    instance: {
      header: 'Lista instancji wyszukiwarek',
      addNew: 'Stwórz nową instancję',
      tableHeader: {
        instanceName: 'Nazwa instancji wyszukiwarki',
        instanceId: 'Id instancji wyszukiwarki',
        catalogName: 'Nazwa katalogu',
        catalogId: 'Id przypisanego katalogu',
        options: 'Opcje',
      },
      add: {
        header: 'Tworzenie nowej instancji wyszukiwarki',
        description: 'Witaj w kreatorze tworzenia nowej instancji wyszukiwarki. System dopuszcza utworzenie wielu różnie skonfigurowanych wyszukiwarek. Ich liczba zależy od Twojego planu.',
        label: {
          instanceName: 'Wprowadź nazwę instancji wyszukiwarki',
          selectCatalog: 'Wybierz katalog z listy skonfigurowanych katalogów',
        },
        submit: 'Zapisz',
        error: {
          message: 'Wystąpił błąd podczas zapisu instancji wyszukiwarki',
        },
      },
      details: {
        main: {
          header: 'Wyszukiwarka id: ',
          content: 'Optymalizacja wyszukiwarki produktów jest kluczowa dla ułatwienia klientom odnajdywania dokładnie tego, czego szukają. Dzięki temu obszarowi masz możliwość dostosowania i ulepszenia działania wyszukiwarki w Twoim sklepie. Sprawdź i dostosuj główną konfigurację instancji wyszukiwarki. Sprawdź czy konfiguracja mapowania katalogu do naszych struktur danych zawiera wszystkie atrybuty niezbędne do szukania w Twoich produktach'
        },
        settingsHeader: 'Podstawowe ustawienia wyszukiwarki',
        settingsDescription: 'Tutaj znajdziesz podstawowe ustawienia Twojej wyszukiwatki',
        fields: {
          productsPerPage: 'Liczba produktów na stronę',
          popularQueriesLimit: 'Limit zwracanych popularnych fraz',
          popularQueriesInterval: 'Zakres czasu zwracanych fraz',
        },
        save: 'Zapisz',
      },
    },
  },
  profile: {
    logout: 'Wyloguj się',
    goToStage: 'Przejdź do Stage Mode',
  },
  subscription: {
    card_title_new_account: 'Nowa subskrypcja',
    first_name: 'Imię',
    last_name: 'Nazwisko',
    address1: 'Adres',
    city: 'Miasto',
    country: 'Kraj',
    postal_code: 'Kod pocztowy',
    company_name: 'Nazwa firmy',
    vat_number: 'Numer NIP',
    save_card: 'Zapisz kartę',
    subscription_active: 'Twoja subskrypcja jest aktywna',
    card_title_new_subscription: 'Subskrybuj',
    need_subscription: 'Twoja subskrypcja jest nieaktywna',
    activate: 'Aktywuj',
    ends_at: 'Nowy okres rozliczeniowy zacznie się',
    offer: {
      search_shoptet: {
        title: 'Smart Search',
        subtitle: 'Najlepsza wyszukiwarka na rynku pod Shoptet!',
        features: [
          'Autouzupełnianie pola wyszukiwania - mała wyszukiwarka',
          'Zaawansowana strona wyników wyszukiwania (SERP)',
          'Obsługa synonimów',
          'Rozpoznawanie błędów we wpisywanych hasłach',
          'Odmiana językowa',
          'Filtrowanie wyników',
          'Zasilanie wyszukiwarki danymi z katalogu produktowego',
          'Uniwersalny szablon wyników wyszukiwania',
          'Integracja z Google Analytics',
        ],
      },
      netto: 'Wszystkie ceny podane w naszej ofercie sa cenami netto.',
    },
    validation: {
      number: 'Numer karty jest niepoprawny.',
      month: 'Miesiąc jest niepoprawny.',
      year: 'Rok jest niepoprawny.',
      first_name: 'Imię jest niepoprawne.',
      last_name: 'Nazwisko jest niepoprawne.',
      address1: 'Adres jest niepoprawny.',
      city: 'Miasto jest niepoprawne.',
      country: 'Kraj jest niepoprawny.',
      postal_code: 'Kod pocztowy jest niepoprawny.',
      company_name: 'Nazwa firmy jest niepoprawna.',
      vat_number: 'NIP jest niepoprawny.',
    },
  },
  catalog: {
    header: 'Dodanie katalogu produktowego',
    description: 'Skonfiguruj sposób zasialnia katalogu produktowego zgodnie z naszą specyfikacją (sprawdź specyfikacje',
    here: 'tutaj)',
    provideName: 'Wprowadź nazwę katalogu',
    provideLink: 'Wprowadź adres URL katalogu w formacie xml (wymagany format Google Merchants, maksymalny rozmiar pliku 512 MB)',
    invalidLinkProvided: 'Wprowadzono niepoprawny adres URL',
    industry: {
      choose: 'Wybierz branże Twojego sklepu',
      chooseFromList: 'Wybierz branżę',
      errorDuringLoading: 'Wystąpił błąd podczas przetwarzania danych dot. branż',
      invalidSelection: 'Nieprawidłowy wybór branży',
    },
    language: {
      choose: 'Wskaż język z listy',
      chooseFromList: 'Wybierz język',
      errorDuringLoading: 'Wystąpił błąd podczas przetwarzania danych dot. języków',
      invalidSelection: 'Nieprawidłowy wybór języka',
    },
    currency: {
      choose: 'Wskaż walutę z listy',
      chooseFromList: 'Wybierz walutę',
      errorDuringLoading: 'Wystąpił błąd podczas przetwarzania danych dot. waluty',
      invalidSelection: 'Nieprawidłowy wybór waluty',
    },
    error: {
      duringCatalogSave: 'Wystąpił błąd podczas zapisywania katalogu',
    },
    refreshSettings: {
      header: 'Ustawienie zasialania katalogu',
      description: 'Ustaw jak często katalog ma być odświeżany',
      maxCount: 'Możesz dodać maksymalnie %maxDayCount% pozycji',
      timeDescription: 'Katalog będzie przetwarzany codziennie o wskazanych godzinach',
      addNewPeriod: 'Dodaj kolejną godzinę',
      success: 'Sukces',
      periodsSaved: 'Dane o odświeżaniu katalogu zapisane poprawnie',
      save: 'Zapisz',
      errors: {
        min_interval_time: 'Minimalny odstęp czasu wynosi 1h',
        distinct_time: 'Czasy aktualizacji nie mogą być takie same',
      },
    },
    xmlPreview: {
      showXmlPreview: 'Pokaż podgląd xml',
      next: 'Następny',
      prev: 'Poprzedni',
    },
    productPreview: {
      requiredFields: 'Pole wymagane',
      productName: 'Nazwa produktu',
      productId: 'Id produktu',
      productCategory: 'Kategorie',
      productLink: 'Link do strony produktu',
      productImage: 'Link do obrazka',
      productAvailability: 'Dostępność',
      productPrice: 'Cena',
      additionalFields: 'Pole dodatkowe',
      missingAdditionalFields: 'Brak dodatkowych pól',
      productPreview: 'Podgląd produktu',
    },
    instance: {
      addNew: 'Skonfiguruj nowy katalog',
      mainHeader: 'Lista katalogów',
      mainDescription: 'Katalog produktów jest bardzo ważnym elementem systemu qon. System umożliwia skonfigurowanie katalogu samodzielnie. W konfiguracji nowego katalogu produktowego pomoże kreator katalogu, który przeprowadzi Cię przez konfigurację krok po kroku.',
      showDetails: 'Pokaż szczegóły',
      delete: 'Usuń',
      workingDirectory: 'katalog  roboczy',
      history: 'Historia importu',
      editMapping: 'Edytuj mapowanie',
      editTimeRefresh: 'Edytuj czas aktualizacji',
      categoryMappings: 'Mapowanie kategorii',
      deletetips: {
        notproduction: 'Nie można usunąć katalogu roboczego',
        assignedSearch: 'Nie można usunąć katalogu z przypisanymi wyszukiwarkami',
      },
      header: {
        id: 'Id katalogu',
        name: 'Nazwa',
        url: 'Ścieżka do katalogu',
        type: 'Typ',
        language: 'Język',
        industry: 'Branża',
      },
      mapping: {
        header: 'Lista mapowanych pól',
        description: 'Lista mapowanych pól z dostarczonego katalogu na katalog możliwy do użycia w systemie QON. Elementy znalezione są transformowane na:',
        foundElement: 'Znaleziony element',
        changedTo: '(Typ) Zamieniony na',
        notSet: 'Mapowania nie przypisano',
      },
      import: {
        header: 'Lista godzin odświeżania',
        description: 'Aby zapewnić jak największą aktualność danych o produktach katalogi przetwarzane są cyklicznie. Katalog jest przetwarzany codziennie w godzinach:',
      },
      details: {
        header: 'Szczegóły katalogu id: {catalogId} ',
        description: 'W tym obszarze możesz sprawdzić konfigurację i przeanalizować katalog produktów. Dostosuj i zweryfikuj, w jaki sposób atrybuty Twoich produktów są odwzorowane w naszych strukturach danych, co ma kluczowe znaczenie dla precyzji i spójności prezentowanych informacji. Dostosuj czas przetwarzania katalogu tak, aby oferta była jak najbardziej aktualna. Na liście produktów możesz sprawdzić, w jaki sposób produkty zostały zaimportowane do systemu.',
      },
      categoriesTree: {
        header: 'Mapowanie kategorii dla katalogu o identyfikatorze: {catalogId}',
        refreshButton: 'Odśwież drzewo kategorii',
        ownMappings: 'Własne',
        parentMappings: 'Rodzica',
        notSetMappings: 'Nie ustawione',
        refreshInfo: 'Po odświeżeniu dodano {added} z {total}',
      },
    },
    timeSelect: {
      labelHours: 'Godzina',
      labelMinutes: 'Minuta',
      labelNowButton: 'Aktualny czas',
      labelCloseButton: 'Zamknij',
    },
    productList: {
      title: 'List produktów',
      product: {
        id: 'Id:',
        price: 'Cena:',
        availability: 'Dostępność:',
        categories: 'Kategorie:',
        link: 'Strona produktu:',
        image: 'Url obrazka:',
        imageClickDescription: 'Kliknij w obrazek, aby przejść do strony produktu wskazanej w katalogu',
        variantsCount: 'brak wariantów | {n} wariant | {n} warianty | {n} wariantów ',
      },
      showProductsDetails: 'Pokaż szczegóły',
      hideProductsDetails: 'Ukryj szczegóły',
      showProduct: 'Pokaż produkt',

    },
  },
  catalogHistory: {
    header: 'Historia importu katalogu',
    shortDescription: 'Poniżej znajdziesz historię importu katalogu',
    lastImport: 'Status ostatniego importu',
    tableHeaders: {
      date: 'Data',
      owner: 'Wywołanie',
      status: 'Status',
      correctProductsCount: 'Poprawne produkty',
      incorrectProductsCount: 'Błedne produkty',
    },
    status: {
      unknown: 'Nieznany',
      ok: 'OK',
      error: 'Wystąpił błąd',
      in_progress: 'Przetwarzanie',
    },
    laststatus: {
      unknown: 'Wystąpił nieznany błąd',
      ok: 'Poprawnie',
      error: 'Niepoprawnie',
      in_progress: 'Przetwarzanie',
    },
    yoursCatalogLastImportDesc: 'Twój katalog ostatnio został zaimportowany:',
    yoursCatalogLastImportDescProducts: 'Łącznie produktów odnalezionych w katalogu:',
    correctProducts: 'produktów poprawnych',
    incorrectProducts: 'produktów niepoprawnych',
    errorResponse: 'Wystąpił błąd, prosimy spróbować później',
  },
  industry: {
    animals: 'Zwierzęta',
    automotive: 'Samochodowy',
    beauty: 'Piękno',
    books_and_multimedia: 'Książki i multimedia',
    education: 'Edukacja',
    electronic: 'Elektronika',
    entertainment: 'Rozrywka',
    erotic: 'Erotyczna',
    fashion: 'Moda',
    food: 'Żywność',
    health: 'Zdrowie',
    hobby: 'Hobby',
    home_and_garden: 'Dom i ogród',
    gifts_toys: 'Podarki',
    jewellery_gallantry: 'Biżuteria i galenteria',
    office: 'Biuro',
    other_goods: 'Inne (dobra)',
    other_services: 'Inne (usługi)',
    sport: 'Sport',
    travel_and_leisure: 'Podróże i odpoczynek',
    other: 'Inne',
  },
  language: {
    pol: 'Polski',
    cze: 'Czeski',
    slo: 'Słowacki',
    eng: 'Angielski',
    ukr: 'Ukraiński',
    rum: 'Rumuński',
    bul: 'Bułgarski',
    ger: 'Niemiecki',
    hrv: 'Chorwacki',
    hun: 'Węgierski',
    ita: 'Włoski',
  },
  apiAuthorization: {
    header: 'Konfiguracja dostępu do API',
    domainWhitelist: {
      header: 'Konfiguracja dostępu do API swyszukiwarki bazująca na whiteliście domen, z których przychodzą żądania. Whitelista pozwala na integrację silnika wyszukiwania na poziomie frontendu sklepu internetowego. API przetworzy każde żądanie, które zostanie zainicjowane z dowolnej z domeny wymienionych poniżej. Żądania z domen, które nie będą na liście i nie będą autoryzowane w inny sposób zostaną odrzucone.',
      tabTitle: 'Dostęp whitelist',
      desc: 'Wprowadź domeny, które uzyskają autoryzację API.',
      newDomainLabel: 'Dodaj nową autoryzowaną domenę',
      add: 'Dodaj',
      domain: 'Domena',
      action: 'Akcja',
      deleteTooltip: 'Usuń',
      save: 'Zapisz',
    },
    key: {
      header: 'Konfiguracja dostępu do API za pomocą klucza. Wszystkie żądania podpisane kluczem zostaną przetworzone. Autoryzacja za pomocą klucza pozwala na integrację API silnika wyszukiwania z backendem sklepu internetowego. Wszystkie żądania które zostaną wywołane z prawidłowym kluczem zostaną przetworzone. Jeżeli klucz będzie błędny lub nie zostanie przekazany, a zgłoszenie do API nie będzie autoryzowane w inny sposób zostanie odrzucone. Pamiętaj aby nie upubliczniać klucza, jest unikalny i przypisany do Twojego konta. Jeżeli instnieje podejrzenie, że klucz uległ ujawnieniu, zalecane jest wygenerowanie klucza ponownie. Uwaga, wygenerowanie klucza usunie aktualny klucz.',
      tabTitle: 'Klucze dostępu',
      url: 'Url API',
      key: 'Klucz',
      copyUrl: 'Kopiuj',
      copyKey: 'Kopiuj',
      resetKey: 'Resetuj klucz',
      customerSymbol: 'Symbol klienta',
    },
  },
  catalogImport: {
    title: 'Infomacje o zaimportowanym katalogu',
    selectedFeed: 'Wskazany feed produktowy:',
    analyzingCatalog: 'Trwa analizowanie katalogu, to może troszkę potrwać',
    correctlyImported: 'Zaimportowano produktów',
    incorrectlyImported: 'Błędne importy',
    downloadingProducts: 'Trwa pobieranie listy produktów',
    catalogProductsList: {
      show: 'Pokaż',
      correctImportedProductListTitle: 'Lista poprawnie zaimportowanych produktów',
      incorrectlyImportedProductListTitle: 'Lista produktów z błędami',
      downloadFile: 'Pobierz plik',
      variantsCount: 'Liczba variantów produktu',
      title: 'Nazwa produktu',
      id: 'Id',
      price: 'Cena',
      categories: 'Kategorie',
      image: 'Zdjęcie',
      actions: 'Akcje',
    },
    warnings: {
      Model: {
        Exception: {
          ValidationException: 'Błąd walidacji',
        },
      },
      Normalizers: {
        Exceptions: {
          IdentifierCannotBeEmptyException: 'Identyfikator produktu nie może być pusty',
        },
      },
    },
  },
  sortSettings: {
    header: 'Ustawienia sortowania',
    description: 'W tym miejscu możesz zarządzać swoimi sposobami sortowania wyników wyszukiwania. Każdy ze sposobów sortowania może zawierać wiele warunków sortowania. Dzięki temu możesz skonfigurować sortowanie wielokryterialne, np. sortuj wyniki wyszukiwania najpierw po cenie malejąco, a następnie po tytule malejąco.',
    list: {
      header: {
        number: 'Pozycja',
        sortId: 'Id sortowania',
        sortName: 'Nazwa sortowania',
        sortDescription: 'Opis sortowania',
      },
      conditions: {
        header: {
          number: 'Pozycja',
          type: 'Typ',
          order: 'Kierunek sortowania',
          details: 'Szczegóły',
        },
        notSet: 'Brak skonfigurowanych warunków sortowania!',
      },
    },
    addSortGroup: {
      header: 'Dodawanie grupy sortowania',
      description: 'Witaj w formularzu dodawnia grupy sortowania. Ponieważ mamy świadomość, że zaawansowane sortowanie wyników wyszukiwania niejednokrotnie jest niezbędne, umożliwiamy Ci skonfigurowanie sortowania wielopoziomowego. Możesz ustawić listę warunktów sortowania, które będą zastosowane kolejno do wyników wyszukiwania. Prostym przykładem jest sortowanie, które najpierw wyniki posortuje ceną malejąco, a następnie tytułem rosnąco.',
      label: {
        sortId: 'Id sortowania',
        sortName: 'Nazwa sortowania, label wyświetlany na liście wyboru sortowań',
        sortDescription: 'Opis sortowania, opisz jaki był cel konfiguracji tak aby wszyscy wiedzieli jak to sortowanie działa',
        sortConditions: 'Lista warunków sortowania',
        sortConditionTable: {
          number: 'Pozycja',
          type: 'Typ',
          field: 'Po czym',
          order: 'Kierunek',
          additionalInfo: 'Dodatkowe informacje',
          actions: 'Akcje',
        },
      },
      add: {
        defaultCondition: 'domyślne',
        fieldBasedCondition: 'na podstawie pola',
        trackingBasedCondition: 'na podstawie popularności',
        advancedCondition: 'zaawansowane',
        sortType: {
          default: 'Sortowanie domyślne',
          field: 'Sortowanie w oparciu o pole',
          popular: 'Sortowanie po popularności',
          script: 'Sortowanie zaawansowane',
        },
      },
      order: {
        asc: 'rosnąco',
        desc: 'malejąco',
      },
      addFieldBasedPopup: {
        header: 'Dodawanie warunku sortowania na podstawie atrybutu z katalogu',
        description: 'Dodajesz kolejny warunek sortowania do grupy. Sortowanie oparte na danych katalogowych',
        fieldSelect: 'Wybierz pole z katalogu',
        orderSelect: 'Wybierz kierunek sortowania',
        add: 'Zapisz',
      },
      addAdvanced: {
        header: 'Dodawanie zaawansowanego warunku sortowania',
        description: 'Dodajesz kolejny warunek sortowania do grupy. Sortowanie w oparciu o wprowadzony skrypt',
        sortScript: 'Wprowadź skrypt na podstawie, którego nastąpi sortowanie',
        orderSelect: 'Wybierz kierunek sortowania',
        add: 'Zapisz',
      },
      addPopularityBasedPopup: {
        header: 'Dodawanie warunku sortowania na podstawie popularności',
        description: 'Dodajesz kolejny warunek sortowania do grupy. Sortowanie bazujące na popularności produktu w określonym czasie',
        typeSelect: 'Wybierz kryterium popularności',
        gradationSelect: 'Z jakiego czasu',
        orderSelect: 'Wybierz kierunek sortowania',
        add: 'Zapisz',
        popularType: {
          views: 'Najczęściej oglądane produkty',
          clicks: 'Najczęściej klikane produkty',
          purchases: 'Najczęściej kupowane produkty',
        },
        gradations: {
          day1: 'dane z ostatniego dnia',
          day7: 'dane z ostatniego tygodnia',
          day14: 'dane z ostatnich dwóch tygodni',
          day30: 'dane z ostatniego miesiąca',
        },
      },
      save: 'Zapisz',
    },
    sortSearch: 'Search',
    sortBestseller: 'Bestsellers',
    sortPopular: 'Popularne',
    add: 'Dodaj sortowanie',
    save: 'Zapisz ustawienia',
    upTooltip: 'Do góry',
    downTooltip: 'W doł',
    deleteTooltip: 'Usuń',
    theadLabel: {
      sortName: 'Nazwa sortowania',
      sortLabel: 'Etykieta sortowania',
      sortType: 'Typ sortowania',
      additionalData: 'Dodatkowe ustawienia',
      actions: 'Akcje',
      order: 'Kolejność',
    },
  },
  weightsSettings: {
    header: 'Istotność pól',
    headerAdd: 'Dodaj nowe pole',
    headerList: 'Zdefiniowane pola',
    description: 'Dzięki tej zakładce właściciel sklepu, może wybrać, które pola mają być brane pod uwagę podczas procesu wyszukiwania oraz określić, jakie wagi przypisać poszczególnym polom. Dodatkowo, w tej zakładce możliwe jest też określenie, jakie rodzaje wyszukiwania mają być stosowane dla poszczególnych pól, co daje jeszcze większą kontrolę nad wynikami wyszukiwania.',
    shortDescription: 'Poniżej znajduje się lista pól po których system będzie szukał wyników oraz z jaką siłą wyniki te będą boostowane',
    selectSearch: 'Wybierz formę dopasowania',
    selectOne: 'Wybierz przynajmniej raz',
    allFieldsSelected: 'Wszystkie dostępne pola zostały dodane',
    strategyDescription: {
      full: 'Zaznacz tą opcję jeśli pole przechowuje identyfikator produktu, zastosowane zostanie dokładne dopasowanie szukanej frazy',
      match: 'to metoda wyszukiwania, która pozwala na uwzględnienie wielu kryteriów wyszukiwania, takich jak treść, tytuł, opis i wiele innych. Dzięki temu użytkownicy będą mieli większe szanse na znalezienie poszukiwanych produktów. Dzięki temu metoda ta jest idealna dla sklepów internetowych, które oferują szeroki wachlarz produktów, a klienci mogą szukać produktów po różnych kryteriach.',
      exact: 'to metoda wyszukiwania, która skupia się na dokładnym dopasowaniu wyszukiwanego wyrażenia do nazwy produktu lub jego cech. Dzięki temu użytkownicy będą mieli większą pewność, że znajdą dokładnie to, czego szukają, co jest szczególnie ważne dla sklepów internetowych, które oferują produkty o specjalnych cechach lub o unikalnych nazwach.',
    },
    tableHeaders: {
      field: 'Pole',
      weight: 'Waga',
      actionAdd: '',
      actionView: 'Akcje',
      actionRemove: 'Usuń',
      fullMatchField: 'Identyfikator produktu',
      matchFields: 'MultiMatch',
      exactMatchFields: 'ExactSearch',
      empty: '',
    },
    fields: {
      id: 'Identyfikator',
      title: 'Tytuł produktu',
      categories: 'Kategorie',
      link: 'Link produktu',
      image_link: 'Url obrazka',
      availability: 'Dostępność',
      price: 'Cena',
    },
    add: 'Dodaj',
    descriptionButton: {
      hide: 'Ukryj',
      show: 'Pokaż',
      description: 'opis',
    },

  },
  facetedSearchSettings: {
    header: 'Ustawienia menu fasetowego',
    description: 'Menu fasetowe to rodzaj interfejsu, który umożliwia nam łatwe filtrowanie. Dzięki niemu możemy dokładnie określić, jakie elementy chcemy zobaczyć w wynikach wyszukiwania, a jakie chcemy wykluczyć. To bardzo przydatne w sklepach internetowych, serwisach z ogłoszeniami i innych aplikacjach, w których szukamy konkretnych informacji. W menu fasetowym mamy kilka rozwijanych list, w których wybieramy interesujące nas kategorie lub cechy elementów, np. kolor, markę, cenę, rozmiar itp. Dzięki temu proces szukania jest łatwiejszy i bardziej precyzyjny.',
    listHeader: 'Lista zwracanych pól',
    shortDescription: 'Lista zwracanych pól w endpoincie.',
    headerAdd: 'Dodaj nowe pole',
    addNewFieldLabel: 'Wybierz pole',
    addNewLabelLabel: 'Nadaj mu nazwę',
    addNewTypeLabel: 'Ustal Funkcję',
    addNewUnitLabel: 'Ustal jednostkę',
    filtersHeader: 'Zdefiniowane filtry',
    add: 'Dodaj',
    descriptionButton: {
      hide: 'Ukryj',
      show: 'Pokaż',
      description: 'opis',
    },

    allFieldsSelected: 'Wszystkie dostępne pola zostały dodane',
    tableHeaders: {
      field: 'Pole',
      label: 'Wyświetlana nazwa',
      type: 'Funkcja',
      actionView: 'Akcje',
    },
    fields: {
      id: 'Identyfikator',
      title: 'Tytuł produktu',
      categories: 'Kategorie',
      link: 'Link produktu',
      image_link: 'Url obrazka',
      availability: 'Dostępność',
      price: 'Cena',
    },
    types: {
      category: 'Drzewo kategorii',
      range: 'Zakres',
      checkbox: 'Pole zaznaczenia',
      rangeSlider: 'Suwak zakresu',
    },
    upTooltip: 'Do góry',
    downTooltip: 'W dół',
    deleteTooltip: 'Usuń',
  },
  catalogMapping: {
    fields: {
      productId: 'ID produktu',
      title: 'Tytuł',
      categories: 'Kategorie',
      productLink: 'Link do strony produktu',
      imageLink: 'Link do obrazka produktu',
      availability: 'Dostępność',
      price: 'Cena',
    },
    pickField: 'Wybierz pole',
    addNewField: 'Dodaj nowe pole z katalogu',
    pickSeparator: 'Zdefinuj separator elementów',
    requiredFields: {
      tableName: 'Wymagane pola',
      theadLabel: {
        qonId: 'Qon Id',
        feedField: 'Pole z katalogu',
        dataType: 'Typ danych',
        description: 'Opis',
      },
    },
    additionalFields: {
      notConfigured: 'Aktualnie nie masz skonfigurowanych pól dodatkowych',
      tableName: 'Dodatkowe pola',
      theadLabel: {
        qonId: 'Qon Id',
        feedField: 'Pole z katalogu',
        dataType: 'Typ danych',
        action: 'Akcje',
      },
    },
    invalid: {
      qonId: 'Błędny identyfikator',
      feedPath: 'Nieprawidłowo wybrana ścieżka',
      dataType: 'Nieprawidłowo wybrany typ danych',
      separator: 'Nieprawidłowo wybrany separator',
    },
    error: {
      duringFindingXmlPaths: 'Wystąpił błąd podczas szukania ścieżek w pliku XML',
      fieldsInvalid: 'Występują błędy w mapowaniu ścieżek z pliku do pól katalogu - popraw konfigurację mapowania',
      storeRequestFailed: 'Wystąpił błąd zapisu mapowania katalogu - skontaktuj się z supportem QON',
      storeRequestUnknown: 'Wystąpił nieznany błąd - skontaktuj się z supportem QON',
    },
  },
  catalogWizard: {
    next: 'Dalej',
    previous: 'Poprzedni',
    save: 'Zapisz',
    tabsTitle: {
      basicConfiguration: 'Podstawowa konfiguracja',
      setMapping: 'Ustawienia mapowania',
      catalogPreview: 'Podgląd katalogu',
      refreshSettings: 'Ustawienie zasilania katalogu',
    },
  },
  htmlTemplate: {
    code: {
      title: 'Kod html szablonu',
    },
    preview: {
      title: 'Podgląd szablonu',
      numberOfProducts: 'Ile produktów wygenerować?',
      email: 'Wprowadź mail do testów',
      widgetId: 'Wprowadź Id widgetu',
      widgetIdList: 'Zobacz listę widgetów w panelu',
    },
    settings: {
      title: 'Ustawienia szablonu html',
    },
    tooltip: {
      editSettings: 'Edytuj ustawienia szablonu',
      editCode: 'Edytuj kod html szablonu',
      generateSnippets: 'Wygeneruj snippet',
    },
    editSettings: 'Edytujesz ustawienia szablonu:',
    editCode: 'Edytujesz kod szablonu:',
    generateSnippets: 'Generujesz snippet HTML dla szablonu:',

    saveButtonLabel: 'Zapisz',
    cancelButtonLabel: 'Anuluj',
    newTemplateButton: 'Utwórz nowy szablon',
    title: 'Zarządzaj szablonami HTML (Image Mailing API v2)',
    widthLabel: 'Szerokość wygenerowanego obrazka (px)',
    widthErrorLabel: 'Nieprawidłowa szerokość',
    heightLabel: 'Wysokość wygenerowanego obrazka (px)',
    heightErrorLabel: 'Nieprawidłowa wysokość',
    idLabel: 'Id szablonu',
    idErrorLabel: 'Nieprawidłowe id szablonu',
    nameLabel: 'Nazwa szablonu',
    nameErrorLabel: 'Nieprawidłowa nazwa szablonu',
  },
  searchTry: {
    testYourSearch: {
      title: 'Przetestuj swoją wyszukiwarkę',
      subtitle: 'twoje produkty czekają, aż je znajdziesz',
      inputPlaceholder: 'Nazwy produktów, kategorie, tagi...',
    },
    selectSortType: 'Sposób sortowania wyników:',
    resultList: {
      product: {
        id: 'Id',
        price: 'Cena',
      },
    },
  },
  appV3Settings: {
    header: 'Ustawienia Aplikacji Search',
    tabGeneral: 'Ustawienia ogólne',
    tabOnfocus: 'Ustawienia Onfocus',
    tabAutocomplete: 'Ustawienia Autocomplete',
    tabSerp: 'Ustawienia strony wynikow',
    fieldValue: {
      true: 'TAK',
      false: 'NIE',
    },
    desc: {
      appConfiguration: 'W tej sekcji możesz dostosować ogólne ustawienia aplikacji, takie jak przekierowanie URL, obsługa miejsc po przecinku w cenach, wybór waluty, oraz korzystanie z hasha przed parametrami wyszukiwania. Ustawienia te są ogólne i sterują zachowaniem całej aplikacji search.',
      onfocus: 'Sekcja ta obejmuje dostosowania dla pola wyszukiwania, takie jak wyświetlanie obrazków produktów w momencie wyświetlenia onfocus, ustawienia ostatnich i popularnych zapytań, a także dostosowanie listy popularnych produktów.',
      autocomplete: 'Tutaj można dostosować, jakie sugestie powinny być wyświetlane w momencie wprowadzania frazy, ile wyników autouzupełniania i jakie obrazy produktów powinny towarzyszyć sugestiom.',
      serp: 'Sekcja ta obejmuje dostosowania dotyczące wyświetlania wyników wyszukiwania, takie jak filtry, sortowanie, paginacja, lista tagów i wielkość obrazów produktów.',
    },
    group: {
      onfocus: {
        popularProducts: 'Popularne produkty',
        popularQueries: 'Popularne frazy',
        predefinedQueries: 'Własne zapytania',
        productImageSize: 'Zdjęcia produktów',
        recentQueries: 'Ostatnie wyszukiwania',
        topProduct: 'Promowane produkty',
      },
      autocomplete: {
        bestseller: 'Bestsellery',
        productImageSize: 'Zdjęcia produktów',
        results: 'Wyniki',
        suggestions: 'Sugestie',
      },
      serp: {
        query: 'Zapytania',
        tagList: 'Lista tagów',
        productImageSize: 'Zdjęcia produktów',
        filters: 'Filtry',
        gridSettings: 'Ułożenie produktów',
        markedFilters: 'Wybrane filtry',
        pagination: 'Paginacja',
        customComponents: 'Własne szablony',
        sorting: 'Sortowanie',
      },
    },
    fields: {
      appConfiguration: {
        applicationEnabled: 'Włączenie aplikacji search',
        redirectionUrl: 'Adres przekierowania',
        removeDecimals: 'Usuń części dziesiętne',
        currency: 'Waluta',
        useHashBeforeSearchParams: 'Użyj hash przed parametrami',
      },
      onfocus: {
        showOnfocus: 'Pokazać onfocus?',
        popularProducts: {
          count: 'Liczba popularnych produktów',
          sortName: 'Sortowanie użyte w popularnych produktach',
          enabled: 'Popularne produkty włączone',
        },
        popularQueries: {
          enabled: 'Popularne frazy włączone',
          count: 'Liczba popularnych fraz',
        },
        predefinedQueries: {
          enabled: 'Czy włączne własne zapytania?',
          sectionsList: 'Zdefiniowane zapytania',
          sectionsListAdd: 'Dodaj sekcję',
          sections: {
            '*': {
              header: 'Nagłówek sekcji',
              queriesList: 'Zapytania',
              queriesListAdd: 'Dodaj etykietę',
              queries: {
                '*': {
                  query: 'Etykieta zapytania',
                  link: 'URL',
                },
              },
            },
          },
        },
        productImageSize: {
          fixedWidth: 'Ustawić szerokość zdjęcia produktu',
          width: 'Zdjęcie produktu - szerokość',
          fixedHeight: 'Ustawić wysokość zdjęcia produktu',
          height: 'Zdjęcie produktu - wysokość',
        },
        recentQueries: {
          enabled: 'Pokazać ostatnie wyszukiwania?',
          count: 'Liczba ostatnich szukań',
        },
        topProduct: {
          enabled: 'Czy włączone promowane produkty?',
          productImageSize: {
            fixedWidth: 'Ustawić szerokość zdjęcia popularnego produktu',
            width: 'Zdjęcie popularnego produktu - szerokość',
            fixedHeight: 'Ustawić wysokość zdjęcia popularnego produktu',
            height: 'Zdjęcie popularnego produktu - wysokość',
          },
        },

      },
      autocomplete: {
        showButtonOnInput: 'Pokazać przycisk na polu szukania?',
        showAutocomplete: 'Pokazać autocomplete?',
        bestseller: {
          enabled: 'Czy bestsellery włączone?',
        },
        productImageSize: {
          fixedWidth: 'Ustawić szerokość zdjęcia produktu',
          width: 'Zdjęcie produktu - szerokość',
          fixedHeight: 'Ustawić wysokość zdjęcia produktu',
          height: 'Zdjęcie produktu - wysokość',
        },
        results: {
          count: 'Liczba prezentowanych wynikow',
        },
        suggestions: {
          enabled: 'Sugestie włączone',
          count: 'Liczba sugestii',
        },
      },
      serp: {
        showInput: 'Pokazać pole szukania',
        showButtonOnInput: 'Pokazać przycisk na polu szukania',
        backToTopButton: {
          enabled: 'Przycisk "wróć do góry" włączony',
        },
        query: {
          allowEmptyQuery: 'Pozwalaj na puste zapytanie',
        },
        sorting: {
          showSorting: 'Pokaż sortowanie',
        },
        tagList: {
          showTagList: 'Wyświetlaj listę tagów:',
          limitPerFilter: 'Limit na filtr listy tagów',
        },
        productImageSize: {
          useQuarticImages: 'Użyj zdjęć z serwera Quarticon',
          fixedWidth: 'Ustawić szerokość zdjęcia produktu',
          width: 'Zdjęcie produktu - szerokość',
          fixedHeight: 'Ustawić wysokość zdjęcia produktu',
          height: 'Zdjęcie produktu - wysokość',
        },
        filters: {
          showAsideFilters: 'Pokaż boczne filtry',
          showTopFilters: 'Pokaż górne filtry',
        },
        gridSettings: {
          column: {
            smallDevice: 'Ilość produktów w wierszu dla małych urządzeń',
            mediumDevice: 'Ilość produktów w wierszu dla średnich urządzeń',
            largeDevice: 'Ilość produktów w wierszu dla dużych urządzeń',
            extraLargeDevice: 'Ilość produktów w wierszu dla bardzo dużych urządzeń',
          },
        },
        markedFilters: {
          enabled: 'Zaznaczaj wybrane filtr - włączone',
        },
        pagination: {
          useTop: 'Pokaż paginację na górze strony',
          useBottom: 'Pokaż paginację na dole strony',
        },
        customComponents: {
          productCard: 'Własny szablon karty produktu',
          autocompleteSingleResult: 'Własny szablon rezultatu autocomplete',
          singlePopularProduct: 'Własny szablon popularnych produktów',
          topProduct: 'Własny szablon bestsellerów',
        },
      },
    },
    fieldsTooltip: {
      appConfiguration: {
        applicationEnabled: 'Umożliwia włączenie/wyłączenie aplikacji front search',
        redirectionUrl: 'Ustaw na jakiej stronie, chcesz aby wyświetlała się strona wyników wyszukiwania',
        removeDecimals: 'Czy chcesz usuwać części dzięstne .00 podczas wyświetlania cen?',
        currency: 'Ustaw walutę w jakiej zwracane są ceny',
        useHashBeforeSearchParams: 'Jeśli strona oparta jest o przekazywanie parametrów za pomocą hash, ustaw tak',
      },
      onfocus: {
        showOnfocus: 'Czy chcesz wyświetlać okno onfocus, po kliknięciu w input?',
        productImageSize: {
          fixedWidth: 'Czy zdjęcia generowane na onfocus powinny mieć narzuconą szerokość? Odznaczenie spowoduje automatyczne ustawienie szerokości.',
          width: 'Ustaw wielość zdjęć generowanych na onfocus - szerokość',
          fixedHeight: 'Czy zdjęcia generowane na onfocus powinny mieć narzuconą wysokość? Odznaczenie spowoduje automatyczne ustawienie wysokości.',
          height: 'Ustaw wielość zdjęć generowanych na onfocus - wysokość',
        },
        popularQueries: {
          enabled: 'Czy wyświetlać użytkownikowi, popularne zapytania',
          count: 'Ile zayptań wyświetlić w popularnych frazach ',
        },
        popularProducts: {
          count: 'Ile popularnych produktów wyświetlić',
          sortName: 'Sortowanie użyte w popularnych produktach',
          enabled: 'Czy wyświetlać produkty popularne? Wskaż wg jakiego sortowania mają być zwrócone produkty. Możesz utworzyć sortowanie po bestsellerach i tu je wskazać?',
        },
        topProduct: {
          enabled: 'Czy promować pierwszy produkt zwracany w produktach popularnych?',
          productImageSize: {
            fixedWidth: 'Czy zdjęcia produktów promowanych powinny mieć narzuconą szerokość? Odznaczenie spowoduje automatyczne ustawienie szerokości.',
            width: 'Ustaw wielość zdjęć promowanych produktów - szerokość',
            fixedHeight: 'Czy zdjęcia produktów promowanych powinny mieć narzuconą wysokość? Odznaczenie spowoduje automatyczne ustawienie wysokości.',
            height: 'Ustaw wielość zdjęć promowanych produktów - wysokość',
          },
        },
        predefinedQueries: {
          enabled: 'Czy wyświetlać predefiniowane zapytania?',
          sectionsList: 'Jeśli chcesz wyświetlić użytkownikowi zapytanie predefiniowane, możesz je dodać tutaj. Wskazując link użytkownik, zostanie przekierowany na wskazany link, Gdy dodasz predefiniowane query bez linku, zapytanie zostanie użyte do wyszukania go',
          sections: {
            '*': {
              header: 'Nagłówek widoczny przed listą zapytań',
              queriesList: '',
              queries: {
                '*': {
                  query: 'Etykieta widoczna jako link. Jeśli nie podano linku url poniżej jest to również zapytanie Search',
                  link: 'Link URL do strony',
                },
              },
            },
          },
        },
        recentQueries: {
          enabled: 'Czy wyświetlać użytkownikowi jego ostatnie wyszukania',
          count: 'Liczba ostatnich wyszukiwań',
        },
      },
      autocomplete: {
        showButtonOnInput: 'Czy wyświetlić button Szukaj na inpucie wyszukiwarki?',
        showAutocomplete: 'Czy wyświetlać okno autocomplete podczas wyszukiwania?',
        bestseller: {
          enabled: 'Czy promować pierwszy produkt jako Bestseller?',
        },
        productImageSize: {
          fixedWidth: 'Czy zdjęcia generowane na autocomplete powinny mieć narzuconą szerokość? Odznaczenie spowoduje automatyczne ustawienie szerokości.',
          width: 'Rozmiar zdjęć na autocomplete - szerokość',
          fixedHeight: 'Czy zdjęcia generowane na autocomplete powinny mieć narzuconą wysokość? Odznaczenie spowoduje automatyczne ustawienie wysokości.',
          height: 'Rozmiar zdjęć na autocomplete - wysokość',
        },
        suggestions: {
          enabled: 'Czy wyświetlać użytkownikowi sugestie zapytań dla wpisanej frazy',
          count: 'Liczba sugestii wyświetlana użytkownikowi',
        },
        results: {
          count: 'Liczba prezentowanych wynikow',
        },
      },
      serp: {
        showInput: 'Czy wyświetlać dodatkowy input na stronie wyników wyszukiwania?',
        showButtonOnInput: 'Czy pokazać przycisk Szukaj na inpucie?',
        tagList: {
          showTagList: 'Czy pokazywać użytkownikowi sekcję tagów, nad wynikami wyszukiwania? Dotyczy tylko filtrów typu string lub box?',
          limitPerFilter: 'Limit wyświetlanych tagów nad wynikami wyszukiwania',
        },
        query: {
          allowEmptyQuery: 'Czy pozwolić na wyświetlania wszystkich produktów na stronie wyników, gdy użytkownik nie wpisał żadnego zapytania?',
        },
        filters: {
          showAsideFilters: 'Czy pokazywać użytkownikowi Sekcję filtrów na stronie wyników wyszukiwania?',
          showTopFilters: 'Czy pokazywać użytkownikowi sekcję filtrów nad wynikami wyszukiwania?',
        },
        sorting: {
          showSorting: 'Czy pokazwyać użytkownikowi, przycisk do zmiany sortowania?',
        },
        pagination: {
          useTop: 'Czy pokazywać użytkownikowi przycisk do zmiany strony, na górze strony?',
          useBottom: 'Czy pokazywać użytkownikowi przycisk do zmiany strony, na dole strony?',
        },
        backToTopButton: {
          enabled: 'Czy pokazywać użytkownikowi przycisk powrót na górę strony?',
        },
        markedFilters: {
          enabled: 'Czy pokazywać użytkownikowi sekcję zaznaczonych filtrów?',
        },
        productImageSize: {
          useQuarticImages: 'Czy zdjęcia generowane na wynikach użytkownika mają pochodzić z serwera Quarticon',
          fixedWidth: 'Czy zdjęcia generowane na wynikach wyszukiwania powinny mieć narzuconą szerokość? Odznaczenie spowoduje automatyczne ustawienie szerokości.',
          width: 'Ustaw rozmiar zdjęć generowanych na wynikach wyszukiwania - szerokość',
          fixedHeight: 'Czy zdjęcia generowane na wynikach wyszukiwania powinny mieć narzuconą wysokość? Odznaczenie spowoduje automatyczne ustawienie wysokości.',
          height: 'Ustaw rozmiar zdjęć generowanych na wynikach wyszukiwania - wysokość',
        },
        gridSettings: {
          column: {
            smallDevice: 'Ustaw ile produktów w kolumnie ma wyświetlać się na małym urządzeniu np. telefon',
            mediumDevice: 'Ustaw ile produktów w kolumnie ma wyświetlać się na średnim urządzeniu np. tablet',
            largeDevice: 'Ustaw ile produktów w kolumnie ma wyświetlać się na dużym urządzeniu np. monitor 1080p',
            extraLargeDevice: 'Ustaw ile produktów w kolumnie ma wyświetlać się na dużym urządzeniu np. monitor 4k',
          },
        },
        customComponents: {
          productCard: 'Gdy chcesz zmienić wygląd kafla produktowego na stronie SERP, wskaż tu nowy HTML z makrami do jego generowania. Więcej tu: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          autocompleteSingleResult: 'Gdy chcesz zmienić wygląd kafla produktowego na Autocomplete, wskaż tu nowy HTML z makrami do jego generowania. Więcej tu: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          singlePopularProduct: 'Gdy chcesz zmienić wygląd kafla produktowego w sekcji popularnych prouktów, wskaż tu nowy HTML z makrami do jego generowania. Więcej tu: "https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          topProduct: 'Gdy chcesz zmienić wygląd kafla produktowego w sekcji top produkt, wskaż tu nowy HTML z makrami do jego generowania. Więcej tu: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
        },
      },
    },
    deleteAsk: 'Czy na pewno usunąć?',
    delete: 'Usuń',
    save: 'Zapisz',
    saveSuccess: 'Dane zostały zapiane poprawnie',
  },
  appV3Labels: {
    header: 'Ustawienie etykiet aplikacji',
    autocomplete: 'Etykiety autocomplete',
    popularQueries: 'Etykiety popularnych zapytań',
    popularProducts: 'Etykiety popularnych produktów',
    serp: 'Etykiety listy wynikow',
    filters: 'Etykiety fitrów',
    pagination: 'Etykiety paginacji',
    fields: {
      autocomplete: {
        whatSearch: 'Czego szukasz?',
        typeTerm: 'Wpisz wyszukiwane słowo',
        seeMore: 'Zobacz więcej',
      },
      popularQueries: {
        header: 'Popularne frazy',
      },
      popularProducts: {
        header: 'Popularne produkty',
      },
      serp: {
        resultHeader: 'Wyniki wyszukiwania dla',
        results: 'wyników',
        page: 'Strona',
        emptyResults: 'Brak wyników',
        typeQuery: 'Wprowadź zapytanie w wyszukiwarce na górze strony',
        showedAll: 'Wyświetlono wszystkie wyniki',
        omnibusLowestPrice: 'Najniższa cena z 30 dni',
      },
      filters: {
        header: 'Filtry',
        clear: 'Wyczyść filtry',
        show: 'Pokaż filtry',
        from: 'od',
        to: 'do',
      },
      pagination: {
        next: 'Następny',
        prev: 'Poprzedni',
      },
    },
    save: 'Zapisz',
    saveSuccess: 'Dane zostały zapiane poprawnie',
  },
  validation: {
    fields: {
      instanceName: 'nazwa instancji',
      selectedCatalog: 'wybrany katalog',
      catalogName: 'nazwa katalogu',
      selectedIndustry: 'branża katalogu',
      selectedLanguage: 'język katalogu',
      catalogUrl: 'link katalogu',
      newDomain: 'Domena',
      sortLabel: 'Etykieta sortowania',
      sortName: 'Nazwa sortowania',
      facetedMenuName: 'Nazwa menu',
      '{field}': 'pole',
    },
    rules: {
      alpha_dash: 'Pole {_field_} może zawierać litery, cyfry oraz myślnik lub podkreślnik',
      alpha_num_dash_spaces: 'Pole {_field_} może zawierać tylko litery, cyfry, spacje oraz myślnik lub podkreślnik',
      min: 'Pole {_field_} musi być długie na co najmniej {length}',
      max: 'Pole {_field_} nie może być dłuższe niż {length}',
      oneOf: 'Pole {_field_} musi być poprawną wartością',
      required: 'Pole {_field_} jest wymagane',
      domain: 'Pole {_field_} nie jest prawidłową domeną',
      alpha_unicode_num_dash_spaces: 'Pole {_field_} może zawierać tylko litery, cyfry, spacje oraz myślnik lub podkreślnik',
      alpha_num: 'Pole {_field_} może zawierać tylko litery i cyfry',
      regex: 'Niepoprawne wyrażenie',
    },
  },
}
