export default {
  menu: {
    smartSearch: 'Smart Search',
    smartSearchV3: 'Search',
    smartSearchDashboard: 'Dashboard',
    searchCatalog: 'Katalog',
    searchReports: 'Reporty',
    searchPhrasesReport: 'Report frází',
    searchPhrasesReportWithNoResults: 'Report fráze bez výsledků',
    searchProductsReport: 'Report produktů',
    searchSynonyms: 'Synonyma',
    searchSettings: 'Nastavení',
    searchImprovements: 'Vylepšení',
    searchInstanceSettings: 'Správa instancí',
    searchInstancesList: 'Seznam instancí',
    searchTester: 'Testování výsledků',
    searchSubscription: 'Předplatné',
    searchBlockedPhrase: 'Blokované fráze',
    searchImmutablePhrase: 'Neohebné fráze',
    mailing: 'Mailing',
    htmlTemplate: 'Html šablona',
    searchWeights: 'Relevance oborů',
    searchSort: 'Řazení',
    searchFacetedMenu: 'Fasetované menu',
    searchAuthorizationSettings: 'API přístup',
    searchInstances: 'Instance vyhledávačů',
    searchSuggestion: 'Návrhy',
    searchSharedFields: 'Sdílená pole',
    searchTry: 'Testování výsledků',
    appV3Settings: 'Nastavení',
    appV3Labels: 'Štítky',
    searchV3: {
      reports: {
        main: 'Reporty',
        emptySearchPhrasesReport: 'Report fráze bez výsledků',
        allPhrasesReport: 'Report frází',
        allReports: 'Dashboard',
      },
    },
    smartStylist: {
      main: 'Stylista',
      reports: 'Zprávy',
      stylizationsList: 'Seznam stylizací',
    },
    catalogInstances: 'Seznam catalogs',
    searchApp: 'Vyhledávací aplikace',
  },
  suggestion: {
    header: 'Nastavení návrhů',
    subHeader: 'Pole, ze kterých se generují návrhy',
    shortDescription: 'Uveďte pole, ze kterých bude systém generovat návrhy',
    description: 'Funkcionalita, která umožňuje určit pole, ze kterých se budou generovat návrhy na zadanou frázi. Díky němu můžete snadno označit pole, která jsou zodpovědná za zobrazení návrhů odpovídajících zadané frázi. Uživatelé díky tomu snáze najdou to, co hledají, což pozitivně ovlivní jejich spokojenost s používáním vyhledávače.',
    warningHeader: 'Pozornost',
    warningDescription: 'Změny budou viditelné po opětovném zpracování adresáře.',
    tableHeaders: {
      field: 'Katalogové pole',
      dataFromAggregate: 'Použít data z filtrů pro návrhy?',
      status: 'Povoleno',
      actions: 'Změna',
    },
    changeTooltip: 'Změna',
    descriptionButton: {
      hide: 'Skrýt',
      show: 'Ukázat',
      description: 'popis',
    },
  },
  sharedFields: {
    header: 'Nastavení sdílených polí',
    tableHeaders: {
      field: 'Katalogové pole',
      status: 'Povoleno',
      actions: 'Změna',
    },
    selectCatalog: 'Vyberte adresář: ',
    changeTooltip: 'Změna',
  },
  searchSelect: {
    description: 'Vyberte vyhledávání',
  },
  alert: {
    success: 'Úspěch',
    error: 'Chyba',
    warning: 'Pozor',
  },
  notifications: {
    copiedKey: {
      name: 'API klíč',
      message: 'Zkopírováno',
    },
    copiedCustomerSymbol: {
      name: 'Symbol zákazníka',
      message: 'Zkopírováno',
    },
    copiedUrl: {
      name: 'URL rozhraní API',
      message: 'Zkopírováno',
    },
    apiKeyReset: {
      name: 'API klíč',
      message: 'Resetovat',
    },
  },
  search: {
    dataSources: {
      allData: 'Všechna data',
      autocomplete: 'automatické doplňování',
      serp: 'Výsledky vyhledávaní',
      bestseller: 'bestsellery',
      popular: 'oblíbené produkty',
    },
    header: {
      dataSource: 'Zdroj',
      dateRange: 'Časové období',
    },
    headers: {
      populars: 'Nejoblíbenější fráze',
      typeDistribution: 'Zdroj vyhledávaní',
      zeroSearch: 'Fráze bez výsledků ',
      mostClicked: 'Nejčastěji klikané fráze',
      bestConversion: 'Fráze s nejvyšší konverzi',
      bestConversionAmount: 'Fráze s největším počtem prodejů',
      searchCount: 'Počet vyhledávaní',
      searchInstances: 'Instance vyhledávačů',
    },
    statistics: {
      conversions: 'Konverze',
      phrase: 'Fráze',
      count: 'Počet vyhledávaní',
      clicks_count: 'Počet kliknutí',
      saleSum: 'Součet prodeje',
      saleAmount: 'Částka prodeje',
    },
    searchGraphBar: {
      headers: {
        searchCount: 'Vyhledávaní',
        searchClickCount: 'Kliknutí',
        searchClickThroughRate: 'CTR',
        salesAmount: 'Objednávky',
        salesTotal: 'Prodej',
        salesConversion: 'Konverze',
      },
      tooltip: {
        searchCount: 'Vyhledávaní',
        searchClickCount: 'Kliknutí',
        searchClickThroughRate: 'CTR',
        salesAmount: 'Objednávky',
        salesTotal: 'Prodej',
        salesConversion: 'Konverze',
      },
    },
    searchPhrases: {
      header: 'Analýza hledaných frází',
      download: 'Stáhnout zprávu',
      phrase: 'Fráze',
      searchCount: 'Dotazy vyhledávaní',
      clicks: 'Kliknutí',
      ctr: 'CTR',
      conversion: 'Konverze',
      salesCount: 'Nákupy',
      salesUnitsSold: 'Prodáno',
      salesTotal: 'Součet',
    },
    searchPhrasesWithNoResults: {
      header: 'Seznam hledaných frází, které nevrátily žádné výsledky',
      download: 'Stáhnout zprávu',
      phrase: 'Fráze',
      searchCount: 'Dotazy vyhledávaní',
    },
    searchProducts: {
      header: 'Analýza hledaných produktů',
      download: 'Stáhnout zprávu',
      productId: 'ID produktu',
      productName: 'Název produktu',
      views: 'Zobrazení',
      clicks: 'Kliknutí',
      conversion: 'Konverze',
      salesCount: 'Nákupy',
      salesUnitsSold: 'Prodáno',
      salesTotal: 'Součet',
    },
    synonyms: {
      header: 'Synonyma',
      addNew: 'Přidat synonymum',

      createPopupHeader: 'Přidat synonymum',
      createPopupSynonymsLabel: 'Pokud uživatel zadá frázi',
      addSynonymInputTooltip: 'Přidat další synonymum',
      createPopupBaseWordLabel: 'hledat frázi',
      or: 'nebo',

      insertSuccessfulNotificationTitle: 'Výborně!',
      insertSuccessfulNotification: 'Data byla uložena správně',

      synonymColumnHeader: 'Po zadání fráze',
      baseWordColumnHeader: 'Vyhledávání',
      optionsHeader: 'Možnosti',
      editTooltip: 'Upravit',
      deleteTooltip: 'Smazat',

      deleteConfirmationHeader: 'Smazání synonyma',
      deleteConfirmationText: 'Jste si jisti, že chcete smazat synonymum?',
      deleteConfirmationAcceptText: 'Ano',
      deleteConfirmationCancelText: 'Zrušit',

      editPopupHeader: 'Upravit synonymum',
      editPopupSynonymLabel: 'Pokud uživatel zadá frázi',
      editPopupBaseWordLabel: 'vyhledat',
      editPopupSave: 'Uložit',
      editPopupCancel: 'Zrušit',

      saveSuccessfulNotificationTitle: 'Výborně!',
      saveSuccessfulNotification: 'Data byla uložena správně',

      popupSynonymInvalid: 'Neplatné synonymum',
      popupBaseWordInvalid: 'Neplatná fráze',
    },
    blockedPhrase: {
      header: 'Správa blokovaných slov',
      addNew: 'Přidat blokovanou frázi',

      createPopupHeader: 'Přidat blokovanou frázi',
      createPopupPhraseLabel: 'Pokud uživatel zadá frázi, vynechte ji z vyhledávání',

      addInputTooltip: 'Přidat další blokovanou frázi',
      or: 'nebo',

      insertSuccessfulNotificationTitle: 'Výborně!',
      insertSuccessfulNotification: 'Data byla správně uložena',

      phraseColumnHeader: 'Blokovaná fráze',
      optionsHeader: 'Možnosti',
      editTooltip: 'Upravit',
      deleteTooltip: 'Vymazat',

      deleteConfirmationHeader: 'Odstranění zablokované fráze',
      deleteConfirmationText: 'Opravdu chcete odstranit blokovanou frázi?',
      deleteConfirmationAcceptText: 'Ano',
      deleteConfirmationCancelText: 'Zrušit',

      editPopupHeader: 'Úprava blokované fráze',
      editPopupBlockedPhraseLabel: 'Fráze k blokování',
      editPopupSave: 'Uložit',
      editPopupCancel: 'Zrušit',

      saveSuccessfulNotificationTitle: 'Výborně!',
      saveSuccessfulNotification: 'Data byla správně uložena',

      popupInvalid: 'Neplatná blokovaná fráze',
    },
    immutablePhrase: {
      header: 'Ovládejte neohebná slova',
      addNew: 'Přidat neohebnou frázi',

      createPopupHeader: 'Přidat neohebnou frázi',
      createPopupLabel: 'Pokud uživatel zadá frázi, při vyhledávání ji nemění',

      addInputTooltip: 'Přidat další neohebnou frázi',
      or: 'nebo',

      insertSuccessfulNotificationTitle: 'Výborně!',
      insertSuccessfulNotification: 'Data byla správně uložena',

      phraseColumnHeader: 'Neohebná fráze',
      optionsHeader: 'Možnosti',
      editTooltip: 'Upravit',
      deleteTooltip: 'Vymazat',

      deleteConfirmationHeader: 'Odstranění neohebné fráze',
      deleteConfirmationText: 'Opravdu chcete smazat neměnnou frázi?',
      deleteConfirmationAcceptText: 'Ano',
      deleteConfirmationCancelText: 'Zrušit',

      editPopupHeader: 'Úprava neměnné fráze',
      editPopupLabel: 'Neohebná fráze',
      editPopupSave: 'Uložit',
      editPopupCancel: 'Zrušit',

      saveSuccessfulNotificationTitle: 'Výborně!',
      saveSuccessfulNotification: 'Data byla správně uložena',

      popupInvalid: 'Neplatná invariantní fráze',
    },
    settings: {
      header: 'Nastavení',
      generalTabTitle: 'Obecné',
      gaTabTitle: 'Google Analytics',
      enabled_on: 'Zapnutý',
      enabled_off: 'Vypnutý',
      showUnavailable: 'Zobrazit také nedostupné produkty',
      showOnlyWithImages: 'Zobrazit pouze produkty s obrázkem',
      showVariantsOfProduct: 'Zobrazit seskupený produkt s variantami',
      showAllVariantsOfProducts: 'Zobrazit všechny odpovídající varianty produktu',
      itemsPerPage: 'Počet produktů na stránku',
      analyticsEnabled: 'Reporting do Google Analytics je zapnutý.',
      analyticsDisabled: 'Reporting do Google Analytics je vypnutý.',
      analyticsMeasurementId: 'ID tracking',
      analyticsMeasurementIdTooltip: 'Np.: UA-000000-1',
      analyticsUseSiteSearchHeader: 'Způsob zasílání statistik',
      analyticsUseSiteSearchTooltip: 'Chcete-li použít události, vyberte možnost „událost“. Chcete-li použít službu GA Site Search, vyberte možnost „zobrazení stránky“.',
      analyticsUseCustomEvents: 'událost',
      analyticsUseSiteSearch: 'pageview',

      save: 'Uložit nastavení',
      unsaved: 'Máte neuložená nastavení.',
      errorGet: 'Při načítání nastavení došlo k problému. Prosím zkuste to znovu později.',
      success: 'Nastavení bylo úspěšně uloženo. Změny na vašem webu by měly být vidět za cca 15 minut.',
      errorSet: 'Při ukládání nastavení došlo k problému. Prosím zkuste to znovu později.',

      recentQueries: {
        title: 'Naposledy vyhledávané',
        on: 'Zapnuté',
        off: 'Vypnuté',
        amount: 'Počet zobrazených dotazů',
      },
      popularQueries: {
        title: 'Nejpopulárnější vyhledávání',
        on: 'Zapnuté',
        off: 'Vypnuté',
        durationInfo: 'Zobrazit poslední vyhledávání z:',
        duration: {
          1: '1 dne',
          3: '3 dnů',
          7: '7 dní',
          30: '30 dní',
          60: '60 dní',
          90: '90 dní',
        },
        amount: 'Počet zobrazených dotazů',
      },
      functionNotAvailable: 'Tato funkce není pro Vás aktuálně k dispozici',
      functionNotAvailableContact: 'Chcete-li jej spustit, napište na',
      functionNotAvailableContactOr: 'nebo',
      functionNotAvailableContactScheduleMeeting: 'domluvte si schůzku s naším poradcem',
    },
    tester: {
      inputPlaceholder: 'Zadejte frázi pro testování',
      noResultsHeader: 'Žádné výsledky',
      noResults: 'Pro tuto frázi jsme nenašli žádné shody. Může to být způsobeno chybějícími produkty nebo nesprávnou konfigurací.',
    },
    instanceList: {
      mainHeader: 'Seznam vyhledávačů',
      mainDescription: 'Vyhledávače umožňují definovat základní parametry prohledávání adresáře. Pomocí seznamu vyhledávačů můžete nastavit různé způsoby vyhledávání pro společný adresář.',
      addNew: 'Vytvořte nový vyhledávač',
      header: {
        id: 'ID vyhledávače',
        name: 'Název vyhledávače',
        catalog: 'Propojený adresář',
      },
    },
    instance: {
      header: 'Seznam instancí vyhledávače',
      addNew: 'Vytvořte novou instanci',
      tableHeader: {
        instanceName: 'Název instance vyhledávače',
        instanceId: 'Id instance vyhledávače',
        catalogName: 'Název adresáře',
        catalogId: 'Id přiřazeného adresáře',
        options: 'Možnosti',
      },
      add: {
        header: 'Vytvoření nové instance vyhledávače',
        description: 'Vítejte v průvodci vytvořením instance nového vyhledávače. Systém umožňuje tvorbu mnoha různě konfigurovaných vyhledávačů. Jejich počet závisí na vašem plánu.',
        label: {
          instanceName: 'Zadejte název instance vyhledávače',
          selectCatalog: 'Vyberte adresář ze seznamu nakonfigurovaných adresářů',
        },
        submit: 'Uložit',
        error: {
          message: 'Při ukládání instance prohlížeče došlo k chybě',
        },
      },
      details: {
        main: {
          header: 'ID vyhledávače: ',
          content: 'Optimalizace pro vyhledávače produktů je klíčem k tomu, aby zákazníci snáze našli přesně to, co hledají. Díky této oblasti si můžete přizpůsobit a zlepšit fungování vyhledávače ve vašem obchodě. Zkontrolujte a upravte hlavní konfiguraci instance vašeho vyhledávače. Zkontrolujte, zda konfigurace mapování katalogu do našich datových struktur obsahuje všechny atributy potřebné k vyhledávání ve vašich produktech',
        },
        settingsHeader: 'Základní nastavení vyhledávače',
        settingsDescription: 'Zde najdete základní nastavení vašeho vyhledávače',
        fields: {
          productsPerPage: 'Počet produktů na stránku',
          popularQueriesLimit: 'Limit vrácených oblíbených frází',
          popularQueriesInterval: 'Časový rozsah vrácených frází',
        },
        save: 'Uložit',
      },
    },
  },
  profile: {
    logout: 'Odhlásit se',
    goToStage: 'Přejděte do režimu náhledu',
  },
  subscription: {
    card_title_new_account: 'Nové předplatné',
    first_name: 'Jméno',
    last_name: 'Příjmení',
    address1: 'Adresa',
    city: 'Město',
    country: 'Stát',
    postal_code: 'PSČ',
    company_name: 'Název firmy',
    vat_number: ' DIČ',
    save_card: 'Uložit kartu',
    subscription_active: 'Vaše předplatné je aktivní',
    card_title_new_subscription: 'Předplatné',
    need_subscription: 'Vaše předplatné je neaktivní',
    activate: 'Aktivovat',
    ends_at: 'Nové fakturační období začne',
    offer: {
      search_shoptet: {
        title: 'Smart Search',
        subtitle: 'Nejlepší vyhledávač na trhu pod Shoptet!',
        features: [
          'Automatické doplňování vyhledávacího pole - autocomplete vyhledávač',
          'Stránka s výsledky pokročilého vyhledávání (SERP)',
          'Podpora synonym',
          'Rozpoznávání chyb v zadaných heslech',
          'Jazyková rozmanitost',
          'Filtrování výsledků',
          'Obohacení vyhledávače daty z katalogu produktů',
          'Univerzální šablona výsledků hledání',
          'Integrace s Google Analytics',
        ],
      },
      netto: 'Všechny ceny v naší nabídce jsou ceny bez DPH.',
    },
    validation: {
      number: 'Číslo karty je nesprávné.',
      month: 'Měsíc je nesprávný.',
      year: 'Rok je nesprávný.',
      first_name: 'Jméno je nesprávné.',
      last_name: 'Přijmení je nesprávné.',
      address1: 'Adresa je nesprávně.',
      city: 'Město je nesprávně.',
      country: 'Stát je nesprávně.',
      postal_code: 'PSČ je nesprávně.',
      company_name: 'Název firmy je nesprávně.',
      vat_number: 'DIČ je nesprávně.',
    },
  },
  catalog: {
    header: 'Přidávání katalogu produktů',
    description: 'Nakonfigurujte poskytovatele katalogu vašeho obchodu se specifikací (zkontrolujte specifikaci',
    here: 'tady )',
    provideName: 'Zadejte název adresáře',
    provideLink: 'Zadejte adresu URL katalogu (ve formátu XML, požadovaný formát Google Merchants, maximální velikost souboru 512 MB)',
    invalidLinkProvided: 'Byla zadána neplatná adresa URL',
    industry: {
      choose: 'Vyberte si odvětví svého obchodu',
      chooseFromList: 'Vyberte si průmysl',
      errorDuringLoading: 'Při zpracování vašich dat došlo k chybě...',
      invalidSelection: 'Nesprávný výběr odvětví',
    },
    language: {
      choose: 'Vyberte jazyk ze seznamu',
      chooseFromList: 'Vyber jazyk',
      errorDuringLoading: 'Při zpracování vašich dat došlo k chybě...',
      invalidSelection: 'Nesprávný výběr jazyka',
    },
    currency: {
      choose: 'Vyberte měna ze seznamu',
      chooseFromList: 'Vyber měna',
      errorDuringLoading: 'Při zpracování vašich dat došlo k chybě...',
      invalidSelection: 'Nesprávný výběr měna',
    },
    error: {
      duringCatalogSave: 'Při ukládání adresáře došlo k chybě',
    },
    refreshSettings: {
      header: 'Nastavení aktualizace katalogu',
      description: 'Nastavte, jak často se má adresář obnovovat',
      maxCount: 'Můžete přidat až %maxDayCount% položek',
      timeDescription: 'Katalog bude zpracováván denně v uvedených časech',
      addNewPeriod: 'Přidejte další hodinu',
      success: 'Úspěch',
      periodsSaved: 'Data obnovení adresáře uložena správně',
      save: 'Uložit',
      errors: {
        min_interval_time: 'Minimální časový interval je 1 hodina',
        distinct_time: 'Časy aktualizací nemohou být stejné',
      },
    },
    xmlPreview: {
      showXmlPreview: 'Zobrazit náhled xml',
      next: 'Další',
      prev: 'Předchozí',
    },
    productPreview: {
      requiredFields: 'Povinná pole',
      productName: 'Jméno produktu',
      productId: 'Id produktu',
      productCategory: 'Kategorie',
      productLink: 'Url produktu',
      productImage: 'Obrázek produktu',
      productAvailability: 'Dostupnost',
      productPrice: 'Cena',
      additionalFields: 'Další pole',
      missingAdditionalFields: 'Chybí další pole',
      productPreview: 'Náhled produktu',
    },
    instance: {
      addNew: 'Nastavte nový adresář',
      mainHeader: 'Seznam adresářů',
      mainDescription: 'Katalog produktů je velmi důležitým prvkem systému qon. Systém vám umožňuje konfigurovat adresář sami. S konfigurací nového katalogu produktů vám pomůže průvodce katalogem, který vás provede konfigurací krok za krokem.',
      showDetails: 'Ukaž mi podrobnosti',
      delete: 'Vymazat',
      workingDirectory: 'pracovní adresář',
      history: 'Historie importu',
      editMapping: 'Upravit mapování',
      editTimeRefresh: 'Upravit čas aktualizace',
      categoryMappings: 'Mapování kategorií',
      deletetips: {
        notproduction: 'Pracovní adresář nelze smazat',
        assignedSearch: 'Adresář s přiřazenými vyhledávači nelze smazat',
      },
      header: {
        id: 'Id adresáře',
        name: 'Název',
        url: 'Cesta k adresáři',
        type: 'Typ',
        language: 'Jazyk',
        industry: 'Průmysl',
      },
      mapping: {
        header: 'Seznam mapovaných polí',
        description: 'Seznam mapovaných polí z poskytnutého adresáře do adresáře použitelného v systému QON. Nalezené prvky se transformují na:',
        foundElement: 'Položka nalezena',
        changedTo: '(Typ) Změněno na',
        notSet: 'Mapování není přiřazeno',
      },
      import: {
        header: 'Obnovit seznam časů',
        description: 'Pro zajištění nejaktuálnějších údajů o produktech jsou katalogy zpracovávány cyklicky. Katalog je zpracováván každý den v hodinách:',
      },
      details: {
        header: 'Podrobnosti adresáře id: {catalogId}',
        description: 'V této oblasti můžete zkontrolovat konfiguraci a analyzovat produktový katalog. Přizpůsobte a ověřte, jak jsou atributy vašeho produktu mapovány v našich datových strukturách, což je zásadní pro přesnost a konzistenci prezentovaných informací. Upravte dobu zpracování katalogu tak, aby nabídka byla co nejaktuálnější. V seznamu produktů můžete zkontrolovat, jak byly produkty importovány do systému.',
      },
      categoriesTree: {
        header: 'Mapování kategorií pro katalogové ID: {catalogId}',
        refreshButton: 'Obnovte strom kategorií',
        ownMappings: 'Vlastní',
        parentMappings: 'Od rodiče',
        notSetMappings: 'Nenastaveno',
        refreshInfo: 'Po obnovení bylo přidáno {added} z {total}',
      },
    },
    timeSelect: {
      labelHours: 'Čas',
      labelMinutes: 'Minuta',
      labelNowButton: 'Aktuální čas',
      labelCloseButton: 'Zavřít',
    },
    productList: {
      title: 'Seznam produktů',
      product: {
        id: 'Id:',
        price: 'Cena:',
        availability: 'Dostupnost:',
        categories: 'Kategorie:',
        link: 'Stránka produktu:',
        image: 'Url obrázku:',
        imageClickDescription: 'Kliknutím na obrázek přejdete na stránku produktu uvedenou v katalogu',
        variantsCount: 'žádné varianty | 1 varianta | {n} varianty | {n} variant ',
      },
      showProductsDetails: 'Ukaž detaily',
      hideProductsDetails: 'Skrýt detaily',
      showProduct: 'Ukázat produkt',
    },
  },
  catalogHistory: {
    header: 'Historie importu katalogu',
    shortDescription: 'Níže naleznete historii importu katalogu',
    lastImport: 'Poslední stav importu',
    tableHeaders: {
      date: 'Datum',
      owner: 'Povolání',
      status: 'Postavení',
      correctProductsCount: 'Správné produkty',
      incorrectProductsCount: 'Špatné produkty',
    },
    status: {
      unknown: 'Neznámý',
      ok: 'OK',
      error: 'Nastala chyba',
      in_progress: 'Zpracovává se',
    },
    laststatus: {
      unknown: 'Nastala neznámá chyba',
      ok: 'Opravit',
      error: 'Nesprávně',
      in_progress: 'Zpracovává se',
    },
    yoursCatalogLastImportDesc: 'Váš adresář byl naposledy importován:',
    yoursCatalogLastImportDescProducts: 'Celkový počet produktů nalezených v katalogu:',
    correctProducts: 'správné produkty',
    incorrectProducts: 'nesprávné produkty',
    errorResponse: 'Vyskytla se chyba. Zkuste to prosím později',
  },
  industry: {
    animals: 'zvířat',
    automotive: 'automobilový průmysl',
    beauty: 'krása',
    books_and_multimedia: 'knihy a multimédia',
    education: 'vzdělávání',
    electronic: 'electronic',
    entertainment: 'elektronický',
    erotic: 'erotický',
    fashion: 'fashion',
    food: 'móda',
    health: 'zdraví',
    hobby: 'hobby',
    home_and_garden: 'Dům a zahrada',
    gifts_toys: 'dárky a hračky',
    jewellery_gallantry: 'šperky a galantnost',
    office: 'kancelář',
    other_goods: 'jiné (zboží)',
    other_services: 'Ostatní (služby)',
    sport: 'sport',
    travel_and_leisure: 'cestování a volný čas',
    other: 'jiný',
  },
  language: {
    pol: 'Polština',
    cze: 'čeština',
    slo: 'Slovák',
    eng: 'Angličtina',
    ukr: 'Ukrajinština',
    rum: 'Rumunština',
    bul: 'Bulharský',
    ger: 'Němec',
    hrv: 'Chorvatský',
    hun: 'Maďarský',
    ita: 'Italština',
  },
  apiAuthorization: {
    header: 'Konfigurace přístupu API',
    domainWhitelist: {
      header: 'Konfigurace přístupu k API vyhledávače na základě whitelistu domén, ze kterých přicházejí požadavky. Whitelist umožňuje integrovat vyhledávač na úrovni frontendu internetového obchodu. Rozhraní API zpracuje jakýkoli požadavek iniciovaný z kterékoli z domén uvedených níže. Požadavky z domén, které nejsou na seznamu a nejsou jinak autorizovány, budou zamítnuty.',
      tabTitle: 'Přístup na seznam povolených',
      desc: 'Zadejte domény, které budou autorizovány rozhraním API.',
      newDomainLabel: 'Nová autorizovaná doména',
      add: 'Přidat',
      domain: 'Doména',
      action: 'Podíl',
      deleteTooltip: 'Vymazat',
      save: 'Uložit',
    },
    key: {
      header: 'Konfigurace přístupu k API pomocí klíče. Všechny žádosti podepsané klíčem budou zpracovány. Autorizace pomocí klíče umožňuje integrovat API vyhledávače s backendem internetového obchodu. Všechny požadavky, které jsou vyvolány s platným klíčem, budou zpracovány. Pokud je klíč nesprávný nebo není poskytnut a aplikace pro rozhraní API není jinak autorizována, bude zamítnuta. Nezapomeňte klíč nezveřejňovat, je jedinečný a přiřazený k vašemu účtu. Pokud existuje podezření, že byl klíč kompromitován, doporučuje se vygenerovat klíč znovu. Všimněte si, že vygenerování klíče smaže aktuální klíč.',
      tabTitle: 'Přístupové klíče',
      url: 'URL rozhraní API',
      key: 'Klíč',
      copyUrl: 'Kopírovat',
      copyKey: 'Kopírovat',
      resetKey: 'Resetovací klíč',
    },
  },
  catalogImport: {
    title: 'Informace o importovaném adresáři',
    selectedFeed: 'Uvedený zdroj produktů:',
    analyzingCatalog: 'Adresář se analyzuje, může to chvíli trvat',
    correctlyImported: 'Dovážené produkty',
    incorrectlyImported: 'Nesprávné dovozy',
    downloadingProducts: 'Probíhá stahování seznamu produktů',
    catalogProductsList: {
      show: 'Ukázat',
      correctImportedProductListTitle: 'Seznam správně dovezených produktů',
      incorrectlyImportedProductListTitle: 'Chybový seznam produktů',
      downloadFile: 'Stáhnout soubor',
      variantsCount: 'Počet variant produktu',
      title: 'Jméno výrobku',
      id: 'Id',
      price: 'Cena',
      categories: 'Kategorie',
      image: 'Obrázek',
      actions: 'Akce',
    },
    warnings: {
      Model: {
        Exception: {
          ValidationException: 'Chyba ověření',
        },
      },
      Normalizers: {
        Exceptions: {
          IdentifierCannotBeEmptyException: 'ID produktu nemůže být prázdné',
        },
      },
    },
  },
  sortSettings: {
    header: 'Řazení',
    sortSearch: 'Search',
    sortBestseller: 'Bestsellers',
    sortPopular: 'Populární',
    add: 'Přidat řazení',
    save: 'Uložte nastavení',
    upTooltip: 'Nahoru',
    downTooltip: 'Dolů',
    deleteTooltip: 'Vymazat',
    theadLabel: {
      sortName: 'Seřadit název',
      sortLabel: 'Seřadit štítek',
      sortType: 'Typ pole',
      additionalData: 'Doplňující údaje',
      actions: 'Akce',
      order: 'Řazení',
    },
  },
  weightsSettings: {
    header: 'Nastavení hmotnosti',
    headerAdd: 'Přidat',
    headerList: 'Definované váhy',
    description: 'V tomto panelu můžete nastavit váhy jednotlivých produktových polí vašeho katalogu a kritéria vyhledávání. Tato nastavení ovlivňují pozici nalezených produktů při vyhledávání v SearchApi a způsob, jakým je vyhledávací fráze přiřazena k tomuto poli.',
    selectSearch: 'Vyberte si vhodnou formu',
    selectOne: 'Vyberte prosím alespoň jednou',
    strategyDescription: {
      full: 'Zaškrtněte tuto možnost, pokud je v poli uloženo ID produktu, bude použita přesná shoda hledané fráze',
      match: 'je metoda vyhledávání, která umožňuje zahrnout více kritérií vyhledávání, jako je obsah, název, popis a další. Uživatelé díky tomu budou mít větší šanci najít produkty, které hledají. Díky tomu je tento způsob ideální pro internetové obchody, které nabízejí širokou škálu produktů a zákazníci mohou vyhledávat produkty podle různých kritérií.',
      exact: 'je metoda vyhledávání, která se zaměřuje na přesnou shodu hledané fráze s názvem produktu nebo jeho vlastnostmi. Uživatelé díky tomu budou mít větší jistotu, že najdou přesně to, co hledají, což je důležité zejména u internetových obchodů, které nabízejí produkty se speciálními vlastnostmi nebo s unikátními názvy.',
    },
    shortDescription: 'Níže je uveden seznam polí, na kterých bude systém hledat výsledky a s jakou silou tyto výsledky posílí',
    allFieldsSelected: 'Byla přidána všechna dostupná pole',
    tableHeaders: {
      field: 'Pole',
      weight: 'Váhy',
      actionAdd: '',
      actionRemove: 'Vymazat',
      actionView: 'Akce',
      fullMatchField: 'ID produktu',
      matchFields: 'Multimatch',
      exactMatchFields: 'ExactSearch',
      empty: '',
    },
    descriptionButton: {
      hide: 'Skrýt',
      show: 'Ukázat',
      description: 'popis',
    },
    fields: {
      id: 'Id',
      title: 'Název produktu',
      categories: 'Kategorie',
      link: 'Odkaz na produkt',
      image_link: 'Url obrázku',
      availability: 'Dostupnost',
      price: 'Cena',
    },
    add: 'Přidat',
  },
  facetedSearchSettings: {
    header: 'Nastavení fasetového menu',
    description: 'Fasetové menu je druh rozhraní, které nám umožňuje snadné filtrování. Díky němu můžeme přesně určit, jaké prvky chceme ve výsledcích vyhledávání vidět a které naopak vyloučit. To je velmi užitečné v internetových obchodech, inzertních webech a dalších aplikacích, kde hledáme konkrétní informace. Ve fasetovaném menu máme několik rozbalovacích seznamů, ve kterých vybíráme kategorie nebo vlastnosti prvků, které nás zajímají, např. barvu, značku, cenu, velikost atd. Díky tomu je proces vyhledávání jednodušší a přesnější.',
    listHeader: 'Seznam vrácených polí',
    shortDescription: 'Seznam vrácených polí v koncovém bodu.',
    headerAdd: 'Přidejte nové pole',
    addNewFieldLabel: 'Vyberte pole',
    addNewLabelLabel: 'Dejte tomu jméno',
    addNewTypeLabel: 'Nastavit funkci',
    addNewUnitLabel: 'Určete jednotku',
    filtersHeader: 'Definované filtry',
    add: 'Přidat',
    allFieldsSelected: 'Byla přidána všechna dostupná pole',
    tableHeaders: {
      field: 'Pole',
      label: 'Zobrazovaný název',
      type: 'Funkce',
      actionView: 'Akce',
    },
    fields: {
      id: 'Id',
      title: 'Název produktu',
      categories: 'Kategorie',
      link: 'Odkaz na produkt',
      image_link: 'Url obrázku',
      availability: 'Dostupnost',
      price: 'Cena',
    },
    types: {
      category: 'Kategorie',
      range: 'Zip',
      checkbox: 'Zaškrtávací políčko',
      rangeSlider: 'Posuvník rozsahu',
    },
    upTooltip: 'Nahoru',
    downTooltip: 'Dolů',
    deleteTooltip: 'Vymazat',
    descriptionButton: {
      hide: 'Skrýt',
      show: 'Ukázat',
      description: 'popis',
    },
  },
  catalogMapping: {
    fields: {
      productId: 'ID produktu',
      title: 'Titul',
      categories: 'Kategorie',
      productLink: 'Odkaz na stránku produktu',
      imageLink: 'Odkaz na obrázek produktu',
      availability: 'Dostupnost',
      price: 'Cena',
    },
    pickField: 'Vyberte pole',
    addNewField: 'Přidejte nové pole z adresáře',
    pickSeparator: 'Definujte oddělovač položek',
    requiredFields: {
      tableName: 'Povinná pole',
      theadLabel: {
        qonId: 'Qon Id',
        feedField: 'Katalogové pole',
        dataType: 'Datový typ',
        description: 'Popis',
      },
    },
    additionalFields: {
      notConfigured: 'Momentálně nemáte nakonfigurovaná žádná vlastní pole',
      tableName: 'Další pole',
      theadLabel: {
        qonId: 'Qon Id',
        feedField: 'Katalogové pole',
        dataType: 'Datový typ',
        action: 'Akce',
      },
    },
    invalid: {
      qonId: 'Neplatné ID',
      feedPath: 'Špatně zvolená cesta',
      dataType: 'Nesprávně vybraný datový typ',
      separator: 'Nesprávně zvolený oddělovač',
    },
    error: {
      duringFindingXmlPaths: 'Při hledání cest v souboru XML došlo k chybě',
      fieldsInvalid: 'V mapování cest ze souboru do polí adresáře jsou chyby - opravte konfiguraci mapování',
      storeRequestFailed: 'Při zápisu mapování adresáře došlo k chybě - kontaktujte prosím podporu QON',
      storeRequestUnknown: 'Došlo k neznámé chybě – kontaktujte podporu QON',
    },
  },
  catalogWizard: {
    next: 'Další',
    previous: 'Předchozí',
    save: 'Uložit',
    tabsTitle: {
      basicConfiguration: 'Základní konfigurace',
      setMapping: 'Nastavení mapování',
      catalogPreview: 'Náhled katalogu',
      refreshSettings: 'Nastavení napájení adresáře',
    },
  },
  htmlTemplate: {
    code: {
      title: 'Kód html šablony',
    },
    preview: {
      title: 'Náhled šablony',
      numberOfProducts: 'Kolik produktů vygenerovat?',
      email: 'Zadejte e-mail pro testování',
      widgetId: 'Zadejte ID widgetu',
      widgetIdList: 'Podívejte se na seznam widgetů na panelu',
    },
    settings: {
      title: 'Nastavení',
    },
    tooltip: {
      editSettings: 'Upravit nastavení šablony',
      editCode: 'Upravte html kód šablony',
      generateSnippets: 'Vygenerujte úryvek',
    },
    editSettings: 'Upravujete nastavení šablony:',
    editCode: 'Upravujete kód šablony:',
    generateSnippets: 'Pro šablonu vygenerujete fragment HTML:',

    saveButtonLabel: 'Uložit',
    cancelButtonLabel: 'Zrušení',
    newTemplateButton: 'Vytvořte novou šablonu',
    title: 'Správa šablon HTML (Image Mailing API v2)',
    widthLabel: 'Šířka',
    widthErrorLabel: 'Nesprávná šířka',
    heightLabel: 'Výška',
    heightErrorLabel: 'Nesprávná výška',
    idLabel: 'Id šablony',
    idErrorLabel: 'Neplatné Id šablony',
    nameLabel: 'Název šablony',
    nameErrorLabel: 'Neplatný název šablony',
  },
  searchTry: {
    testYourSearch: {
      title: 'Otestujte svůj vyhledávač',
      subtitle: 'vaše produkty čekají, až je najdete',
      inputPlaceholder: 'Názvy produktů, kategorie, štítky...',
    },
    selectSortType: 'Jak seřadit výsledky:',
    resultList: {
      product: {
        id: 'Id',
        price: 'Cena',
      },
    },
  },
  appV3Settings: {
    header: 'Nastavení aplikace vyhledávání',
    tabGeneral: 'Obecná nastavení',
    tabOnfocus: 'Nastavení Onfocus',
    tabAutocomplete: 'Nastavení Autocomplete',
    tabSerp: 'Nastavení stránky výsledků vyhledávání',
    fieldValue: {
      true: 'ANO',
      false: 'NE',
    },
    desc: {
      appConfiguration: 'V této sekci můžete upravit obecná nastavení aplikace, jako je URL přesměrování, zacházení s desetinnými místy v cenách, volba měny a používání hash před parametry vyhledávání. Tato nastavení jsou globální a ovládají chování celého vyhledávacího rozhraní.',
      onfocus: 'Tato sekce obsahuje úpravy pro pole vyhledávání, jako je zobrazování obrázků produktů při aktivaci onfocus, nastavení nedávných a oblíbených dotazů a možnost přizpůsobení seznamu populárních produktů.',
      autocomplete: 'Zde můžete upravit, které návrhy by měly být zobrazeny při zadávání fráze, počet výsledků automatického doplňování a obrázky, které doprovázejí návrhy.',
      serp: 'Tato sekce obsahuje úpravy týkající se zobrazení výsledků vyhledávání, jako jsou filtry, řazení, stránkování, seznam tagů a velikost obrázků produktů.',
    },
    group: {
      onfocus: {
        popularProducts: 'Oblíbené produkty',
        popularQueries: 'Oblíbené dotazy',
        predefinedQueries: 'Vlastní dotazy',
        productImageSize: 'Velikost obrázků produktů',
        recentQueries: 'Nedávná hledání',
        topProduct: 'Top produkty',
      },
      autocomplete: {
        bestseller: 'Bestsellery',
        productImageSize: 'Velikost obrázků produktů',
        results: 'Výsledky',
        suggestions: 'Návrhy',
      },
      serp: {
        query: 'Dotazy',
        tagList: 'Seznam tagů',
        productImageSize: 'Velikost obrázků produktů',
        filters: 'Filtry',
        gridSettings: 'Rozložení produktů',
        markedFilters: 'Vybrané filtry',
        pagination: 'Stránkování',
        customComponents: 'Vlastní šablony',
        sorting: 'Řazení',
      },
    },
    fields: {
      appConfiguration: {
        applicationEnabled: 'Povolení vyhledávací aplikace',
        redirectionUrl: 'Adresa přesměrování',
        removeDecimals: 'Odstranit desetinné části',
        currency: 'Měna',
        useHashBeforeSearchParams: 'Použít hash před parametry vyhledávání',
      },
      onfocus: {
        showOnfocus: 'Zobrazit zaměření?',
        popularProducts: {
          count: 'Počet populárních produktů',
          sortName: 'Řazení použité v populárních produktech',
          enabled: 'Povoleny populární produkty',
        },
        popularQueries: {
          enabled: 'Povoleny populární dotazy',
          count: 'Počet populárních dotazů',
        },
        predefinedQueries: {
          enabled: 'Povolit vlastní dotazy?',
          sectionsList: 'Definované dotazy',
          sectionsListAdd: 'Přidejte sekcję',
          sections: {
            '*': {
              header: 'Hlavička sekce',
              queriesList: 'Dotazy',
              queriesListAdd: 'Přidejte štítek',
              queries: {
                '*': {
                  query: 'Štítek dotazu',
                  link: 'URL',
                },
              },
            },
          },
        },
        productImageSize: {
          fixWidth: 'Nastavit šířku obrázku produktu',
          width: 'Šířka obrázku produktu',
          fixHeight: 'Nastavit výšku obrázku produktu',
          height: 'Výška obrázku produktu',
        },
        recentQueries: {
          enabled: 'Zobrazit poslední vyhledávání?',
          count: 'Počet nedávných vyhledávání',
        },
        topProduct: {
          enabled: 'Povoleny nejlepší produkty',
          productImageSize: {
            fixWidth: 'Nastavit šířku oblíbené fotografie produktu',
            width: 'Šířka obrázku nejlepšího produktu',
            fixHeight: 'Nastavit výšku oblíbené fotografie produktu',
            height: 'Výška obrázku nejlepšího produktu',
          },
        },
      },
      autocomplete: {
        showButtonOnInput: 'Zobrazit tlačítko ve vyhledávacím poli?',
        showAutocomplete: 'Zobrazit automatické doplňování?',
        bestseller: {
          enabled: 'Jsou zahrnuty bestsellery?',
        },
        productImageSize: {
          fixWidth: 'Nastavit šířku obrázku produktu',
          width: 'Šířka obrázku produktu',
          fixHeight: 'Nastavit výšku obrázku produktu',
          height: 'Výška obrázku produktu',
        },
        results: {
          count: 'Počet zobrazených výsledků',
        },
        suggestions: {
          enabled: 'Povoleny návrhy',
          count: 'Počet návrhů',
        },
      },
      serp: {
        showInput: 'Zobrazit vyhledávací pole',
        showButtonOnInput: 'Zobrazit tlačítko ve vyhledávacím poli',
        backToTopButton: {
          enabled: 'Povolen tlačítko „Zpět nahoru“',
        },
        query: {
          allowEmptyQuery: 'Povolit prázdný dotaz',
        },
        sorting: {
          showSorting: 'Zobrazit řazení',
        },
        tagList: {
          showTagList: 'Zobrazit seznam značek:',
          limitPerFilter: 'Omezit filtr seznamu značek',
        },
        productImageSize: {
          useQuarticImages: 'Použít obrázky ze serveru Quarticon',
          fixWidth: 'Nastavit šířku obrázku produktu',
          width: 'Šířka obrázku produktu',
          fixHeight: 'Nastavit výšku obrázku produktu',
          height: 'Výška obrázku produktu',
        },
        filters: {
          showAsideFilters: 'Zobrazit boční filtry',
          showTopFilters: 'Zobrazit top filtry',
        },
        gridSettings: {
          column: {
            smallDevice: 'Počet produktů v řádku pro malá zařízení',
            mediumDevice: 'Počet produktů v řádku pro střední zařízení',
            largeDevice: 'Počet produktů v řádku pro velká zařízení',
            extraLargeDevice: 'Počet produktů v řádku pro velmi velká zařízení',
          },
        },
        markedFilters: {
          enabled: 'Označovat vybrané filtry - povoleno',
        },
        pagination: {
          useTop: 'Zobrazit stránkování v horní části stránky',
          useBottom: 'Zobrazit stránkování v dolní části stránky',
        },
        customComponents: {
          productCard: 'Vlastní šablona produktové karty',
          autocompleteSingleResult: 'Vlastní šablona výsledku Autocomplete',
          singlePopularProduct: 'Vlastní šablona jednoho populárního produktu',
          topProduct: 'Vlastní šablona nejlepších produktů',
        },
      },
    },
    fieldsTooltip: {
      appConfiguration: {
        applicationEnabled: 'Povolí/zakáže aplikaci předního vyhledávání',
        redirectionUrl: 'Nastavte na které stránce chcete, aby se zobrazil výsledný výsledek hledání',
        removeDecimals: 'Chcete odstranit desetinné části .00 při zobrazení cen?',
        currency: 'Nastavte měnu, ve které jsou ceny vráceny',
        useHashBeforeSearchParams: 'Pokud je stránka založena na předávání parametrů pomocí hash, nastavte to',
      },
      onfocus: {
        showOnfocus: 'Chcete zobrazit okno onfocus po kliknutí na vstup?',
        productImageSize: {
          fixWidth: 'Měly by mít fotografie generované onfocusem nastavenou šířku? Zrušením zaškrtnutí se šířka automaticky nastaví.',
          width: 'Nastavte velikost obrázků generovaných na onfocus - šířka',
          fixHeight: 'Měly by mít fotografie generované onfocusem nastavenou výšku? Zrušením zaškrtnutí se výška automaticky nastaví.',
          height: 'Nastavte velikost obrázků generovaných na onfocus - výška',
        },
        popularQueries: {
          enabled: 'Mají se zobrazovat uživateli populární dotazy?',
          count: 'Kolik dotazů zobrazit v populárních frázích ',
        },
        popularProducts: {
          count: 'Kolik populárních produktů zobrazit',
          sortName: 'Třídění použité u populárních produktů',
          enabled: 'Mají se zobrazovat populární produkty? Určte podle jakého třídění mají být vráceny produkty. Můžete vytvořit třídění podle bestsellerů a zadat je zde.',
        },
        topProduct: {
          enabled: 'Má se propagovat první vrácený produkt mezi populárními produkty?',
          productImageSize: {
            fixWidth: 'Měly by mít fotografie propagovaných produktů stanovenou šířku? Zrušením zaškrtnutí se šířka automaticky nastaví.',
            width: 'Nastavte velikost obrázků pro propagované produkty - šířka',
            fixHeight: 'Měly by mít fotografie propagovaných produktů stanovenou výšku? Zrušením zaškrtnutí se výška automaticky nastaví.',
            height: 'Nastavte velikost obrázků pro propagované produkty - výška',
          },
        },
        predefinedQueries: {
          enabled: 'Mají se zobrazovat předdefinované dotazy?',
          sectionsList: 'Pokud chcete uživateli zobrazit předdefinované dotazy, můžete je přidat zde. Poskytnutím odkazu bude uživatel přesměrován na daný odkaz. Při přidání předdefinovaného dotazu bez odkazu bude dotaz použit k jeho vyhledání',
          sections: {
            '*': {
              header: 'Nadpis viditelný před seznamem dotazů',
              queriesList: '',
              queries: {
                '*': {
                  query: 'Štítek viditelný jako odkaz. Pokud není níže uveden odkaz URL, jedná se také o dotaz Search',
                  link: 'Odkaz URL na stránku',
                },
              },
            },
          },
        },
        recentQueries: {
          enabled: 'Má se uživateli zobrazovat jeho poslední hledání',
          count: 'Počet posledních hledání',
        },
      },
      autocomplete: {
        showButtonOnInput: 'Má se na vstupu vyhledávání zobrazit tlačítko Hledat?',
        showAutocomplete: 'Má se během hledání zobrazovat okno s automatickým doplňováním?',
        bestseller: {
          enabled: 'Má se první produkt propagovat jako Bestseller?',
        },
        productImageSize: {
          fixedWidth: 'Měly by mít fotografie generované pomocí automatického doplňování definovanou šířku? Zrušením zaškrtnutí se šířka automaticky nastaví.',
          width: 'Velikost obrázků v automatickém doplňování - šířka',
          fixedHeight: 'Měly by fotky generované pomocí automatického doplňování mít nastavenou výšku? Zrušením zaškrtnutí se výška automaticky nastaví.',
          height: 'Velikost obrázků v automatickém doplňování - výška',
        },
        suggestions: {
          enabled: 'Mají se uživateli zobrazovat návrhy dotazů pro zadanou frázi?',
          count: 'Počet návrhů zobrazených uživateli',
        },
        results: {
          count: 'Počet prezentovaných výsledků',
        },
      },
      serp: {
        showInput: 'Má se na stránce výsledků hledání zobrazovat další vstup?',
        showButtonOnInput: 'Má se na vstupu zobrazovat tlačítko Hledat?',
        tagList: {
          showTagList: 'Má se uživateli zobrazovat sekce štítků nad výsledky hledání? Platí pouze pro filtry typu string nebo box?',
          limitPerFilter: 'Omezení zobrazených štítků nad výsledky hledání',
        },
        query: {
          allowEmptyQuery: 'Má se umožnit zobrazovat všechny produkty na stránce výsledků, když uživatel nezadal žádný dotaz?',
        },
        filters: {
          showAsideFilters: 'Má se uživateli zobrazovat sekce filtrů na stránce výsledků hledání?',
          showTopFilters: 'Zobrazit uživateli sekci filtru nad výsledky vyhledávání?',
        },
        sorting: {
          showSorting: 'Má se uživateli zobrazovat tlačítko pro změnu třídění?',
        },
        pagination: {
          useTop: 'Má se uživateli zobrazovat tlačítko pro změnu stránky nahoře na stránce?',
          useBottom: 'Má se uživateli zobrazovat tlačítko pro změnu stránky dole na stránce?',
        },
        backToTopButton: {
          enabled: 'Má se uživateli zobrazovat tlačítko zpět nahoru na stránce?',
        },
        markedFilters: {
          enabled: 'Má se uživateli zobrazovat sekce označených filtrů?',
        },
        productImageSize: {
          useQuarticImages: 'Mají obrázky generované na výsledcích uživatele pocházet ze serveru Quarticon',
          fixedWidth: 'Měly by mít fotografie generované ve výsledcích vyhledávání stanovenou šířku? Zrušením zaškrtnutí se šířka automaticky nastaví.',
          width: 'Nastavte velikost obrázků generovaných na výsledcích uživatele - šířka',
          fixedHeight: 'Měly by mít fotografie generované ve výsledcích vyhledávání stanovenou výšku? Zrušením zaškrtnutí se výška automaticky nastaví.',
          height: 'Nastavte velikost obrázků generovaných na výsledcích uživatele - výška',
        },
        gridSettings: {
          column: {
            smallDevice: 'Nastavte, kolik produktů ve sloupci by se mělo zobrazit na malém zařízení, např. telefon',
            mediumDevice: 'Nastavte, kolik produktů ve sloupci by se mělo zobrazit na středním zařízení, např. tablet',
            largeDevice: 'Nastavte, kolik produktů ve sloupci by se mělo zobrazit na velkém zařízení, např. monitor s rozlišením 1080p',
            extraLargeDevice: 'Nastavte, kolik produktů ve sloupci by se mělo zobrazit na velkém zařízení, např. monitor s rozlišením 4k',
          },
        },
        customComponents: {
          productCard: 'Když chcete změnit vzhled dlaždice produktu na stránce SERP, specifikujte zde nové HTML s makry pro jeho generování. Více zde: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          autocompleteSingleResult: 'Když chcete změnit vzhled dlaždice produktu v automatickém doplňování, specifikujte zde nové HTML s makry pro jeho generování. Více zde: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          singlePopularProduct: 'Když chcete změnit vzhled dlaždice produktu v sekci populárních produktů, specifikujte zde nové HTML s makry pro jeho generování. Více zde: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          topProduct: 'Když chcete změnit vzhled dlaždice produktu v sekci nejlepších produktů, specifikujte zde nové HTML s makry pro jeho generování. Více zde: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
        },
      },
    },
    deleteAsk: 'Opravdu chcete smazat?',
    delete: 'Vymazat',
    save: 'Uložit',
    saveSuccess: 'Data byla uložena správně',
  },
  appV3Labels: {
    header: 'Nastavení popisků aplikace',
    autocomplete: 'Popisky automatického dokončování',
    popularQueries: 'Populární dotazy',
    popularProducts: 'Populární produkty',
    serp: 'Popisky výsledků vyhledávání',
    filters: 'Popisky filtrů',
    pagination: 'Stránkovací štítky',
    fields: {
      autocomplete: {
        whatSearch: 'Co hledáte?',
        typeTerm: 'Zadejte hledaný výraz',
        seeMore: 'Zobrazit více',
      },
      popularQueries: {
        header: 'Populární fráze',
      },
      popularProducts: {
        header: 'Populární produkty',
      },
      serp: {
        resultHeader: 'Výsledky vyhledávání pro',
        results: 'výsledků',
        page: 'Stránka',
        emptyResults: 'Žádné výsledky',
        typeQuery: 'Zadejte dotaz do vyhledávače nahoře na stránce',
        showedAll: 'Zobrazeny všechny výsledky',
        omnibusLowestPrice: 'Nejnižší cena ze posledních 30 dnů',
      },
      filters: {
        header: 'Filtry',
        clear: 'Vymazat filtry',
        show: 'Zobrazit filtry',
        from: 'z',
        to: 'dolů',
      },
      pagination: {
        next: 'Další',
        prev: 'Předchozí',
      },
    },
    save: 'Uložit',
    saveSuccess: 'Data byla uložena správně',
  },
  validation: {
    fields: {
      instanceName: 'název instance',
      selectedCatalog: 'vybraný adresář',
      catalogName: 'název adresáře',
      selectedIndustry: 'adresářový průmysl',
      selectedLanguage: 'jazyk adresáře',
      catalogUrl: 'odkaz na adresář',
      newDomain: 'Doména',
      sortLabel: 'Seřadit štítek',
      sortName: 'Seřadit název',
      facetedMenuName: 'Název menu',
      '{field}': 'pole',
    },
    rules: {
      alpha_dash: 'Pole {_field_} může obsahovat pouze písmena, číslice, pomlčky nebo podtržítka',
      alpha_num_dash_spaces: 'Pole {_field_} může obsahovat pouze písmena, čísla, mezery, pomlčky nebo podtržítka',
      min: 'Minimální počet znaků pole {_field_} je {length}',
      max: 'Maximální počet znaků pole {_field_} je {length}',
      oneOf: '{_field_} musí být správná hodnota',
      required: 'Pole {_field_} je povinné',
      domain: 'Pole {_field_} není platná doména',
      alpha_unicode_num_dash_spaces: 'Pole {_field_} může obsahovat pouze písmena, čísla, mezery, pomlčky nebo podtržítka',
      alpha_num: 'Pole {_field_} může obsahovat pouze písmena, čísla',
      regex: 'Niepoprawne wyrażenie',
    },
  },
}
