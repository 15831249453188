import apiRequest from '@/apiRequest'
import Notifications from '@/store/notifications/notifications'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface Currency {
  label: string;
}

// Currencies
interface CurrenciesState {
  currencies: Array<Currency>
}

interface CurrencyResponse {
  id: number;
  code: string;
}

interface CurrenciesResponse {
  data: Array<CurrencyResponse>
  total: number;
}

export default new Vuex.Store({
  state: (): CurrenciesState => (
    {
      currencies: [],
    }
  ),

  mutations: {
    setCurrencies(state: CurrenciesState, currencies: Array<Currency>) {
      state.currencies = currencies
    },
  },

  actions: {
    loadCurrencies(context: any) {
      const response = apiRequest.get<CurrenciesResponse>('catalog/currencies')

      return response
        .then(result => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'catalog.currency.errorDuringLoading', { root: true })
            return
          }

          const currencies: any = {}

          result.data.data.forEach(currency => {
            currencies[currency.id] = currency.code
          })

          context.commit('setCurrencies', currencies)
        })
        .catch(error => {
          Notifications.commit('addError', error.message, { root: true })
        })
    },
  },

  getters: {
    getCurrencies(state: CurrenciesState): Array<Currency> {
      return state.currencies
    },
  },

})
