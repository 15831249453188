import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'
import moduleSearchCatalogMapping from '@/store/searchCatalogMapping/moduleSearchCatalogMapping'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'
import moduleCatalog from '@/store/catalog/moduleCatalog'

export default {
  namespaced: true,

  state: {
    // wizard status
    isLoading: false,

    // wizard tab index
    currentTabIndex: 1,
    hideButtons: false,
  },

  // mutations
  mutations: {
    startLoading(state: any) {
      state.isLoading = true
      state.hideButtons = true
    },
    endLoading(state: any) {
      state.isLoading = false
      state.hideButtons = false
    },
    hideButtons(state: any) {
      state.hideButtons = true
    },
    showButtons(state: any) {
      state.hideButtons = false
    },
  },

  actions: {
    loadFirstStep(context: any) {
      context.commit('startLoading')
      moduleCatalog.dispatch('initCatalogSettings', {}, { root: true })
        .finally(() => {
          context.commit('endLoading')
        })
    },
    openSetMapping(context: any): Promise<boolean> {
      context.commit('startLoading')
      Notifications.commit('clearErrors', {}, { root: true })
      return moduleCatalog.dispatch('saveCatalogSettings', {}, { root: true })
        .then((_response: AxiosResponse) => {
          const catalogLinkUri = moduleCatalog.getters.getCatalogLink
          return moduleSearchCatalogMapping.dispatch('findXmlPaths', catalogLinkUri, { root: true })
            .then(() => {
              moduleSearchCatalogMapping.dispatch('getPreviewData', catalogLinkUri, { root: true })
              context.commit('endLoading')
              return true
            })
            .catch(() => {
              Notifications.commit('addError', 'catalogMapping.error.storeRequestUnknown', { root: true })
              context.commit('endLoading')
              return false
            })
        })
        .catch(() => {
          Notifications.commit('addError', 'catalog.error.duringCatalogSave', { root: true })
          context.commit('endLoading')
          return false
        })
    },
    open3rdStep(context: any): Promise<boolean> {
      context.commit('startLoading')
      Notifications.commit('clearErrors', {}, { root: true })
      moduleImportedCatalog.dispatch('resetProductList', {}, { root: true })
      return moduleSearchCatalogMapping.dispatch('saveMappingSettings', {}, { root: true })
        .then(() => {
          context.commit('endLoading')
          context.commit('hideButtons')

          // zapisane mappingi, rozpoczęte przetwarzanie feed'a - sprawdzaj w kółko aż zostanie zakończone ??
          const interval = setInterval(() => {
            moduleImportedCatalog.dispatch('isCatalogImportFinished', {}, { root: true })
              .then((result: AxiosResponse) => {
                if (result.status === 200) {
                  clearInterval(interval)
                  const productInterval = setInterval(() => {
                    moduleImportedCatalog.dispatch('getProductsList', null, { root: true })
                      .then((importProductResult: AxiosResponse) => {
                        if (importProductResult.status === 200) {
                          context.commit('showButtons')
                          clearInterval(productInterval)
                        }
                      })
                  }, 2000)
                }
                return true
              })
              // .catch((error: any) => {
              //   console.dir(error)
              //   context.commit('showButtons')
              //   clearInterval(interval)
              // })
          }, 2000)
          return true
        })
        .catch((err: any) => {
          if (err.name === 'FIELDS_INVALID_ERROR') {
            Notifications.commit('addError', 'catalogMapping.error.fieldsInvalid', { root: true })
          } else if (err.isAxiosError) {
            Notifications.commit('addError', 'catalogMapping.error.storeRequestFailed', { root: true })
          } else {
            Notifications.commit('addError', 'catalogMapping.error.storeRequestUnknown', { root: true })
          }
          context.commit('endLoading')
          return false
        })
    },
    open4thStep(context: any): any {
      context.commit('startLoading')

      context.commit('endLoading')
    },
  },
}
