import i18n from '@/libs/i18n'
import CatalogApiRequest from '@/store/catalog/catalogApiRequest'
import Notifications from '@/store/notifications/notifications'

export default {
  namespaced: true,
  state: {
    sharedFields: {
    },
    availableCatalogs: [],
    selectedCatalog: 0,
    availableFields: [],
  },
  mutations: {
    setSharedFields(state:any, data:any) {
      state.sharedFields = data
    },
    setAvailableFields(state:any, data:any) {
      state.availableFields = data
    },
    setAvailableCatalogs(state:any, data:any) {
      state.availableCatalogs = data
    },
    setSelectedCatalog(state:any, data:any) {
      state.selectedCatalog = data
    },
  },
  actions: {
    initializeCatalogList(context: any) {
      CatalogApiRequest.getCatalogsList().then(catalogListResponse => {
        context.commit('setAvailableCatalogs', catalogListResponse.data)
        if (catalogListResponse.data.length) {
          const catalogId = catalogListResponse.data[0].id
          context.dispatch('getCatalogSharedFields', catalogId)
        }
      })
    },
    getCatalogSharedFields(context: any, catalogId: string) {
      context.commit('setSelectedCatalog', catalogId)
      CatalogApiRequest.getCatalogFields(catalogId).then(catalogFieldsResponse => {
        context.commit('setAvailableFields', catalogFieldsResponse.data.data)
        CatalogApiRequest.getSharedFields(catalogId)
          .then(sharedFieldsResult => {
            context.commit('setSharedFields', sharedFieldsResult.data)
          })
          .catch(error => {
            Notifications.commit('addError', error, { root: true })
          })
      })
    },
    toggle(context: any, parm: any) {
      console.log(parm)
      let { sharedFields } = context.state
      let find = false
      sharedFields.forEach((sharedField: any) => {
        if (parm.name === sharedField.field) {
          find = true
        }
      })
      if (find) {
        sharedFields = context.state.sharedFields.filter((item: any) => item.field !== parm.name)
      } else {
        sharedFields.push({
          field: parm.name,
        })
      }
      context.dispatch('store', { sharedFields, catalogId: parm.catalogId })
    },
    async store(context:any, data:any) {
      CatalogApiRequest.setSharedFields({
        catalogId: data.catalogId,
        sharedFields: data.sharedFields,
      })
        .then(() => {
          Notifications.commit('addPopupInfo', {
            name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
            message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
          }, { root: true })
          context.dispatch('getCatalogSharedFields', data.catalogId)
        })
        .catch(error => {
          Notifications.commit('addPopupError', error, { root: true })
        })
    },
  },

}
