const LanguageMap: any = {
  pl: 'Polski',
  cz: 'Czech',
  en: 'English',
}

interface LanguageState {
    locale: string
    language: string
}

const QonLocaleKey = 'qonLocale'
const QonLanguageKey = 'qonLang'

export default {
  namespaced: true,
  state: {
    locale: localStorage.getItem(QonLocaleKey) || 'pl',
    language: localStorage.getItem(QonLanguageKey) || 'Polski',
  },
  getters: {
    getLocale(state: LanguageState): string {
      return state.locale
    },
    getLanguages(state: LanguageState): string {
      return state.language
    },
  },
  mutations: {
    setLocale(state: LanguageState, locale: string) {
      state.locale = locale
      state.language = LanguageMap[locale]
      localStorage.setItem(QonLocaleKey, locale)
      localStorage.setItem(QonLanguageKey, state.language)
    },
  },
}
