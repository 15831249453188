// @ts-ignore
import EventEmitter from 'events'
import store from '@/store/'

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = 'loggedIn'
const tokenExpiryKey = 'tokenExpiry'
const tokenRefreshKey = 'tokenRefresh'
const accessTokenKey = 'accessToken'
const loginEvent = 'loginEvent'
const userInfoKey = 'userInfo'

export default class AuthService extends EventEmitter {
  /**
   * Login subject to app by hash provided by old control panel
   */
  loginByHash(hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      store.dispatch('auth/getTokenDataByHash', hash)
        .catch(error => {
          reject(error)
        })
        .then(tokenDataResponse => {
          this.setSubjectLoggedIn(tokenDataResponse)

          store.dispatch('auth/getUserProfile')
            .catch(error => reject(error))
            .then(userProfileResponse => {
              const userProfile = userProfileResponse.data
              const userData = {
                uid: userProfile.upsellerCustomer_Id,
                displayName: userProfile.upsellerCustomer_Name,
                about: '',
                status: 'online',
                userRole: 'user',
                domain: userProfile.upsellerCustomer_Domain,
                customerSymbol: userProfile.upsellerCustomer_QuarticAccount,
                platformId: userProfile.upsellerCustomer_PlatformId,
                features: userProfile.features,
                modules: userProfile.modules,
              }

              store.commit('user/UPDATE_USER_INFO', userData, { root: true })
              // @ts-ignore
              this.emit(loginEvent, {
                loggedIn: true,
                profile: userData,
              })

              resolve(tokenDataResponse)
            })
        })
    })
  }

  /**
   * Return local access token
   */
  getLocalAccessToken(): string {
    const item = localStorage.getItem(accessTokenKey)
    return item ?? ''
  }

  /**
   * Set subject as logged in
   * @private
   */
  setSubjectLoggedIn(tokenDataResponse: any) {
    localStorage.setItem(localStorageKey, 'true')
    localStorage.setItem(accessTokenKey, tokenDataResponse.access_token)
    const timestamp = Math.round((new Date()).getTime() / 1000)
    localStorage.setItem(tokenExpiryKey, ((timestamp + tokenDataResponse.expires_in) * 1000).toString())
    localStorage.setItem(tokenRefreshKey, ((timestamp + tokenDataResponse.expires_in - 60) * 1000).toString())
  }

  /**
   * Is subject authenticated?
   */
  isAuthenticated(): boolean {
    return new Date(Date.now()) < new Date(parseInt(localStorage.getItem(tokenExpiryKey) ?? '')) && localStorage.getItem(localStorageKey) === 'true'
  }

  /**
   * Is refresh Needed
   */
  isRefreshNeeded(): boolean {
    return new Date(Date.now()) > new Date(parseInt(localStorage.getItem(tokenRefreshKey) ?? ''))
  }

  /**
   * Refresh Token
   */
  refreshToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      store.dispatch('auth/refreshToken')
        .catch(error => reject(error))
        .then(tokenDataResponse => {
          this.setSubjectLoggedIn(tokenDataResponse)
          resolve(tokenDataResponse)
        })
    })
  }

  /**
   * Logout subject from app
   */
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      store.dispatch('auth/logout')
        .catch(error => reject(this.clearSessionData(error)))
        .then(result => resolve(this.clearSessionData(result)))
    })
  }

  clearSessionData(result: any): any {
    localStorage.removeItem(tokenRefreshKey)
    localStorage.removeItem(tokenExpiryKey)
    localStorage.removeItem(localStorageKey)
    localStorage.removeItem(accessTokenKey)
    localStorage.removeItem(userInfoKey)
    return result
  }
}
