import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface RequiredField {
  description: string,
  qonId: string,
  catalogField: string,
  dataType: string,
  separator?: string,
}

interface RequiredFieldsState {
  requiredFields: RequiredField[]
}

const requiredFields = new Vuex.Store({
  state: (): RequiredFieldsState => (
    {
      requiredFields: [
        {
          description: 'catalogMapping.fields.productId',
          qonId: 'id',
          catalogField: 'g:id',
          dataType: 'id',
        },
        {
          description: 'catalogMapping.fields.title',
          qonId: 'title',
          catalogField: 'g:title',
          dataType: 'string',
        },
        {
          description: 'catalogMapping.fields.categories',
          qonId: 'categories',
          catalogField: 'g:google_product_category',
          dataType: 'table',
          separator: ',',
        },
        {
          description: 'catalogMapping.fields.productLink',
          qonId: 'link',
          catalogField: 'g:link',
          dataType: 'string',
        },
        {
          description: 'catalogMapping.fields.imageLink',
          qonId: 'image_link',
          catalogField: 'g:image_link',
          dataType: 'string',
        },
        {
          description: 'catalogMapping.fields.availability',
          qonId: 'availability',
          catalogField: 'g:availability',
          dataType: 'string',
        },
        {
          description: 'catalogMapping.fields.price',
          qonId: 'price',
          catalogField: 'g:price',
          dataType: 'number',
        },
      ],

    }
  ),
  mutations: {
    setRequiredFields(state, field: RequiredField) {
      const foundIndex = state.requiredFields.findIndex(element => element.qonId === field.qonId)
      state.requiredFields[foundIndex].catalogField = field.catalogField
      state.requiredFields[foundIndex].separator = field.separator
    },
  },
})

export default requiredFields
