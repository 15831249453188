export default {
  menu: {
    smartSearch: 'Smart Search',
    smartSearchV3: 'Search',
    smartSearchDashboard: 'Dashboard',
    searchCatalog: 'Catalog',
    searchReports: 'Reports',
    searchPhrasesReport: 'Phrases',
    searchPhrasesReportWithNoResults: 'Empty searches',
    searchProductsReport: 'Products report',
    searchSynonyms: 'Synonyms',
    searchSettings: 'Settings',
    searchImprovements: 'Improvments',
    searchInstanceSettings: 'Instances settings',
    searchInstancesList: 'Instances list',
    searchTester: 'Search Test',
    searchSubscription: 'Subscription',
    searchBlockedPhrase: 'Blocked phrases',
    searchImmutablePhrase: 'Unchangeable phrases',
    mailing: 'Mailing',
    htmlTemplate: 'Html template',
    searchWeights: 'Relevance of fields',
    searchSort: 'Sort',
    searchFacetedMenu: 'Facated Menu',
    searchAuthorizationSettings: 'API access',
    searchInstances: 'Search instances',
    searchSuggestion: 'Suggestions',
    searchSharedFields: 'Shared fields',
    searchTry: 'Search Test',
    appV3Settings: 'Settings',
    appV3Labels: 'Labels',
    searchV3: {
      reports: {
        main: 'Reports',
        emptySearchPhrasesReport: 'Empty searches',
        allPhrasesReport: 'Phrases',
        allReports: 'Dashboard',
      },
    },
    smartStylist: {
      main: 'Stylist',
      reports: 'Reports',
      stylizationsList: 'Stylizations list',
    },
    catalogInstances: 'Catalogs list',
    searchApp: 'Search App',
  },
  suggestion: {
    header: 'Suggestions settings',
    subHeader: 'Fields from which suggestions are generated',
    shortDescription: 'Indicate the fields from which the system will generate suggestions',
    description: 'Functionality that allows you to specify the fields from which suggestions for the entered phrase will be generated. Thanks to it, you can easily indicate the fields that are responsible for displaying suggestions matched to the entered phrase. Thanks to this, it will be easier for users to find what they are looking for, which will positively affect their satisfaction with using the search engine.',
    warningHeader: 'Warning',
    warningDescription: 'The changes will be visible after reprocessing the directory.',
    tableHeaders: {
      field: 'Catalog field',
      dataFromAggregate: 'Use data from filters for suggestions?',
      status: 'On/Off',
      actions: 'Change',
    },
    changeTooltip: 'Change',
    descriptionButton: {
      hide: 'Hide',
      show: 'Show',
      description: 'description',
    },
  },
  sharedFields: {
    header: 'Shared fields settings',
    description: 'Here you can decide which product fields will be available via the API.',
    tableHeaders: {
      field: 'Catalog field',
      status: 'On/Off',
      actions: 'Change',
    },
    selectCatalog: 'Select catalog: ',
    changeTooltip: 'Change',
  },
  searchSelect: {
    description: 'Vyberte vyhledávání',
  },
  alert: {
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
  },
  notifications: {
    copiedKey: {
      name: 'API key',
      message: 'Copied',
    },
    copiedCustomerSymbol: {
      name: 'Customer Symbol',
      message: 'Copied',
    },
    copiedUrl: {
      name: 'API URL',
      message: 'Copied',
    },
    apiKeyReset: {
      name: 'API key',
      message: 'Reset',
    },
  },
  search: {
    dataSources: {
      allData: 'all data',
      autocomplete: 'autocomplete',
      serp: 'search results',
      bestseller: 'best selling',
      popular: 'most popular',
    },
    header: {
      dataSource: 'Source',
      dateRange: 'Date range',
    },
    headers: {
      populars: 'Most popular phrases',
      typeDistribution: 'Source',
      zeroSearch: 'Phrases with no search results',
      mostClicked: 'Most clicked phrases',
      bestConversion: 'Phrases with best sale count',
      bestConversionAmount: 'Phrases with best sale sum',
      searchCount: 'Search count',
      searchInstances: 'Search instances',
    },
    statistics: {
      conversions: 'Conversion',
      phrase: 'Phrase',
      count: 'Searches count',
      clicks_count: 'Clicks count',
      saleSum: 'Sale sum',
      saleAmount: 'Sale amount',
    },
    searchGraphBar: {
      headers: {
        searchCount: 'Search count',
        searchClickCount: 'Clicks count',
        searchClickThroughRate: 'CTR',
        salesAmount: 'Orders count',
        salesTotal: 'Sales total',
        salesConversion: 'Conversion',
      },
      tooltip: {
        searchCount: 'Search count',
        searchClickCount: 'Clicks count',
        searchClickThroughRate: 'CTR',
        salesAmount: 'Orders count',
        salesTotal: 'Sales total',
        salesConversion: 'Conversion',
      },
    },
    searchPhrases: {
      header: 'Searched phrases report',
      download: 'Download report',
      phrase: 'Phrase',
      searchCount: 'Count',
      clicks: 'Clicks',
      ctr: 'CTR',
      conversion: 'Conversion',
      salesCount: 'Sales count',
      salesUnitsSold: 'Units sold',
      salesTotal: 'Total',
    },
    searchPhrasesWithNoResults: {
      header: 'List of searched phrases that did not return any results',
      download: 'Download report',
      phrase: 'Phrase',
      searchCount: 'Count',
    },
    searchProducts: {
      header: 'Searched products report',
      download: 'Download report',
      productId: 'Product ID',
      productName: 'Product name',
      views: 'Views',
      clicks: 'Clicks',
      conversion: 'Conversion',
      salesCount: 'Sales count',
      salesUnitsSold: 'Units sold',
      salesTotal: 'Total',
    },
    synonyms: {
      header: 'Synonyms',
      addNew: 'Add synonym',

      createPopupHeader: 'Add synonym',
      createPopupSynonymsLabel: 'If a user searches for',
      addSynonymInputTooltip: 'Add next synonym',
      createPopupBaseWordLabel: 'search for a phrase',
      or: 'or',

      insertSuccessfulNotificationTitle: 'Success!',
      insertSuccessfulNotification: 'Data has been successfully saved',

      synonymColumnHeader: 'If a user searches for',
      baseWordColumnHeader: 'Search for',
      optionsHeader: 'Options',
      editTooltip: 'Edit',
      deleteTooltip: 'Delete',

      deleteConfirmationHeader: 'Deleting synonym',
      deleteConfirmationText: 'You are about to delete a synonym. Are you sure?',
      deleteConfirmationAcceptText: 'Yes',
      deleteConfirmationCancelText: 'Cancel',

      editPopupHeader: 'Editing synonym',
      editPopupSynonymLabel: 'If a user searches for',
      editPopupBaseWordLabel: 'search for a phrase',
      editPopupSave: 'Save',
      editPopupCancel: 'Cancel',

      saveSuccessfulNotificationTitle: 'Success!',
      saveSuccessfulNotification: 'Data has been successfully saved',

      popupSynonymInvalid: 'Invalid synonym',
      popupBaseWordInvalid: 'Invalid phrase',
    },
    blockedPhrase: {
      header: 'Manage blocked phrases',
      addNew: 'Add a blocked phrase',

      createPopupHeader: 'Add a blocked phrase',
      createPopupPhraseLabel: 'If a user enters a blocked phrase in the search box, it will be omitted in the search results',

      addInputTooltip: 'Add another blocked phrase',
      or: 'or',

      insertSuccessfulNotificationTitle: 'Success!',
      insertSuccessfulNotification: 'The data has been saved correctly',

      phraseColumnHeader: 'Blocked phrase',
      optionsHeader: 'Options',
      editTooltip: 'Edit',
      deleteTooltip: 'Delete',

      deleteConfirmationHeader: 'Removing a blocked phrase',
      deleteConfirmationText: 'Are you sure you want to remove the blocked phrase?',
      deleteConfirmationAcceptText: 'Yes',
      deleteConfirmationCancelText: 'Cancel',

      editPopupHeader: 'Editing the blocked phrase',
      editPopupBlockedPhraseLabel: 'The phrase to block',
      editPopupSave: 'Save',
      editPopupCancel: 'Cancel',

      saveSuccessfulNotificationTitle: 'Success!',
      saveSuccessfulNotification: 'The data has been saved correctly',

      popupInvalid: 'Invalid blocked phrase',
    },
    immutablePhrase: {
      header: 'Manage unchangeable phrases',
      addNew: 'Add an unchangeable phrase',

      createPopupHeader: 'Add an unchangeable phrase',
      createPopupLabel: 'If a user enters an unchangeable phrase in the search box, the system won\'t change its wording while searching (recommended for proper names, brands, foreign words)',

      addInputTooltip: 'Add another unchangeable phrase',
      or: 'or',

      insertSuccessfulNotificationTitle: 'Success!',
      insertSuccessfulNotification: 'The data has been saved correctly',

      phraseColumnHeader: 'The unchangeable phrase',
      optionsHeader: 'Options',
      editTooltip: 'Edit',
      deleteTooltip: 'Delete',

      deleteConfirmationHeader: 'Removing an unchangeable phrase',
      deleteConfirmationText: 'Are you sure you want to delete the unchangeable phrase?',
      deleteConfirmationAcceptText: 'Yes',
      deleteConfirmationCancelText: 'Cancel',

      editPopupHeader: 'Editing the unchangeable phrase',
      editPopupLabel: 'The unchangeable phrase',
      editPopupSave: 'Save',
      editPopupCancel: 'Cancel',

      saveSuccessfulNotificationTitle: 'Success!',
      saveSuccessfulNotification: 'The data has been saved correctly',

      popupInvalid: 'Invalid unchangeable phrase',
    },
    settings: {
      header: 'Settings',
      generalTabTitle: 'General',
      gaTabTitle: 'Google Analytics',
      enabled_on: 'On',
      enabled_off: 'Off',
      showUnavailable: 'Show unavailable products',
      showOnlyWithImages: 'Show only products with image',
      showVariantsOfProduct: 'Show grouped product with variants',
      showAllVariantsOfProducts: 'Show all matching product variants',
      itemsPerPage: 'Items per page',
      analyticsEnabled: 'Reporting to Google Analytics enabled',
      analyticsDisabled: 'Reporting to Google Analytics disabled',
      analyticsMeasurementId: 'Tracking ID',
      analyticsMeasurementIdTooltip: 'Eg.: UA-000000-1',
      analyticsUseSiteSearchHeader: 'Type of reporting',
      analyticsUseSiteSearchTooltip: 'Choose "event" to send GA custom events. Choose "pageview" to send pageviews in order to use GA Site Search.',
      analyticsUseCustomEvents: 'event',
      analyticsUseSiteSearch: 'pageview',

      save: 'Save settings',
      unsaved: 'You have unsaved changes.',
      errorGet: 'There was an error with retrieving settings. Please try again later.',
      success: 'Your settings was successfully saved. Changes in your website should be visible in about 15 minutes.',
      errorSet: 'There was an error with saving your settings. Please try again late.',

      recentQueries: {
        title: 'Recent queries',
        on: 'Enabled',
        off: 'Disabled',
        amount: 'Number of queries displayed',
      },
      popularQueries: {
        title: 'Most popular queries',
        on: 'Enabled',
        off: 'Disabled',
        durationInfo: 'Show queries from last:',
        duration: {
          1: '1 day',
          3: '3 days',
          7: '7 days',
          30: '30 days',
          60: '60 days',
          90: '90 days',
        },
        amount: 'Number of queries displayed',
      },
      functionNotAvailable: 'This feature is not available to you',
      functionNotAvailableContact: 'If you want to run it, please write us at',
      functionNotAvailableContactOr: 'or',
      functionNotAvailableContactScheduleMeeting: 'make an appointment with our advisor',
    },
    tester: {
      inputPlaceholder: 'Enter query to test search',
      noResultsHeader: 'No results',
      noResults: 'We have not found any matching results for this phrase. This may be due to a lack of products or incorrect configuration.',
    },
    instanceList: {
      mainHeader: 'Search engine list',
      mainDescription: 'Search engines allow you to define the basic parameters of searching the directory. With the search engine list, you can set different search behaviors for a common directory.',
      addNew: 'Create a new search engine',
      header: {
        id: 'Search engine ID',
        name: 'Search engine name',
        catalog: 'Linked directory',
      },
    },
    instance: {
      header: 'List of search engine instances',
      addNew: 'Create a new instance',
      tableHeader: {
        instanceName: 'Instance name',
        instanceId: 'Search instance Id',
        catalogName: 'Catalog name',
        catalogId: 'Catalog Id',
        options: 'Options',
      },
      add: {
        header: 'Creating a new instance of the search engine',
        description: 'Welcome to the new search engine instance creation wizard. The system allows the creation of many differently configured search engines. Their number depends on your plan.',
        label: {
          instanceName: 'Enter the name of the search engine instance',
          selectCatalog: 'Choose a product feed from the list',
        },
        submit: 'Save',
        error: {
          message: 'Wystąpił błąd podczas zapisu instancji wyszukiwarki',
        },
      },
      details: {
        main: {
          header: 'Search instance id: ',
          content: 'Product search engine optimization is key to making it easier for customers to find exactly what they are looking for. Thanks to this area, you can customize and improve the operation of the search engine in your store. Check and adjust the main configuration of your search engine instance. Check whether the catalog mapping configuration to our data structures contains all the attributes necessary to search in your products',
        },
        settingsHeader: 'Basic search engine settings',
        settingsDescription: 'Here you will find the basic settings of your search engine',
        fields: {
          productsPerPage: 'Number of products per page',
          popularQueriesLimit: 'Limit of returned popular phrases',
          popularQueriesInterval: 'Time range of returned phrases',
        },
        save: 'Save',
      },
    },
  },
  profile: {
    logout: 'Logout',
    goToStage: 'Go to Stage Mode',
  },
  subscription: {
    card_title_new_account: 'New Subscription',
    first_name: 'First name',
    last_name: 'Last name',
    address1: 'Address',
    city: 'City',
    country: 'Country',
    postal_code: 'Postal code',
    company_name: 'Company name',
    vat_number: 'VAT Number',
    save_card: 'Save credit card',
    subscription_active: 'Your subscription is active',
    card_title_new_subscription: 'Subscribe',
    need_subscription: 'Your subscription is inactive',
    activate: 'Activate',
    ends_at: 'New billing cycle begin at',
    offer: {
      search_shoptet: {
        title: 'Smart Search',
        subtitle: 'Best search on the market!',
        features: [
          'Autocomplete',
          'Synonyms',
          'Recognition of errors',
          'Language variety',
          'Filtering results',
          'Supplying the search engine with data from the product catalog',
          'Universal template of search results',
          'Integration with Google Analytics',
        ],
      },
      netto: 'All prices listed in our offer are net prices, excluding taxes.',
    },
    validation: {
      number: 'Card number is invalid.',
      month: 'Month is invalid.',
      year: 'Year is invalid.',
      first_name: 'First name is invalid.',
      last_name: 'Last name is invalid.',
      address1: 'Address is invalid.',
      city: 'City is invalid.',
      country: 'Country is invalid.',
      postal_code: 'Postal code is invalid.',
      company_name: 'Company name is invalid.',
      vat_number: 'VAT number is invalid.',
    },
  },
  catalog: {
    header: 'Addinng products catalog',
    description: 'Configure provider of your shop catalog with specification (check specification',
    here: 'here )',
    provideName: 'Provide catalog name',
    provideLink: 'Provide URL of catalog (in XML format, required Google Merchants format, max file size 512 MB)',
    invalidLinkProvided: 'Invalid URL entered',
    industry: {
      choose: 'Choose industry of your shop',
      chooseFromList: 'Choose industry',
      errorDuringLoading: 'There was an error during data processing...',
      invalidSelection: 'Incorrect industry selection',
    },
    language: {
      choose: 'Choose language from the list',
      chooseFromList: 'Choose language',
      errorDuringLoading: 'There was an error during data processing...',
      invalidSelection: 'Incorrect language selection',
    },
    currency: {
      choose: 'Choose currency from the list',
      chooseFromList: 'Choose currency',
      errorDuringLoading: 'There was an error during data processing...',
      invalidSelection: 'Incorrect currency selection',
    },
    error: {
      duringCatalogSave: 'There was an error during saving catalog settings',
    },
    refreshSettings: {
      header: 'Catalog refresh settings',
      description: 'Set how often the directory should be refreshed',
      maxCount: 'You can add up to %maxDayCount% items',
      timeDescription: 'The catalog will be processed daily at the indicated times',
      addNewPeriod: 'Add another hour',
      success: 'Success',
      periodsSaved: 'Directory refresh data saved correctly',
      save: 'Save',
      errors: {
        min_interval_time: 'The minimum time interval is 1 hour',
        distinct_time: 'Update times cannot be the same',
      },
    },
    xmlPreview: {
      showXmlPreview: 'Show xml preview',
      next: 'Next',
      prev: 'Previous',
    },
    productPreview: {
      requiredFields: 'Required fields',
      productName: 'Product name',
      productId: 'Product Id',
      productCategory: 'Category',
      productLink: 'Product link',
      productImage: 'Product image',
      productAvailability: 'Availability',
      productPrice: 'Price',
      additionalFields: 'Additional fields',
      missingAdditionalFields: 'Missing additional fields',
      productPreview: 'Product preview',
    },
    instance: {
      addNew: 'Configure new catalog',
      mainHeader: 'Catalogs instances',
      mainDescription: 'The product catalog is a very important element of the qon system. The system allows you to configure the directory yourself. The catalog wizard will help you configure the new product catalogue, which will guide you through the configuration step by step.',
      showDetails: 'Show details',
      delete: 'Delete',
      workingDirectory: 'working directory',
      history: 'Import history',
      editMapping: 'Edit mapping',
      editTimeRefresh: 'Edit update time',
      categoryMappings: 'Category mappings',
      deletetips: {
        notproduction: 'The working directory could not be deleted',
        assignedSearch: 'The directory with assigned search engines could not be deleted',
      },
      header: {
        id: 'Catalog Id',
        name: 'Name',
        url: 'Url path',
        type: 'Type',
        language: 'Language',
        industry: 'Industry',
      },
      mapping: {
        header: 'List of mapped fields',
        description: 'A list of mapped fields from the provided catalog feed to a feed usable in the QON system. The elements found are transformed into:',
        foundElement: 'Item found',
        changedTo: '(Type) Changed to',
        notSet: 'Mapping not assigned',
      },
      import: {
        header: 'Refresh time list',
        description: 'To ensure the most up-to-date data on products, the catalogs are processed cyclically. The catalog is processed every day in hours:',
      },
      details: {
        header: 'Catalog id: {catalogId} details',
        description: 'This area allows you to check the configuration and analyze your product catalog. Adjust and verify how the attributes of your products are mapped to our data structures, which is crucial for the precision and consistency of the information presented. Adjust the processing time of the catalog so that the offer is as up-to-date as possible. On the product list, you can review how products have been imported into the system.',
      },
      categoriesTree: {
        header: 'Category mapping for catalog with id: {catalogId}',
        refreshButton: 'Refresh the category tree',
        ownMappings: 'Own',
        parentMappings: 'Parent',
        notSetMappings: 'Not set',
        refreshInfo: 'After refresh, {added} from {total} was added',
      },
    },
    timeSelect: {
      labelHours: 'Hour',
      labelMinutes: 'Minute',
      labelNowButton: 'Select now',
      labelCloseButton: 'Close',
    },
    productList: {
      title: 'Product list',
      product: {
        id: 'Id:',
        price: 'Price:',
        availability: 'Availability:',
        categories: 'Categories:',
        link: 'Product page:',
        image: 'Image url:',
        imageClickDescription: 'Click on the image to go to the product page indicated in the catalogue',
        variantsCount: 'no variants | 1 variant | {n} variants ',
      },
      showProductsDetails: 'Show details',
      hideProductsDetails: 'Hide details',
      showProduct: 'Show product',
    },
  },
  catalogHistory: {
    header: 'Catalog import history',
    shortDescription: 'Below you will find the catalog import history',
    lastImport: 'Last catalog import status',
    tableHeaders: {
      date: 'Date',
      owner: 'Calling',
      status: 'Status',
      correctProductsCount: 'Correct Products',
      incorrectProductsCount: 'Incorrect Products',
    },
    status: {
      unknown: 'Unknown',
      ok: 'OK',
      error: 'Error',
      in_progress: 'Processing',
    },
    laststatus: {
      unknown: 'An error occured',
      ok: 'Correct',
      error: 'Incorrect',
      in_progress: 'Processing',
    },
    yoursCatalogLastImportDesc: 'Your catalog was last imported:',
    yoursCatalogLastImportDescProducts: 'Total products found in the catalog:',
    correctProducts: 'correct products',
    incorrectProducts: 'incorrect products',
    errorResponse: 'An error occurred, please try again later',
  },
  industry: {
    animals: 'animals',
    automotive: 'automotive',
    beauty: 'beauty',
    books_and_multimedia: 'books & multimedia',
    education: 'education',
    electronic: 'electronic',
    entertainment: 'entertainment',
    erotic: 'erotic',
    fashion: 'fashion',
    food: 'food',
    health: 'health',
    hobby: 'hobby',
    home_and_garden: 'home & garden',
    gifts_toys: 'gifts & toys',
    jewellery_gallantry: 'jewellery & gallantry',
    office: 'office',
    other_goods: 'other (goods)',
    other_services: 'other (services)',
    sport: 'sport',
    travel_and_leisure: 'travel & leisure',
    other: 'other',
  },
  language: {
    pol: 'Polish',
    cze: 'Czech',
    slo: 'Slovakian',
    eng: 'English',
    ukr: 'Ukrainian',
    rum: 'Romanian',
    bul: 'Bulgarian',
    ger: 'German',
    hrv: 'Croatian',
    hun: 'Hungarian',
    ita: 'Italian',
  },
  apiAuthorization: {
    header: 'API access configuration',
    domainWhitelist: {
      header: 'Configuration of access to the search engine API based on the whitelist of domains from which requests come. The whitelist allows you to integrate the search engine at the frontend level of the online store. The API will process any request that is initiated from any of the domains listed below. Requests from domains that are not on the list and not otherwise authorized will be rejected.',
      tabTitle: 'Whitelist access',
      desc: 'Type access domains',
      newDomainLabel: 'Domain url',
      add: 'Add',
      domain: 'Domain',
      action: 'Actions',
      deleteTooltip: 'Delete',
      save: 'Save',
    },
    key: {
      header: 'Configuring access to the API using a key. All key signed requests will be processed. Authorization with a key allows you to integrate the search engine API with the backend of the online store. All requests that are invoked with a valid key will be processed. If the key is incorrect or not provided, and the application to the API is not otherwise authorized, it will be rejected. Remember not to make the key public, it is unique and assigned to your account. If there is a suspicion that the key has been compromised, it is recommended to generate the key again. Note, generating a key will delete the current key.',
      tabTitle: 'Key authorization',
      url: 'Url API',
      key: 'Key',
      copyUrl: 'Copy',
      copyKey: 'Copy',
      resetKey: 'Reset key',
    },
  },
  catalogImport: {
    title: 'Information about the imported directory',
    selectedFeed: 'Indicated product feed:',
    analyzingCatalog: 'Analyzing catalog, it might take a while',
    correctlyImported: 'Imported products',
    incorrectlyImported: 'Incorrect imports',
    downloadingProducts: 'Downloading products',
    catalogProductsList: {
      show: 'Pokaż',
      correctImportedProductListTitle: 'Correct imported products',
      incorrectlyImportedProductListTitle: 'Products with an import warnings',
      downloadFile: 'Download file',
      variantsCount: 'Variants count',
      title: 'Title',
      id: 'Id',
      price: 'Price',
      categories: 'Categories',
      image: 'Image',
      actions: 'Actions',
    },
    warnings: {
      Model: {
        Exception: {
          ValidationException: 'Validation is not passed',
        },
      },
      Normalizers: {
        Exceptions: {
          IdentifierCannotBeEmptyException: 'Product Id can not be empty',
        },
      },
    },
  },
  sortSettings: {
    header: 'Sort settings',
    description: 'Here you can manage how you sort your search results. Each sorting method can contain multiple sorting conditions. Thanks to this, you can configure multi-criteria sorting, e.g. sort search results first by price descending, then by title descending.',
    list: {
      header: {
        number: 'Position',
        sortId: 'Sort Id',
        sortName: 'Sort Name',
        sortDescription: 'Sort Description',
      },
      conditions: {
        header: {
          number: 'No',
          type: 'Type',
          order: 'Sort order',
          details: 'Details',
        },
        notSet: 'No sort conditions configured!',
      },
    },
    addSortGroup: {
      header: 'Adding a sorting group',
      description: 'Welcome to the add sort group form. Because we are aware that advanced sorting of search results is often necessary, we enable you to configure multi-level sorting. You can set a list of sorting conditions that will be applied sequentially to the search results. A simple example is a sort that will sort the results first by price in descending order and then by title in ascending order.',
      label: {
        sortId: 'Sort Id',
        sortName: 'Sort name, label displayed in the sort selection list',
        sortDescription: 'Sorting description, describe what was the purpose of the configuration so that everyone knows how the sorting works',
        sortConditions: 'List of sort conditions',
        sortConditionTable: {
          number: 'No',
          type: 'Type',
          field: 'Field',
          order: 'Order',
          additionalInfo: 'Additional information',
          actions: 'Actions',
        },
      },
      add: {
        defaultCondition: 'default',
        fieldBasedCondition: 'based on a field',
        trackingBasedCondition: 'based on popularity',
        advancedCondition: 'advanced',
        sortType: {
          default: 'Default sort type',
          field: 'Field based sort',
          popular: 'Popular based sort',
          script: 'Advanced sort',
        },
      },
      order: {
        asc: 'ascending',
        desc: 'descending',
      },
      addFieldBasedPopup: {
        header: 'Adding a sorting condition based on an attribute from the directory',
        description: 'You are adding another sort condition to the group. Sorting based on catalog data',
        fieldSelect: 'Select a field from the directory',
        orderSelect: 'Select sorting direction',
        add: 'Save',
      },
      addAdvanced: {
        header: 'Adding an advanced sort condition',
        description: 'You are adding another sort condition to the group. Sorting based on the entered script',
        sortScript: 'Enter the script based on which the sorting will take place',
        orderSelect: 'Select sorting direction',
        add: 'Save',
      },
      addPopularityBasedPopup: {
        header: 'Adding a sort condition based on popularity',
        description: 'You are adding another sort condition to the group. Sorting based on the popularity of the product at a certain time',
        typeSelect: 'Select a popularity criterion',
        gradationSelect: 'From what time',
        orderSelect: 'Select sorting direction',
        add: 'Save',
        popularType: {
          views: 'Most viewed products',
          clicks: 'Most clicked products',
          purchases: 'Most purchased products',
        },
        gradations: {
          day1: 'last day data',
          day7: 'last week\'s data',
          day14: 'data from the last two weeks',
          day30: 'last month\'s data',
        },
      },
      save: 'Save',
    },
    sortSearch: 'Search',
    sortBestseller: 'Bestsellers',
    sortPopular: 'Popular',
    add: 'Add sort',
    save: 'Save settings',
    upTooltip: 'Move up',
    downTooltip: 'Move down',
    deleteTooltip: 'Delete',
    theadLabel: {
      sortName: 'Sort name',
      sortLabel: 'Sort label',
      sortType: 'Field type',
      additionalData: 'Additional data',
      actions: 'Actions',
      order: 'Order',
    },
  },
  weightsSettings: {
    header: 'Relevance of fields',
    headerAdd: 'Add',
    headerList: 'Weight',
    description: 'You can set individual product fields of your catalog and search criteria. These settings are used to find found products when searching in SearchApi and to provide the search phrase to this field.',
    selectSearch: 'Choose a search',
    selectOne: 'Please select at least once',
    allFieldsSelected: 'All fields selected',
    shortDescription: 'Below is a list of fields by which the system will search for results and with what strength these results will be boosted',
    strategyDescription: {
      full: 'Check this option if the field stores the product ID, an exact match of the search phrase will be used',
      match: 'is a search method that allows you to include multiple search criteria, such as content, title, description, and more. Thanks to this, users will have a better chance of finding the products they are looking for. Thanks to this, this method is ideal for online stores that offer a wide range of products, and customers can search for products using various criteria.',
      exact: 'is a search method that focuses on the exact match of the search phrase to the name of the product or its features. Thanks to this, users will be more confident that they will find exactly what they are looking for, which is especially important for online stores that offer products with special features or with unique names.\n',
    },
    tableHeaders: {
      field: 'Field',
      weight: 'Weight',
      actionAdd: 'Add',
      actionRemove: 'Remove',
      actionView: 'Actions',
      fullMatchField: 'Product ID',
      matchFields: 'MultiMatch',
      exactMatchFields: 'ExactSearch',
      empty: '',
    },
    fields: {
      id: 'ID',
      title: 'Product name',
      categories: 'Categories',
      link: 'Product link',
      image_link: 'Image link',
      availability: 'Availability',
      price: 'Price',
    },
    add: 'Add',
    descriptionButton: {
      hide: 'Hide',
      show: 'Show',
      description: 'Description',
    },
  },
  facetedSearchSettings: {
    header: 'Facated menu settings',
    description: 'A faceted menu is a kind of interface that allows us to filter easily. Thanks to it, we can specify exactly what elements we want to see in the search results and which we want to exclude. This is very useful in online stores, classifieds websites and other applications where we are looking for specific information. In the faceted menu, we have several drop-down lists in which we select the categories or features of the elements we are interested in, e.g. color, brand, price, size, etc. This makes the search process easier and more precise.',
    listHeader: 'List of returned fields',
    shortDescription: 'List of returned fields in the endpoint.',
    headerAdd: 'Add',
    addNewFieldLabel: 'Add new field',
    addNewLabelLabel: 'Label',
    addNewTypeLabel: 'Function',
    addNewUnitLabel: 'Unit',
    filtersHeader: 'Define filters',
    add: 'Add',
    allFieldsSelected: 'All fields selected',
    descriptionButton: {
      hide: 'Hide',
      show: 'Show',
      description: 'description',
    },
    tableHeaders: {
      field: 'Field',
      label: 'Label',
      type: 'Function',
      actionView: 'Actions',
    },
    fields: {
      id: 'ID',
      title: 'Product name',
      categories: 'Categories',
      link: 'Product name',
      image_link: 'Image link',
      availability: 'Availability',
      price: 'Price',
    },
    types: {
      category: 'Category',
      range: 'Range field',
      checkbox: 'Checkobx field',
      rangeSlider: 'Range slider',
    },
    upTooltip: 'UP',
    downTooltip: 'DOWN',
    deleteTooltip: 'Delete',
  },
  catalogMapping: {
    fields: {
      productId: 'Product ID',
      title: 'Title',
      categories: 'Categories',
      productLink: 'Link to the product page',
      imageLink: 'Link to the product image',
      availability: 'Availability',
      price: 'Price',
    },
    pickField: 'Select a field',
    addNewField: 'Add a new field from the directory',
    pickSeparator: 'Define elements separator',
    requiredFields: {
      tableName: 'Required fields',
      theadLabel: {
        qonId: 'Qon Id',
        feedField: 'Catalog field',
        dataType: 'Data type',
        description: 'Description',
      },
    },
    additionalFields: {
      notConfigured: 'You currently have no custom fields configured',
      tableName: 'Additional fields',
      theadLabel: {
        qonId: 'Qon Id',
        feedField: 'Catalog field',
        dataType: 'Data type',
        action: 'Actions',
      },
    },
    invalid: {
      qonId: 'Invalid ID',
      feedPath: 'Incorrectly selected path',
      dataType: 'Incorrectly selected data type',
      separator: 'Incorrectly selected separator',
    },
    error: {
      duringFindingXmlPaths: 'An error occurred while looking for paths in the XML file',
      fieldsInvalid: 'There are errors in mapping the paths from the file to the directory fields - correct the mapping configuration',
      storeRequestFailed: 'There was an error writing the directory mapping - please contact QON support',
      storeRequestUnknown: 'An unknown error has occurred - contact QON support',
    },
  },
  catalogWizard: {
    next: 'Next',
    previous: 'Previous',
    save: 'Save',
    tabsTitle: {
      basicConfiguration: 'Basic configuration',
      setMapping: 'Mapping settings',
      catalogPreview: 'Catalog preview',
      refreshSettings: 'Catalog refresh settings',
    },
  },
  htmlTemplate: {
    code: {
      title: 'Template html code',
    },
    preview: {
      title: 'Template preview',
      numberOfProducts: 'How many products to generate?',
      email: 'Enter email for testing',
      widgetId: 'Enter the Widget Id',
      widgetIdList: 'See the list of widgets in the panel',
    },
    settings: {
      title: 'Settings',
    },
    tooltip: {
      editSettings: 'Edit template settings',
      editCode: 'Edit the template\'s html code',
      generateSnippets: 'Generate a snippet',
    },
    editSettings: 'You are editing template settings:',
    editCode: 'You are editing the template code:',
    generateSnippets: 'You generate an HTML snippet for the template:',

    saveButtonLabel: 'Save',
    cancelButtonLabel: 'Cancel',
    newTemplateButton: 'Create a new template',
    title: 'Manage HTML Templates (Image Mailing API v2)',
    widthLabel: 'Width',
    widthErrorLabel: 'Incorrect width',
    heightLabel: 'Height',
    heightErrorLabel: 'Incorrect height',
    idLabel: 'Template Id',
    idErrorLabel: 'Invalid template Id',
    nameLabel: 'Template name',
    nameErrorLabel: 'Invalid template name',
  },
  searchTry: {
    testYourSearch: {
      title: 'Test your search engine',
      subtitle: 'your products are waiting for you to find them',
      inputPlaceholder: 'Product names, categories, tags...',
    },
    selectSortType: 'How to sort results:',
    resultList: {
      product: {
        id: 'Id',
        price: 'Price',
      },
    },
  },
  appV3Settings: {
    header: 'Application Search Settings',
    tabGeneral: 'General Settings',
    tabOnfocus: 'Onfocus Settings',
    tabAutocomplete: 'Autocomplete Settings',
    tabSerp: 'Search Results Page Settings',
    fieldValue: {
      true: 'YES',
      false: 'NO',
    },
    desc: {
      appConfiguration: 'In this section, you can customize the general settings of the application, such as the redirection URL, handling decimals in prices, currency selection, and the use of a hash before search parameters. These settings are global and control the behavior of the entire search application.',
      onfocus: 'This section covers adjustments for the search field, such as displaying product images when the onfocus event occurs, settings for recent and popular queries, as well as customization of the list of popular products.',
      autocomplete: 'Here you can customize which suggestions should be displayed when entering a phrase, the number of autocomplete results, and the images accompanying the suggestions.',
      serp: 'This section covers adjustments for displaying search results, such as filters, sorting, pagination, tag list, and the size of product images.',
    },
    group: {
      onfocus: {
        popularProducts: 'Popular Products',
        popularQueries: 'Popular Queries',
        predefinedQueries: 'Custom Queries',
        productImageSize: 'Product Images',
        recentQueries: 'Recent Searches',
        topProduct: 'Top Products',
      },
      autocomplete: {
        bestseller: 'Bestsellers',
        productImageSize: 'Product Images',
        results: 'Results',
        suggestions: 'Suggestions',
      },
      serp: {
        query: 'Queries',
        tagList: 'Tag List',
        productImageSize: 'Product Images',
        filters: 'Filters',
        gridSettings: 'Product Layout',
        markedFilters: 'Selected Filters',
        pagination: 'Pagination',
        customComponents: 'Custom Templates',
        sorting: 'Sorting',
      },
    },
    fields: {
      appConfiguration: {
        applicationEnabled: 'Enabling the search application',
        redirectionUrl: 'Redirection URL',
        removeDecimals: 'Remove Decimals',
        currency: 'Currency',
        useHashBeforeSearchParams: 'Use Hash Before Search Parameters',
      },
      onfocus: {
        showOnfocus: 'Show onfocus?',
        popularProducts: {
          count: 'Number of Popular Products',
          sortName: 'Sorting Used in Popular Products',
          enabled: 'Popular Products Enabled',
        },
        popularQueries: {
          enabled: 'Popular Queries Enabled',
          count: 'Number of Popular Queries',
        },
        predefinedQueries: {
          enabled: 'Enable custom queries?',
          sectionsList: 'Defined queries',
          sectionsListAdd: 'Add a section',
          sections: {
            '*': {
              header: 'Section header',
              queriesList: 'Queries',
              queriesListAdd: 'Add a label',
              queries: {
                '*': {
                  query: 'Query label',
                  link: 'URL',
                },
              },
            },
          },
        },
        productImageSize: {
          fixedWidth: 'Set the width of the product image',
          width: 'Product Image - Width',
          fixedHeight: 'Set the height of the product image',
          height: 'Product Image - Height',
        },
        recentQueries: {
          enabled: 'Show recent searches?',
          count: 'Number of recent searches',
        },
        topProduct: {
          enabled: 'Top Products Enabled',
          productImageSize: {
            fixedWidth: 'Set the width of the popular product photo',
            width: 'Top Product Image - Width',
            fixedHeight: 'Set the height of the popular product photo',
            height: 'Top Product Image - Height',
          },
        },
      },
      autocomplete: {
        showButtonOnInput: 'Show button in search box?',
        showAutocomplete: 'Show autocomplete?',
        bestseller: {
          enabled: 'Are bestsellers enabled?',
        },
        productImageSize: {
          fixedWidth: 'Set the width of the product image',
          width: 'Product Image - Width',
          fixedHeight: 'Set the height of the product image',
          height: 'Product Image - Height',
        },
        results: {
          count: 'Number of Presented Results',
        },
        suggestions: {
          enabled: 'Suggestions Enabled',
          count: 'Number of Suggestions',
        },
      },
      serp: {
        showInput: 'Show search field',
        showButtonOnInput: 'Show button in search box',
        backToTopButton: {
          enabled: 'Back to Top Button Enabled',
        },
        query: {
          allowEmptyQuery: 'Allow Empty Query',
        },
        sorting: {
          showSorting: 'Show Sorting',
        },
        tagList: {
          showTagList: 'Show tag list:',
          limitPerFilter: 'Limit per tag list filter',
        },
        productImageSize: {
          useQuarticImages: 'Use Images from Quarticon Server',
          fixedWidth: 'Set the width of the product image',
          width: 'Product Image - Width',
          fixedHeight: 'Set the height of the product image',
          height: 'Product Image - Height',
        },
        filters: {
          showAsideFilters: 'Show Aside Filters',
          showTopFilters: 'Show top filters',
        },
        gridSettings: {
          column: {
            smallDevice: 'Number of Products in Row for Small Devices',
            mediumDevice: 'Number of Products in Row for Medium Devices',
            largeDevice: 'Number of Products in Row for Large Devices',
            extraLargeDevice: 'Number of Products in Row for Extra Large Devices',
          },
        },
        markedFilters: {
          enabled: 'Mark Selected Filters - Enabled',
        },
        pagination: {
          useTop: 'Show pagination at top of page',
          useBottom: 'Show pagination at the bottom of the page',
        },
        customComponents: {
          productCard: 'Custom Product Card Template',
          autocompleteSingleResult: 'Custom Autocomplete Result Template',
          singlePopularProduct: 'Custom Single Popular Product Template',
          topProduct: 'Custom Top Products Template',
        },
      },
    },
    fieldsTooltip: {
      appConfiguration: {
        applicationEnabled: 'Enables/disables the front search application',
        redirectionUrl: 'Set on which page you want the search results page to be displayed',
        removeDecimals: 'Do you want to remove decimal parts .00 when displaying prices?',
        currency: 'Set the currency in which prices are returned',
        useHashBeforeSearchParams: 'If the page is based on passing parameters using a hash, set it up',
      },
      onfocus: {
        showOnfocus: 'Do you want to display the onfocus window after clicking on the input?',
        productImageSize: {
          fixedWidth: 'Should photos generated on onfocus have an imposed width? Unchecking will automatically set the width.',
          width: 'Set the size of images generated on onfocus - width',
          fixedHeight: 'Should photos generated on onfocus have an imposed height? Unchecking will automatically set the height.',
          height: 'Set the size of images generated on onfocus - height',
        },
        popularQueries: {
          enabled: 'Should popular queries be shown to the user?',
          count: 'How many queries to display in popular phrases ',
        },
        popularProducts: {
          count: 'How many popular products to display',
          sortName: 'Sorting used in popular products',
          enabled: 'Should popular products be displayed? Specify the sorting criteria for returning products. You can create sorting by bestsellers and indicate it here?',
        },
        topProduct: {
          enabled: 'Should the first product returned in popular products be promoted?',
          productImageSize: {
            fixedWidth: 'Should photos of promoted products have an imposed width? Unchecking will automatically set the width.',
            width: 'Set the size of images for promoted products - width',
            fixedHeight: 'Should photos of promoted products have an imposed height? Unchecking will automatically set the height.',
            height: 'Set the size of images for promoted products - height',
          },
        },
        predefinedQueries: {
          enabled: 'Should predefined queries be displayed?',
          sectionsList: 'If you want to show the user predefined queries, you can add them here. By providing a link, the user will be redirected to the specified link. When you add a predefined query without a link, the query will be used to search for it',
          sections: {
            '*': {
              header: 'Header visible before the list of queries',
              queriesList: '',
              queries: {
                '*': {
                  query: 'Label visible as a link. If no URL link is provided below, this is also a Search query',
                  link: 'URL link to the page',
                },
              },
            },
          },
        },
        recentQueries: {
          enabled: 'Should the user be shown their recent searches',
          count: 'Number of recent searches',
        },
      },
      autocomplete: {
        showButtonOnInput: 'Should the Search button be displayed on the search input?',
        showAutocomplete: 'Should the autocomplete window be displayed during searching?',
        bestseller: {
          enabled: 'Should the first product be promoted as Bestseller?',
        },
        productImageSize: {
          fixedWidth: 'Should photos generated on autocomplete have a defined width? Unchecking will automatically set the width.',
          width: 'Image size on autocomplete - width',
          fixedHeight: 'Should photos generated on autocomplete have an imposed height? Unchecking will automatically set the height.',
          height: 'Image size on autocomplete - height',
        },
        suggestions: {
          enabled: 'Should suggestions for entered phrases be displayed to the user?',
          count: 'Number of suggestions displayed to the user',
        },
        results: {
          count: 'Number of presented results',
        },
      },
      serp: {
        showInput: 'Should an additional input be displayed on the search results page?',
        showButtonOnInput: 'Should the Search button be shown on the input?',
        tagList: {
          showTagList: 'Should the user be shown a section of tags above the search results? Applies only to string or box-type filters?',
          limitPerFilter: 'Limit of displayed tags above the search results',
        },
        query: {
          allowEmptyQuery: 'Should all products be displayed on the results page when the user has not entered any query?',
        },
        filters: {
          showAsideFilters: 'Should the user be shown the Filters section on the search results page?',
          showTopFilters: 'Should the user be shown the filter section above the search results?',
        },
        sorting: {
          showSorting: 'Should the user be shown a button to change sorting?',
        },
        pagination: {
          useTop: 'Should the user be shown a button to change the page at the top of the page?',
          useBottom: 'Should the user be shown a button to change the page at the bottom of the page?',
        },
        backToTopButton: {
          enabled: 'Should the user be shown a back-to-top button?',
        },
        markedFilters: {
          enabled: 'Should the user be shown a section of marked filters?',
        },
        productImageSize: {
          useQuarticImages: 'Should the images generated on the user\'s results come from the Quarticon server',
          fixedWidth: 'Should photos generated in search results have an imposed width? Unchecking will automatically set the width.',
          width: 'Set the size of images generated on user results - width',
          fixedHeight: 'Should photos generated in search results have an imposed height? Unchecking will automatically set the height.',
          height: 'Set the size of images generated on user results - height',
        },
        gridSettings: {
          column: {
            smallDevice: 'Set how many products in a column should be displayed on a small device, e.g., phone',
            mediumDevice: 'Set how many products in a column should be displayed on a medium device, e.g., tablet',
            largeDevice: 'Set how many products in a column should be displayed on a large device, e.g., 1080p monitor',
            extraLargeDevice: 'Set how many products in a column should be displayed on an extra-large device, e.g., 4k monitor',
          },
        },
        customComponents: {
          productCard: 'When you want to change the appearance of the SERP product tile, specify new HTML with macros for its generation. More here: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          autocompleteSingleResult: 'When you want to change the appearance of the product tile in Autocomplete, specify new HTML with macros for its generation. More here: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          singlePopularProduct: 'When you want to change the appearance of the product tile in the section of popular products, specify new HTML with macros for its generation. More here: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
          topProduct: 'When you want to change the appearance of the product tile in the top product section, specify new HTML with macros for its generation. More here: https://howto.quarticon.com/pl/articles/8450886-instrukcja-wdrozenia-ai-smart-search-v3-za-pomoca-aplikacji-vue-frontend#h_267780d67d',
        },
      },
    },
    deleteAsk: 'Are you sure you want to delete?',
    delete: 'Remove',
    save: 'Save',
    saveSuccess: 'The data was saved correctly',
  },
  appV3Labels: {
    header: 'Set Application Labels',
    autocomplete: 'Autocomplete Labels',
    popularQueries: 'Popular Query Labels',
    popularProducts: 'Popular Product Labels',
    serp: 'Search Results Labels',
    filters: 'Filter Labels',
    pagination: 'Pagination Labels',
    fields: {
      autocomplete: {
        whatSearch: 'What are you looking for?',
        typeTerm: 'Enter search term',
        seeMore: 'See more',
      },
      popularQueries: {
        header: 'Popular Phrases',
      },
      popularProducts: {
        header: 'Popular Products',
      },
      serp: {
        resultHeader: 'Search results for',
        results: 'results',
        page: 'Page',
        emptyResults: 'No results',
        typeQuery: 'Enter a query in the search bar at the top of the page',
        showedAll: 'Showed all results',
        omnibusLowestPrice: 'Lowest price from the last 30 days',
      },
      filters: {
        header: 'Filters',
        clear: 'Clear filters',
        show: 'Show filters',
        from: 'from',
        to: 'to',
      },
      pagination: {
        next: 'Next',
        prev: 'Previous',
      },
    },
    save: 'Save',
    saveSuccess: 'The data was saved correctly',
  },
  validation: {
    fields: {
      instanceName: 'instance name',
      selectedCatalog: 'selected catalog',
      catalogName: 'catalog name',
      selectedIndustry: 'directory industry',
      selectedLanguage: 'directory language',
      catalogUrl: 'directory link',
      newDomain: 'Domain',
      sortLabel: 'Sort label',
      sortName: 'Sort name',
      facetedMenuName: 'Menu name',
      '{field}': 'field',
      selectSortConditionScript: 'sort condition script',
    },
    rules: {
      alpha_dash: 'The {_field_} field may contain alpha-numeric characters as well as dashes and underscores',
      alpha_num_dash_spaces: 'The {_field_} field may contain alpha-numeric characters as well as spaces, dashes and underscores',
      min: 'The {_field_} field must be at least {length} characters',
      max: 'The {_field_} field may not be greater than {length} characters',
      oneOf: 'The {_field_} field is not a valid value',
      required: 'The {_field_} field is required',
      domain: 'The {_field_} is not valid domain',
      alpha_unicode_num_dash_spaces: 'The {_field_} field may contain alpha-numeric characters as well as spaces, dashes and underscores',
      alpha_num: 'The {_field_} field may contain alpha-numeric characters',
      regex: 'Neplatný výraz',
    },
  },
}
