import Vue from 'vue'
import Vuex from 'vuex'
import { apiRequestIsCancel } from '@/apiRequest'

Vue.use(Vuex)

interface Info {
  name: string
  message: string
}

interface Notifications {
  errors: Array<Error>
  popupError: Error
  popupInfo: Info
}

const getInitialState = (): any => ({
  errors: [],
  popupError: [],
  popupInfo: [],
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    // state of wizard
    addError(state: Notifications, error: Error) {
      if (!apiRequestIsCancel(error)) {
        state.errors.push(error)
      }
    },
    addPopupError(state: Notifications, error: Error) {
      state.popupError = error
    },
    clearErrors(state: Notifications) {
      state.errors = []
    },
    addPopupInfo(state: Notifications, info: Info) {
      state.popupInfo = info
    },
  },
  getters: {
    getErrors(state: Notifications) {
      return state.errors
    },
  },
})
