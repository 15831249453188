import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface SearchInstance {
  searchInstanceId: string
}

export default new Vuex.Store({
  state: (): SearchInstance => ({
    searchInstanceId: '',
  }),
  mutations: {
    setSearchInstanceId(state: SearchInstance, searchInstanceId: string) {
      state.searchInstanceId = searchInstanceId
    },
    assertSearchInstanceId(state: SearchInstance, searchInstances: { instanceId: string }[]) {
      if (searchInstances.length === 0) {
        throw new Error('SearchInstancesList is empty')
      }
      if (!state.searchInstanceId) {
        state.searchInstanceId = searchInstances[0].instanceId
      }
    },
  },
})
