import apiRequest from '@/apiRequest'
import moment from 'moment'
import Notifications from '@/store/notifications/notifications'
import { ROWS_PER_PAGE } from './reportSettings'

interface PhraseRequest {
  type: string,
  start: string,
  end: string,
  page: number,
  sort: string,
  orderBy: string,
}

interface PhraseExportAllRequest {
  type: string,
  start: string,
  end: string,
  sort: string,
  orderBy: string,
}

export default {
  namespaced: true,
  state: {
    dataLoading: false,
    results: [],
    totalPages: 1,
    limit: ROWS_PER_PAGE,
  },
  mutations: {
    setDataLoading(state: any, isLoading: boolean) {
      state.dataLoading = isLoading
    },
    setResults(state: any, results: boolean) {
      state.results = results
      state.dataLoading = false
    },
    setTotalPages(state: any, totalPages: number) {
      state.totalPages = Math.ceil(totalPages / state.limit)
    },
  },
  actions: {
    getData(context: any, request: PhraseRequest) {
      if (context.state.dataLoading) {
        return
      }

      context.commit('setDataLoading', true)

      const requestData = {
        offset: context.state.limit * (request.page - 1),
        limit: context.state.limit,
        ...request,
      }

      apiRequest
        .post('search/reports/phrases', requestData)
        .then(result => {
          context.commit('setTotalPages', result.data.total)
          context.commit('setResults', result.data.data)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
          context.commit('setDataLoading', false)
        })
    },
    getFile(context: any, request: PhraseExportAllRequest) {
      if (context.state.dataLoading) {
        return
      }
      context.commit('setDataLoading', true)
      const dateFormat = 'YYYY-MM-DD'
      const fileName = `phrases__${request.type}__${moment(request.start).format(dateFormat)}-${moment(request.end).format(dateFormat)}.csv`

      apiRequest
        .post('search/reports/export/phrases', request)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.download = fileName
          link.click()
          context.commit('setDataLoading', false)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
          context.commit('setDataLoading', false)
        })
    },
  },
}
