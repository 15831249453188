import apiRequest from '@/apiRequest'
import Notifications from '@/store/notifications/notifications'
import Vue from 'vue'
import Vuex from 'vuex'

interface Industry {
  label: string;
}

interface IndustryState {
  industries: Array<Industry>
}

interface IndustryResponse {
  id: number;
  label: string;
}

interface IndustriesResponse {
  data: Array<IndustryResponse>
  total: number
}

Vue.use(Vuex)

export default new Vuex.Store({

  state: (): IndustryState => (
    {
      industries: [],
    }
  ),

  mutations: {
    setIndustries(state: IndustryState, industries: Array<Industry>) {
      state.industries = industries
    },
  },

  actions: {
    loadIndustries(context: any) {
      const response = apiRequest.get<IndustriesResponse>('catalog/industries')

      return response
        .then(result => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'catalog.industry.errorDuringLoading', { root: true })
            return
          }

          const industries: any = {}

          result.data.data.forEach(industry => {
            industries[industry.id] = industry.label
          })

          context.commit('setIndustries', industries)
        })
        .catch(() => {
          Notifications.commit('addError', 'catalog.industry.errorDuringLoading', { root: true })
        })
    },
  },

  getters: {
    getIndustries(state: IndustryState): Array<Industry> {
      return state.industries
    },
  },
})
