import apiRequest from '@/apiRequest'
import Notifications from '@/store/notifications/notifications'
import Vue from 'vue'
import Vuex from 'vuex'

interface Language {
  label: string;
}

interface LanguageState {
  languages: Array<Language>
}

interface LanguageResponse {
  id: number;
  code: string;
  label: string;
}

interface LanguagesResponse {
  data: Array<LanguageResponse>
  total: number;
}

Vue.use(Vuex)

export default new Vuex.Store({

  state: (): LanguageState => (
    {
      languages: [],
    }
  ),

  mutations: {
    setLanguages(state: LanguageState, languages: Array<Language>) {
      state.languages = languages
    },
  },

  actions: {
    loadLanguages(context: any) {
      const response = apiRequest.get<LanguagesResponse>('catalog/languages')

      return response
        .then(result => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'catalog.language.errorDuringLoading', { root: true })
            return
          }

          const languages: any = {}

          result.data.data.forEach(language => {
            languages[language.id] = language.code
          })

          context.commit('setLanguages', languages)
        })
        .catch(error => {
          Notifications.commit('addError', error.message, { root: true })
        })
    },
  },

  getters: {
    getLanguages(state: LanguageState): Array<Language> {
      return state.languages
    },
  },
})
