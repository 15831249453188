import apiRequest from '@/apiRequest'
import RequiredFieldsStore from '@/store/searchCatalogMapping/requiredFields'
import AdditionalFieldsStore from '@/store/searchCatalogMapping/additionalFields'
import Notifications from '@/store/notifications/notifications'
import Vuex from 'vuex'
import Vue from 'vue'

interface RequestMapping {
  path: string
  fieldLabel: string
  fieldType: string,
  separator?: string,
}

interface RequestMappings {
  mapping: RequestMapping[]
}

interface XmlAnalyseResultResponse {
  foundPaths: string[],
  xmlUri: string,
  ex: string,
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => (
    {
      // mapping component
      foundPaths: [],
      preview: [],
    }
  ),

  mutations: {
    setFoundPaths(state: any, foundPaths: string[]) {
      state.foundPaths = foundPaths
    },
    setPreview(state: any, preview: string[]) {
      state.preview = preview
    },
  },

  actions: {
    async findXmlPaths(context: any, linkUri: string) {
      AdditionalFieldsStore.commit('addSuggestedFields')
      const responseAnalyzer = await apiRequest
        .get<XmlAnalyseResultResponse>(`${process.env.VUE_APP_CATALOG_ANALYSE_URL}/api/xmlAnalyzer/analyze?xmlUri=${linkUri}`)

      if (responseAnalyzer.status !== 200) {
        Notifications.commit('addError', 'catalogMapping.error.storeRequestUnknown', {root: true})
        return
      }
      context.commit('setFoundPaths', responseAnalyzer.data.foundPaths)
    },
    async getPreviewData(context: any, linkUri: string) {
      context.commit('setPreview', [])

      const responsePreview = await apiRequest
        .get<XmlAnalyseResultResponse>(`${process.env.VUE_APP_CATALOG_ANALYSE_URL}/api/xmlAnalyzer/preview?xmlUri=${linkUri}`)
      if (responsePreview.status !== 200) {
        Notifications.commit('addError', 'catalogMapping.error.storeRequestUnknown', { root: true })
        return
      }
      context.commit('setPreview', responsePreview.data.foundPaths)
    },
    async saveMappingSettings() {
      const requiredFields = RequiredFieldsStore.state.requiredFields.map((field: any) => ({
        path: field.qonId,
        fieldLabel: field.catalogField,
        fieldType: field.dataType,
        separator: field.separator,
      }))

      const additionalFields = AdditionalFieldsStore.state.additionalFields.map((field: any) => ({
        path: field.qonId,
        fieldLabel: field.catalogField,
        fieldType: field.dataType,
        separator: field.separator,
      }))

      const payload = {
        mapping: [
          ...requiredFields,
          ...additionalFields,
        ],
      }

      return apiRequest.post<RequestMappings>('xmlMapping/storeMapping', payload)
    },
  },
})
