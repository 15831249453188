/** axios for API calls */
import axios, { AxiosRequestConfig } from 'axios'

const apiRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/`,
})

let requestTokensToCancel: AbortController[] = []

apiRequest.interceptors.request.use((config: AxiosRequestConfig) => {
  const returnConfig = config
  const abortController = new AbortController()
  returnConfig.signal = abortController.signal

  requestTokensToCancel.push(abortController)

  return returnConfig
}, (error: any) => Promise.reject(error))

apiRequest.interceptors.response.use(undefined, error => {
  if (error?.response?.status === 401) {
    window.location.href = process.env.VUE_APP_OLD_PANEL_URL
  }
  throw error
})

export function cancelRequests() {
  requestTokensToCancel.forEach(request => {
    request.abort()
  })

  requestTokensToCancel = []
}

export function apiRequestIsCancel(error: Error): boolean {
  return axios.isCancel(error)
}

export default apiRequest
