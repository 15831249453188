import apiRequest from '@/apiRequest'
import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => (
    {
      isImported: false,
      importResult: {
        correct: -1,
        wrong: -1,
      },
      haveImportedProducts: false,
      importedProducts: {
        correctProducts: [],
        total: 0,
        totalPages: 0,
        page: 1,
      },
      importedProductsPerPage: 20,
      detailPopup: {
        active: false,
        product: null,
        mainProductId: null,
      },
      warnings: [],
      error: null,
    }
  ),
  mutations: {
    setIsImported(state: any, isImported: boolean) {
      state.isImported = isImported
    },
    setImportResult(state: any, result: any) {
      state.importResult.correct = result.correctCount
      state.importResult.wrong = result.wrongCount
    },
    setImportedProducts(state: any, result: any) {
      state.haveImportedProducts = true
      state.importedProducts = result
      state.importedProducts.page = 1
      state.importedProducts.totalPages = Math.ceil(state.importedProducts.total / state.importedProductsPerPage)
    },
    setImportedProductsPage(state: any, page: string) {
      state.importedProducts.page = page
    },
    resetProductList(state: any) {
      state.error = null
      state.haveImportedProducts = false
      state.importedProducts = {
        correctProducts: [],
        total: 0,
        totalPages: 0,
        page: 1,
      }
    },
    showProductPopup(state: any, payload: { product: any, mainProductId: string }) {
      state.detailPopup.active = true
      state.detailPopup.product = payload.product
      state.detailPopup.mainProductId = payload.mainProductId
    },
    setActiveProductPopup(state: any, active: any) {
      state.detailPopup.active = active
    },
    setError(state: any, error: any) {
      state.error = error
    },
    setFieldPathsWithWarnings(state: any, warnings: any) {
      state.warnings = warnings
    },
  },
  actions: {
    resetProductList(context: any) {
      context.commit('resetProductList', null)
    },

    fetchProductsList(context: any, url: string) {
      return apiRequest
        .get(url)
        .then(result => {
          if (result.status === 200) {
            context.commit('setImportedProducts', result.data.data)
          }
          return result
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },

    getProdProductsList(context: any, catalogId: string) {
      return context.dispatch('fetchProductsList', `importCatalog/getImportedProducts/${catalogId}/${context.state.importedProducts.page.toString()}/${context.state.importedProductsPerPage.toString()}`)
    },

    getDevProductList(context: any) {
      return context.dispatch('fetchProductsList', `importCatalog/getImportedProducts/${context.state.importedProducts.page.toString()}/${context.state.importedProductsPerPage.toString()}`)
    },

    getProductsList(context: any, catalogId: string) {
      return (catalogId !== undefined && catalogId !== null) ? this.dispatch('getProdProductsList', catalogId) : this.dispatch('getDevProductList')
    },

    isCatalogImportFinished(context: any) {
      // const interval = setInterval(() => {
      return apiRequest.get('importCatalog/isTestImportFinished')
        .then((response: AxiosResponse) => {
          if (response.data.data.error) {
            context.commit('setError', response.data.data.error)
            throw new Error('błąd podczas importu')
          }
          context.commit('setIsImported', response.status === 200)
          context.commit('setImportResult', response.data.data)
          context.commit('setFieldPathsWithWarnings', response.data.data.warnings)
          return response
        })
        .catch((error: Error) => {
          throw error
        })
    },
    isCatalogProdFinished(context: any, catalogId: string) {
      return apiRequest.get(`importCatalog/isProdImportFinished/${catalogId}`)
        .then((response: AxiosResponse) => {
          if (response.data.data.error) {
            context.commit('setError', response.data.data.error)
            throw new Error('błąd podczas importu')
          }
          context.commit('setIsImported', response.status === 200)
          context.commit('setImportResult', response.data.data)
          context.commit('setFieldPathsWithWarnings', response.data.data.warnings)
          return response
        })
        .catch((error: Error) => {
          throw error
        })
    },
  },

  getters: {
    isImportFinished(state: any): boolean {
      return state.isImported
    },
    haveImportedProducts(state: any): boolean {
      return state.haveImportedProducts
    },
    getSuccessImportedProducts(state: any): any {
      return state.importedProducts
    },
    correctlyImportedProductsCount(state: any): number {
      return state.importResult.correct
    },
    incorrectlyImportedProductsCount(state: any): number {
      return state.importResult.wrong
    },
    errorOccurred(state: any) {
      return state.error
    },
    feedPathsWithWarnings(state: any) {
      return state.warnings
    },
  },
})
