import suggestedFields from '@/store/searchCatalogMapping/suggestedFields'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface AdditionalField {
  description: string,
  qonId: string,
  catalogField: string,
  dataType: string,
  separator?: string,
  isDeletable?: boolean,
}

interface AdditionalFieldsState {
  additionalFields: AdditionalField[]
}

const emptyField = (): AdditionalField => ({
  description: '',
  qonId: '',
  catalogField: '',
  dataType: '',
  separator: '',
  isDeletable: true,
})

export default new Vuex.Store({
  state: (): AdditionalFieldsState => (
    {
      additionalFields: [],
    }
  ),
  mutations: {
    addSuggestedFields(state) {
      if (!state.additionalFields.length) {
        suggestedFields.state.SuggestedFields.forEach(field => {
          state.additionalFields.push(field)
        })
      }
    },
    clearAdditionalField(state) {
      state.additionalFields = []
    },
    addAdditionalField(state) {
      state.additionalFields.push(emptyField())
    },
    addAdditionalFieldData(state, field: AdditionalField) {
      state.additionalFields.push(field)
    },
    removeAdditionalField(state, index) {
      state.additionalFields = state.additionalFields.filter((element, elementIndex) => elementIndex !== index)
    },
  },
})
