import moment from 'moment'

export default {
  namespaced: true,
  state: {
    activeDataSource: null,
    dateRange: {
      start: moment().subtract(1, 'months').set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD'),
      end: moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD'),
    },
  },
  mutations: {
    setActiveDataSource(state, dataSourceKey) {
      state.activeDataSource = dataSourceKey
    },
    setDateStart(state, date) {
      state.dateRange.start = date
    },
    setDateEnd(state, date) {
      state.dateRange.end = date
    },
  },
}
