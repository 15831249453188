import apiRequest from '@/apiRequest'
import store from '@/store'
import AuthService from './authService'

const apiUrl: string = process.env.VUE_APP_API_URL

export default {
  init() {
    const authService = new AuthService()
    const accessToken = authService.getLocalAccessToken()
    if (accessToken) {
      store.commit('auth/SET_BEARER', accessToken)
    }
  },

  /**
   * Get Bearer Token by relogin hash (login from old control panel)
   */
  getTokenDataByHash(hash: string): Promise<any> {
    return apiRequest.get(`${apiUrl}/api/auth/relogin/${hash}`)
  },

  /**
   * Get user`s profile data
   */
  getProfile(): Promise<any> {
    return apiRequest.get(`${apiUrl}/api/auth/profile`)
  },

  /**
   * Call to refreshToken endpoint
   */
  refreshToken(): Promise<any> {
    return apiRequest.get(`${apiUrl}/api/auth/refresh`)
  },

  /**
   * Call to logout endpoint
   */
  logout(): Promise<any> {
    return apiRequest.get(`${apiUrl}/api/auth/logout`)
  },
}
