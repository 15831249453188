import apiRequest from '@/apiRequest'
import { AxiosResponse } from 'axios'
import ApiRequestPaths from '@/apiRequestPaths'
import {
  SearchSetSettingsResponse,
  SearchSharedField,
  SearchSharedFields,
  SearchSuggestion,
} from '@/store/search/searchApiRequestHelper'

interface CustomerPeriodsPayload {
  periods: Array<string>
}

interface CatalogSettings {
  industryId: number,
  languageCode: string,
  linkUri: string,
}

interface CatalogMappingField {
  fieldLabel: string,
  fieldType: string,
  path: string,
}

interface CatalogMappingSettings {
  mapping: Array<CatalogMappingField>
  catalogId: string
}

interface CatalogUrlResponse {
  url: string,
}

interface CatalogResponse {
  catalogUri: string,
  industryId: number,
  languageCode: string,
  catalogName: string,
}

export interface CatalogIdentity {
  id: string,
  name: string,
  type: string,
}

export interface CatalogInstance {
  id: string,
  name: string,
  description: string,
  url: string,
  language: string,
  industry: string,
}

export interface CatalogMapping {
  toPath: string,
  fromPath: string,
  castType: string,
  separateBy: string,
}

export interface CatalogImportPeriods {
  periods: string[],
}

export interface CatalogFields {
  data: string[],
}

export default class CatalogApiRequest {
  static async getWizardCatalogSettings(): Promise<AxiosResponse<CatalogResponse>> {
    return apiRequest.get<CatalogResponse>(ApiRequestPaths.GET_WIZARD_CATALOG_SETTINGS)
  }

  static setWizardCatalogSettings(catalogSettings: CatalogSettings) {
    console.log(catalogSettings)
    return apiRequest.post<CatalogSettings>(ApiRequestPaths.SET_WIZARD_CATALOG_SETTINGS, catalogSettings)
  }

  static async getCatalogRefreshSettings() {
    return apiRequest.get(ApiRequestPaths.GET_CATALOG_PERIODIC_SETTINGS)
  }

  static async setCatalogRefreshSettings(customerPeriodsPayload: CustomerPeriodsPayload) {
    return apiRequest.post<CustomerPeriodsPayload>(ApiRequestPaths.SET_CATALOG_REFRESH_SETTINGS, customerPeriodsPayload)
  }

  static async getCatalogsList(): Promise<AxiosResponse<Array<CatalogIdentity>>> {
    return apiRequest.get<Array<CatalogIdentity>>(ApiRequestPaths.GET_CATALOGS_LIST)
  }

  static async getCatalogInstances(): Promise<AxiosResponse<Array<CatalogInstance>>> {
    return apiRequest.get<Array<CatalogInstance>>(ApiRequestPaths.GET_CATALOG_INSTANCES_LIST)
  }

  static async getMappingsForCatalogInstance(catalogId: string): Promise<AxiosResponse<Array<CatalogMapping>>> {
    const mappingsPathUrl = ApiRequestPaths.GET_MAPPINGS_FOR_CATALOG_ID.replace('{catalogId}', catalogId)
    return apiRequest.get<Array<CatalogMapping>>(mappingsPathUrl)
  }

  static async getImportPeriodsForCatalogInstance(catalogId: string): Promise<AxiosResponse<CatalogImportPeriods>> {
    const importPathUrl = ApiRequestPaths.GET_IMPORT_PERIODS_FOR_CATALOG_ID.replace('{catalogId}', catalogId)
    return apiRequest.get<CatalogImportPeriods>(importPathUrl)
  }

  static async changeCatalogTypeToProduction(): Promise<AxiosResponse<any>> {
    return apiRequest.get<any>(ApiRequestPaths.SET_CATALOG_TYPE_PRODUCTION)
  }

  static async getCatalogFields(catalogId: string): Promise<AxiosResponse<CatalogFields>> {
    const mappingsPathUrl = ApiRequestPaths.GET_CATALOG_FIELDS.replace('{catalogId}', catalogId)
    return apiRequest.get<CatalogFields>(mappingsPathUrl)
  }

  static async getSharedFields(catalogId: string): Promise<AxiosResponse<Array<SearchSharedField>>> {
    return apiRequest.post<Array<SearchSuggestion>>(ApiRequestPaths.GET_SEARCH_SHARED_FIELDS, { catalogId })
  }

  static async setSharedFields(sharedFields: SearchSharedFields): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post<SearchSetSettingsResponse>(ApiRequestPaths.SET_SEARCH_SHARED_FIELDS, sharedFields)
  }

  static async deleteCatalog(catalogId: string): Promise<AxiosResponse<Array<any>>> {
    const deleteCatalogUrl = ApiRequestPaths.DELETE_CATALOG.replace('{catalogId}', catalogId)
    return apiRequest.delete(deleteCatalogUrl)
  }

  static async getCatalogHistory(catalogId: string): Promise<AxiosResponse<any>> {
    const catalogHistoryUrl = ApiRequestPaths.GET_CATALOG_HISTORY.replace('{catalogId}', catalogId)
    return apiRequest.get<any>(catalogHistoryUrl)
  }

  static async getCatalogMapping(catalogId: string): Promise<AxiosResponse<any>> {
    const catalogMappingUrl = ApiRequestPaths.GET_CATALOG_MAPPING.replace('{catalogId}', catalogId)
    return apiRequest.get<any>(catalogMappingUrl)
  }

  static async getCatalogFoundPaths(catalogId: string): Promise<AxiosResponse<any>> {
    const catalogFoundPathsUrl = ApiRequestPaths.GET_CATALOG_FOUND_PATHS.replace('{catalogId}', catalogId)
    return apiRequest.get<any>(`${process.env.VUE_APP_CATALOG_ANALYSE_URL}/api/${catalogFoundPathsUrl}`)
  }

  static async storeCatalogMapping(catalogMappingSettings: CatalogMappingSettings): Promise<AxiosResponse<any>> {
    return apiRequest.post<any>(ApiRequestPaths.STORE_CATALOG_MAPPING, catalogMappingSettings)
  }

  static async getCatalogUrl(catalogId: string): Promise<AxiosResponse<CatalogUrlResponse>> {
    const catalogUrl = ApiRequestPaths.GET_CATALOG_URL.replace('{catalogId}', catalogId)
    return apiRequest.get(catalogUrl)
  }
}
