import apiRequest from '@/apiRequest'
import store from '@/store'

export interface HtmlTemplate {
  html: string,
  width: number,
  height: number,
  templateName: string,
  templateId: string,
  id?: number,
  customerSymbol?: string
}

const DATE = new Date().toLocaleDateString()
const TIME = new Date().toLocaleTimeString().replaceAll(':', '_')

export const getInitialState = (): HtmlTemplate => ({
  html: '<!DOCTYPE html>\n'
    + '<head><meta charset="UTF-8"></head>\n'
    + '<body style="background-color:rgba(0,0,0,0.25);">\n'
    + '<table border="0" cellpadding="0" cellspacing="0"\n'
    + '       style="margin:0;padding:0;background-color:transparent;border:none;outline:none;height:320px;width:200px;">\n'
    + '    <tbody>\n'
    + '    <tr>\n'
    + '        <td style="text-align:center;padding-top:12px;padding-right:6px;padding-left:6px;padding-bottom:12px;background-color:transparent;border:0 none transparent;width:154px"\n'
    + '            width="154">\n'
    + '            <p style="font-size:14px;font-family:Tahoma;text-align:center;width:154px;margin:0 auto" width="154">\n'
    + '                %%title%%\n'
    + '            </p>\n'
    + '        </td>\n'
    + '    </tr>\n'
    + '    <tr>\n'
    + '        <td style="text-align:center;padding:0 6px 12px 6px;background-color:transparent;font-size:0;line-height:0;border:0 none transparent;width:154px"\n'
    + '            width="154">\n'
    + '            <img alt="%%title%%" width="154" height="150"\n'
    + '                 src="%%image%%"\n'
    + '                 style="width:154px;margin:0;border:1px solid #c6c6c6" title="" class="CToWUd">\n'
    + '        </td>\n'
    + '    </tr>\n'
    + '    <tr>\n'
    + '        <td style="text-align:center;padding-top:12px;padding-right:6px;padding-left:6px;padding-bottom:12px;background-color:transparent;border:0 none transparent;width:154px"\n'
    + '            width="154">\n'
    + '            <span style="font-size:14px;font-family:Tahoma;text-align:center;color:#0f866f">\n'
    + '                <b> %%price%% zł  </b>\n'
    + '                <s style="font-size:12px;color:#ff0000" %%oldPriceHidden%%> %%oldPrice%% zł</s>\n'
    + '            </span>\n'
    + '        </td>\n'
    + '    </tr>\n'
    + '    </tbody>\n'
    + '</table>\n'
    + '</body>',
  width: 200,
  height: 320,
  templateName: `Template ${DATE}`,
  templateId: `Template_${TIME}`,
})

export default {
  namespaced: true,

  state: getInitialState(),
  mutations: {
    setId(state: HtmlTemplate, templateId: string) {
      state.templateId = templateId
    },
    setName(state: HtmlTemplate, name: string) {
      state.templateName = name
    },
    setHtml(state: HtmlTemplate, html: string) {
      state.html = html
    },
    setWidth(state: HtmlTemplate, width: number) {
      state.width = width
    },
    setHeight(state: HtmlTemplate, height: number) {
      state.height = height
    },
    async storeHtmlTemplate(state: HtmlTemplate) {
      apiRequest
        .post('htmlTemplate/store', state)
        .then(() => {
          store.commit('notifications/addPopupInfo', {
            name: 'Sukces',
            message: `Szablon ${state.templateName} został zapisany`,
          })

          store.commit('htmlTemplateEditor/getAllTemplates')
        })
        .catch(error => {
          store.commit('notifications/addPopupError', {
            name: 'Błąd',
            message: 'Szablon nie został zapisany',
          })
          console.log(error)
        })
    },
    resetToInitialState(state: HtmlTemplate, newState: HtmlTemplate) {
      Object.assign(state, newState)
    },
  },
  actions: {
    storeHtmlTemplate(context: any) {
      context.commit('storeHtmlTemplate')
    },
    resetToInitialState(context: any) {
      context.commit('resetToInitialState', getInitialState())
    },
    setId(context: any, id: string) {
      context.commit('setId', id)
    },
    setName(context: any, name: string) {
      context.commit('setName', name)
    },
    setHtml(context: any, html: string) {
      context.commit('setHtml', html)
    },
    setWidth(context: any, width: number) {
      context.commit('setWidth', width)
    },
    setHeight(context: any, height: number) {
      context.commit('setHeight', height)
    },
    setWidgetId(context: any, widgetId: number) {
      context.commit('setWidgetId', widgetId)
    },
  },
  getters: {
    getHtmlCode(state: HtmlTemplate): string {
      return state.html
    },
    getWidth(state: HtmlTemplate): number {
      return state.width
    },
    getHeight(state: HtmlTemplate): number {
      return state.height
    },
    getWidthWithPxPostfix(state: HtmlTemplate): string {
      return state.width.toString().concat('px')
    },
    getHeightWithPxPostfix(state: HtmlTemplate): string {
      return state.height.toString().concat('px')
    },
    getName(state: HtmlTemplate): string {
      return state.templateName
    },
    getTemplateId(state: HtmlTemplate): string {
      return state.templateId
    },
  },
}
