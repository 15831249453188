export default class ApiRequestPaths {
  static readonly GET_WIZARD_CATALOG_SETTINGS = 'catalog/testCatalog'

  static readonly SET_WIZARD_CATALOG_SETTINGS = 'catalog/testCatalog'

  static readonly GET_CATALOG_PERIODIC_SETTINGS = 'catalog/periodicCatalog'

  static readonly SET_CATALOG_REFRESH_SETTINGS = 'catalog/periodicCatalog'

  static readonly GET_CATALOGS_LIST = 'catalog/catalogs'

  static readonly GET_SEARCH_INSTANCES = 'search/instances'

  static readonly STORE_SEARCH_INSTANCES = 'search/instance'

  static readonly GET_SEARCH_FIELD_WEIGHTS = 'search/getWeights'

  static readonly SET_SEARCH_FIELD_WEIGHTS = 'search/setWeights'

  static readonly GET_SEARCH_SORTS = 'search/getSorts'

  static readonly GET_SEARCH_SORTS_GROUPS = 'search/getSortGroups'

  static readonly GET_SEARCH_MOVE_DOWN_SORT_GROUPS = 'search/moveDownSortGroup'

  static readonly GET_SEARCH_MOVE_UP_SORT_GROUPS = 'search/moveUpSortGroup'

  static readonly GET_SEARCH_POPULARQUERIES_SETTINGS = 'search/getPopularQueriesSettings';

  static readonly DELETE_SEARCH_SORT_GROUP = 'search/deleteSortGroup'

  static readonly SET_STORE_SORT_GROUP = 'search/saveSortGroup'

  static readonly SET_SEARCH_SORTS = 'search/setSorts'

  static readonly GET_SEARCH_FACETED_MENU = 'search/getFacetedMenu'

  static readonly SET_SEARCH_FACETED_MENU = 'search/setFacetedMenu'

  static readonly GET_SEARCH_SUGGESTIONS = 'search/getSuggestions'

  static readonly SET_SEARCH_SUGGESTIONS = 'search/setSuggestions'

  static readonly GET_SEARCH_SHARED_FIELDS = 'search/getSharedFields'

  static readonly SET_SEARCH_SHARED_FIELDS = 'search/setSharedFields'

  static readonly SET_SEARCH_POPULARQUERIES_SETTINGS = 'search/setPopularQueriesSettings';

  static readonly GET_SEARCH_FIELDS = 'search/getFields'

  static readonly GET_CATALOG_INSTANCES_LIST = 'catalog/catalogs/full'

  static readonly GET_MAPPINGS_FOR_CATALOG_ID = 'catalogInstance/{catalogId}/mappings'

  static readonly GET_CATALOG_FIELDS = 'catalogInstance/{catalogId}/fields'

  static readonly GET_IMPORT_PERIODS_FOR_CATALOG_ID = 'catalogInstance/{catalogId}/importPeriods'

  static readonly SET_CATALOG_TYPE_PRODUCTION = 'catalogInstance/makeTypeProduction'

  static readonly GET_PRODUCT_LIST = 'catalog/{catalogId}/products'

  static readonly GET_PAGINATION_SETTINGS = 'search/getPagination'

  static readonly SET_PAGINATION_SETTINGS = 'search/setPagination'

  static readonly GET_CATALOG_HISTORY = 'catalog/{catalogId}/importStatus'

  static readonly GET_CATALOG_MAPPING = 'catalogInstance/{catalogId}/mappings'

  static readonly GET_CATALOG_FOUND_PATHS = 'xmlAnalyzer/foundPathsByCatalog/{catalogId}'

  static readonly GET_CATALOG_URL = 'catalogInstance/{catalogId}/url'

  static readonly STORE_CATALOG_MAPPING = 'xmlMapping/updateMapping '

  static readonly DELETE_CATALOG = 'catalog/{catalogId}'

  static readonly APPV3_SETTINGS = 'search/{searchId}/appSettings'

  static readonly APPV3_LABELS = 'search/{searchId}/appLabels'

  static readonly GET_STYLIZATION_DETAILS = 'stylist/stylization/{stylizationId}'

  static readonly UPDATE_STYLIZATION_DETAILS = 'stylist/stylization/{stylizationId}/update'

  static readonly GET_CATEGORY_MAPPING = 'catalogInstance/{catalogId}/categoriesMappings'

  static readonly UPDATE_MAPPINGS_FOR_CATEGORY_ID = 'stylist/updateMappingsByCategoryId'

  static readonly REFRESH_CATALOG_CATEGORIES_TREE = 'catalogInstance/{catalogId}/refreshCategoryTree'
}
