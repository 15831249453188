export interface AvailableField{
  path: string,
  fieldType: string;
}

export class AvailableFields {
  static getAvailableFieldsFromResponse(data: Array<AvailableField>) {
    return data.map(item => item.path)
  }
}
