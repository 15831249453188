import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from '@/auth/qonJwt/authService'
import store from '@/store'
import { cancelRequests } from '@/apiRequest'

Vue.use(VueRouter)

const routes = []

if (store.state.user.appActiveUser.features.includes('enabled_searchv3')) {
  routes.push({
    path: '/',
    redirect: '/search/instances',
  })
  routes.push({
    path: '/search',
    redirect: '/search/instances',
  })
} else {
  routes.push({
    path: '/',
    redirect: '/search',
  })
}

const mailingRoutes = [{
  path: '/search/htmlTemplate',
  name: 'htmlTemplate',
  component: () => import('@/views/HtmlTemplate.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: false,
  },
}]

routes.concat(mailingRoutes)

routes.push({
  path: '/search',
  name: 'searchDashboard',
  component: () => import('@/views/SearchDashboard.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: true,
  },
})

routes.push({
  path: '/search/phrasesReport',
  name: 'searchPhrasesReport',
  component: () => import('@/views/SearchPhrasesReport.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: true,
  },
})

routes.push({
  path: '/search/productsReport',
  name: 'searchProductsReport',
  component: () => import('@/views/SearchProductsReport.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: true,
  },
})

routes.push({
  path: '/search/synonyms',
  name: 'searchSynonyms',
  component: () => import('@/views/SearchSynonyms.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: false,
  },
})

if (process.env.VUE_APP_SHOW_BLOCKED_PHRASES !== 'false') {
  routes.push({
    path: '/search/blockedPhrase',
    name: 'searchBlockedPhrase',
    component: () => import('@/views/SearchBlockedPhrase.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
    },
  })
}
if (process.env.VUE_APP_SHOW_BLOCKED_INFLECTION !== 'false') {
  routes.push({
    path: '/search/immutablePhrase',
    name: 'searchImmutablePhrase',
    component: () => import('@/views/SearchBlockedInflection.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
    },
  })
}

routes.push({
  path: '/search/settings',
  name: 'searchSettings',
  component: () => import('@/views/SearchSettings.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: false,
  },
})

routes.push({
  path: '/search/phrasesReportWithNoResults',
  name: 'searchPhrasesReportWithNoResults',
  component: () => import('@/views/SearchPhrasesReportWithNoResults.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: true,
  },
})

if (store.state.user.appActiveUser.modules.includes('search')) {
  routes.push({
    path: '/search/weightsSettings',
    name: 'searchWeightsSettings',
    component: () => import('@/views/WeightsSettings.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
      showSearchSelectHeader: true,
    },
  })

  routes.push({
    path: '/search/sortSettings',
    name: 'searchSortSettings',
    component: () => import('@/views/SortSettings.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
      showSearchSelectHeader: true,
    },
  })
  routes.push({
    path: '/search/sortSettings/add/:searchId',
    name: 'addSortGroupInstance',
    component: () => import('@/views/components/sort/AddSortSettings.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
      showSearchSelectHeader: true,
    },
  })

  routes.push({
    path: '/search/searchFacetedSettings',
    name: 'searchFacetedSettings',
    component: () => import('@/views/FacetedSearchSettings.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
      showSearchSelectHeader: true,
    },
  })

  routes.push({
    path: '/search/searchSuggestionSettings',
    name: 'searchSuggestionSettings',
    component: () => import('@/views/SearchSuggestions.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
      showSearchSelectHeader: true,
    },
  })

  routes.push({
    path: '/search/searchSharedFieldsSettings',
    name: 'searchSharedFieldsSettings',
    component: () => import('@/views/SearchSharedFields.vue'),
    meta: {
      authRequired: true,
      showSearchStatisticsHeader: false,
      showSearchSelectHeader: false,
    },
  })
}
routes.push({
  path: '/api/authorization',
  name: 'searchApiAccess',
  component: () => import('@/views/ResultsAuthorization.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: false,
  },
})

routes.push({
  path: '/search/subscription',
  name: 'searchSubscription',
  component: () => import('@/views/SearchSubscription.vue'),
  meta: {
    authRequired: true,
    showSearchStatisticsHeader: false,
  },
})

if (store.state.user.appActiveUser.features.includes('enabled_searchv3')) {
  if (
    store.state.user.appActiveUser.modules.includes('catalog')
    || store.state.user.appActiveUser.modules.includes('search')
    || store.state.user.appActiveUser.modules.includes('tracking')
  ) {
    routes.push({
      path: '/catalog/instances',
      name: 'catalogInstances',
      component: () => import('@/views/CatalogInstances.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/catalog/mapping/:catalogId',
      name: 'catalogMapping',
      component: () => import('@/views/CatalogMapping.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/catalog/history/:catalogId',
      name: 'catalogHistory',
      component: () => import('@/views/CatalogHistory.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/catalog/time/:catalogId',
      name: 'catalogTime',
      component: () => import('@/views/CatalogTime.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/catalog/instance/:catalogId',
      name: 'catalogInstance',
      component: () => import('@/views/components/catalog/CatalogInstance.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/catalog/instance/:catalogId/categories',
      name: 'catalogInstanceCategories',
      component: () => import('@/views/components/catalog/CatalogInstanceCategories.vue'),
      meta: {
        authRequired: true,
      },
    })

    routes.push({
      path: '/catalog/instances/add',
      name: 'catalogInstanceAdd',
      component: () => import('@/views/Catalog.vue'),
      meta: {
        authRequired: true,
      },
    })
  }
  if (store.state.user.appActiveUser.modules.includes('search')) {
    routes.push({
      path: '/search/instances',
      name: 'searchInstances',
      component: () => import('@/views/SearchInstances.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/search/instance/:searchId',
      name: 'searchInstance',
      component: () => import('@/views/components/search/SearchInstance.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/search/instances/add',
      name: 'searchInstanceAdd',
      component: () => import('@/views/SearchInstancesAdd.vue'),
      meta: {
        authRequired: true,
      },
    })
    routes.push({
      path: '/search/try',
      name: 'searchTry',
      component: () => import('@/views/SearchTry/SearchTry.vue'),
      meta: {
        authRequired: true,
        showSearchSelectHeader: true,
      },
    })
    routes.push({
      path: '/search/v3/reportDashboard',
      name: 'searchV3AllReports',
      component: () => import('@/views/reports/AllReports.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeaderV3: true,
      },
    })
    routes.push({
      path: '/search/v3/phrases/all',
      name: 'searchV3AllPhrasesReport',
      component: () => import('@/views/reports/AllPhrasesReport.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeaderV3: true,
      },
    })
    routes.push({
      path: '/search/v3/phrases/emptySearch',
      name: 'searchV3EmptySearchPhrasesReport',
      component: () => import('@/views/reports/EmptySearchPhrasesReport.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeaderV3: true,
      },
    })
    routes.push({
      path: '/appV3Settings/settings',
      name: 'appV3Settings',
      component: () => import('@/views/components/appV3/AppV3Settings.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeader: false,
        showSearchSelectHeader: true,
      },
    })
    routes.push({
      path: '/appV3Settings/labels',
      name: 'appV3Labels',
      component: () => import('@/views/components/appV3/AppV3Labels.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeader: false,
        showSearchSelectHeader: true,
      },
    })
  }
  // Routing stylisty
  if (store.state.user.appActiveUser.modules.includes('stylist')) {
    routes.push({
      path: '/stylist/v3/reportDashboard',
      name: 'stylistReports',
      component: () => import('@/views/stylist/reports/AllReports.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeaderV3: true,
      },
    })
    routes.push({
      path: '/stylist/stylizations/list',
      name: 'stylizationsList',
      component: () => import('@/views/stylist/StylizationList.vue'),
      meta: {
        authRequired: true,
        showSearchSelectHeader: true,
      },
    })
    routes.push({
      path: '/stylist/stylization/:stylizationId',
      name: 'stylizationDetails',
      component: () => import('@/views/stylist/stylization/StylizationDetails.vue'),
      meta: {
        authRequired: true,
        showSearchStatisticsHeaderV3: true,
      },
    })
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // Auth
    {
      path: '/auth/:hash',
      name: 'relogin',
      component: () => import('@/views/auth/Relogin.vue'),
      meta: {},
    },
    ...routes,

    {
      path: '/pages/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    const authService = new AuthService()
    if (!authService.isAuthenticated()) {
      authService.clearSessionData()
      window.location.href = process.env.VUE_APP_OLD_PANEL_URL
      return
    }

    if (authService.isRefreshNeeded()) {
      authService.refreshToken()
    }
  }

  cancelRequests()
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  if (to.name === 'relogin') {
    return
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
