import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import i18n from '@/libs/i18n'
import {
  configure, ValidationObserver, ValidationProvider, extend,
} from 'vee-validate'

import {
  required, min, max, oneOf, alpha_num, alpha_dash, regex, min_value,
} from 'vee-validate/dist/rules'

Vue.component(FeatherIcon.name, FeatherIcon)

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`validation.fields.${field}`)
    return i18n.t(`validation.rules.${values._rule_}`, values)
  },
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('required', required)
extend('min', min)
extend('minValue', min_value)
extend('max', max)
extend('oneOf', oneOf)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)
extend('regex', regex)

extend('alpha_num_dash_spaces', {
  validate(value) {
    return {
      required: false,
      valid: /^[\w -]+$/.test(value),
    }
  },
})

extend('distinct', {
  params: ['target'],
  validate(value, { target }) {
    let valid = true
    const validTarget = !Array.isArray(target) ? [target] : target
    validTarget.forEach(elementValue => {
      if (value === elementValue) {
        valid = false
      }
    })
    return valid
  },
  message: 'Fields can not be the same',
})

extend('distinctTime', {
  params: ['target'],
  validate(value, { target }) {
    let valid = true
    const valueTime = parseFloat(value.replace(':', ''))
    const validTarget = !Array.isArray(target) ? [target] : target
    validTarget.forEach(elementValue => {
      const elementValueTime = parseFloat(elementValue.replace(':', ''))
      if (valueTime === elementValueTime) {
        valid = false
      }
    })
    return valid
  },
  message: 'distinct_time',
})

extend('refreshTime', {
  params: ['target'],
  validate(value, { target }) {
    let valid = true
    const valueTime = parseFloat(value.replace(':', ''))
    const validTarget = !Array.isArray(target) ? [target] : target
    validTarget.forEach(elementValue => {
      const elementValueTime = parseFloat(elementValue.replace(':', ''))
      if (Math.abs(valueTime - elementValueTime) < 100) {
        valid = false
      }
    })
    return valid
  },
  message: 'min_interval_time',
})

extend('alpha_unicode_num_dash_spaces', {
  validate(value) {
    return {
      required: false,
      valid: /^[\s\p{L}_\w]+$/u.test(value),
    }
  },
})

extend('domain', {
  validate(value) {
    return {
      required: false,
      valid: /^(https?:\/\/)?([a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(value),
    }
  },
})
