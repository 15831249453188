export default {
  namespaced: true,
  state: {
    active: false,
    blockedPhrase: '',
    rowIndex: null,
  },
  mutations: {
    showPopup(state:any) {
      state.active = true
    },
    hideEditPopup(state: any) {
      state.blockedPhrase = ''
      state.active = false
    },
    editPopup(state: any, popupData: any) {
      state.active = true
      state.rowIndex = popupData.rowIndex
      state.blockedPhrase = popupData.blockedPhrase
    },
    setActive(state: any, active: boolean) {
      state.active = active
    },
    setBlockedPhrase(state: any, phrase: string) {
      state.blockedPhrase = phrase
    },
  },
}
