import apiRequest from '@/apiRequest'
import { AxiosResponse } from 'axios'
import ApiRequestPaths from '@/apiRequestPaths'
import { SortGroup } from '@/store/sort/sortGroupAdd'
import { AppV3Settings } from '@/store/appV3/moduleAppV3SettingsDef'
import { AppV3Labels } from '@/store/appV3/moduleAppV3LabelsDef'

export interface SearchInstance {
  instanceId: string | null,
  instanceName: string | null,
  catalogId: string | null,
  catalogName: string | null,
}

export interface SearchInstances {
  dataLoading: boolean,
  instances: Array<SearchInstance>,
}

export interface SearchSettings {
  productsPerPage: number,
}

export interface PopularQueriesSettings {
  limit: number,
  interval: number,
}

export interface SearchSuggestion {
  label: string,
  field: string,
}

export interface SearchSuggestions {
  searchId: string,
  suggestions: Array<SearchSuggestion>,
}

export interface SearchSharedField {
  label: string,
  field: string,
}

export interface SearchSharedFields {
  catalogId: string,
  sharedFields: Array<SearchSharedField>,
}

export interface SearchSetSettingsResponse {
  updated: boolean
}

export interface SearchFieldWeight {
  field: string,
  weight: number,
}

export interface SearchFieldWeights {
  searchId: string,
  weights: Array<SearchFieldWeight>,
}

export interface SearchSort {
  field: string,
  label: string,
  name: string,
  order: string,
  position: number,
  script: string | null,
  type: string,
}

export interface SearchSorts {
  searchId: string,
  name: string,
  sorts: Array<SearchSort>,
}

export interface SearchFacetedMenu {
  field: string,
  label: string,
  type: string,
}

export interface SearchFacetedMenus {
  searchId: string,
  facetedmenu: Array<SearchFacetedMenu>
}

export class SearchApiRequest {
  static async getSearchInstances(): Promise<AxiosResponse<SearchInstances>> {
    return apiRequest.get<SearchInstances>(ApiRequestPaths.GET_SEARCH_INSTANCES)
  }

  static async getSuggestions(searchId: string): Promise<AxiosResponse<Array<SearchSuggestion>>> {
    return apiRequest.post<Array<SearchSuggestion>>(ApiRequestPaths.GET_SEARCH_SUGGESTIONS, { searchId })
  }

  static async setSuggestions(suggestions: SearchSuggestions): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post<SearchSetSettingsResponse>(ApiRequestPaths.SET_SEARCH_SUGGESTIONS, suggestions)
  }

  static storeSearchInstance(instance: SearchInstance) {
    return apiRequest.post<SearchInstance>(ApiRequestPaths.STORE_SEARCH_INSTANCES, instance)
  }

  static getFieldWeights(searchId: string): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_FIELD_WEIGHTS, { searchId })
  }

  static setFieldWeights(searchFieldWeights: SearchFieldWeights): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post(ApiRequestPaths.SET_SEARCH_FIELD_WEIGHTS, searchFieldWeights)
  }

  static getSorts(searchId: string): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_SORTS, { searchId })
  }

  static getSortsGroups(searchId: string): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_SORTS_GROUPS, { searchId })
  }

  static moveSortsGroupDown(searchId: string, from: number): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_MOVE_DOWN_SORT_GROUPS, { searchId, from })
  }

  static moveSortsGroupUp(searchId: string, from: number): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_MOVE_UP_SORT_GROUPS, { searchId, from })
  }

  static deleteSortGroup(searchId: string, sortId: string): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.DELETE_SEARCH_SORT_GROUP, { searchId, sortId })
  }

  static storeSortGroup(searchId: string, sortGroup: SortGroup): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.SET_STORE_SORT_GROUP, { searchId, sortGroup })
  }

  static setSort(searchSorts: SearchSorts): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post(ApiRequestPaths.SET_SEARCH_SORTS, searchSorts)
  }

  static getFacetedMenu(searchId: string): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_FACETED_MENU, { searchId })
  }

  static setFacetedMenu(searchFacetedMenus: SearchFacetedMenus): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post(ApiRequestPaths.SET_SEARCH_FACETED_MENU, searchFacetedMenus)
  }

  static getAvailableFields(searchId: string): Promise<AxiosResponse<any>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_FIELDS, { searchId })
  }

  static getPaginationSettings(searchId: string): Promise<AxiosResponse<SearchSettings>> {
    return apiRequest.post(ApiRequestPaths.GET_PAGINATION_SETTINGS, { searchId })
  }

  static setPaginationSettings(searchId: string, productsPerPage: number): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post(ApiRequestPaths.SET_PAGINATION_SETTINGS, { searchId, productsPerPage })
  }

  static getPopularQueriesSettings(searchId: string): Promise<AxiosResponse<PopularQueriesSettings>> {
    return apiRequest.post(ApiRequestPaths.GET_SEARCH_POPULARQUERIES_SETTINGS, { searchId })
  }

  static setPopularQueriesSettings(searchId: string, limit: number, interval: number): Promise<AxiosResponse<SearchSetSettingsResponse>> {
    return apiRequest.post(ApiRequestPaths.SET_SEARCH_POPULARQUERIES_SETTINGS, { searchId, limit, interval })
  }

  static getAppV3Settings(searchId: string) {
    const settingsUrl = ApiRequestPaths.APPV3_SETTINGS.replace('{searchId}', searchId)
    return apiRequest.get(settingsUrl)
  }

  static setAppV3Settings(searchId: string, settings: AppV3Settings) {
    const settingsUrl = ApiRequestPaths.APPV3_SETTINGS.replace('{searchId}', searchId)
    return apiRequest.post(settingsUrl, { searchId, ...settings })
  }

  static getAppV3Labels(searchId: string) {
    const settingsUrl = ApiRequestPaths.APPV3_LABELS.replace('{searchId}', searchId)
    return apiRequest.get(settingsUrl)
  }

  static setAppV3Labels(searchId: string, labels: AppV3Labels) {
    const settingsUrl = ApiRequestPaths.APPV3_LABELS.replace('{searchId}', searchId)
    return apiRequest.post(settingsUrl, { searchId, labels })
  }
}
