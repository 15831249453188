import apiRequest from '@/apiRequest'
import i18n from '@/libs/i18n'
import Notifications from '@/store/notifications/notifications'

export default {
  namespaced: true,
  state: {
    domainsList: [],
    dataLoading: false,
  },
  mutations: {
    setAuthDomainsList(state: any, data: any) {
      state.domainsList = data
      state.dataLoading = true
    },
    addDomain(state: any, data: string) {
      if (state.domainsList.indexOf(data) === -1) {
        state.domainsList.push(data)
      }
    },
    removeDomain(state: any, data: any) {
      state.domainsList.splice(data, 1)
    },
  },
  getters: {
    getDomains(state: any) {
      return state.domainsList
    },
  },
  actions: {
    getData(context: any) {
      apiRequest
        .get('catalog/getAuthDomainsList')
        .then(result => {
          context.commit('setAuthDomainsList', result.data)
        })
        .catch(error => {
        })
    },
    saveDomains(context: any) {
      apiRequest.post('catalog/setAuthDomainsList', { domains: context.getters.getDomains })
      Notifications.commit('addPopupInfo', {
        name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
        message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
      }, { root: true })
    },
  },
}
