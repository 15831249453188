import Vue from 'vue'
import VueI18n from 'vue-i18n'

import cz from './locales/cz'
import en from './locales/en'
import pl from './locales/pl'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    cz,
    pl,
  },
  pluralizationRules: {
    pl(choice) {
      if (choice === 0) {
        return 0
      }
      if (choice === 1) {
        return 1
      }
      if ([2, 3, 4].includes(choice % 10)) {
        return 2
      }
      return 3
    },
    cz(choice) {
      if (choice === 0) {
        return 0
      }
      if (choice === 1) {
        return 1
      }
      if ([2, 3, 4].includes(choice)) {
        return 2
      }
      return 3
    },
  },
})
