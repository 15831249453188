import Notifications from '@/store/notifications/notifications'
import Vuex from 'vuex'
import Vue from 'vue'
import moduleIndustries from '@/store/industries/moduleIndustries'
import moduleLanguages from '@/store/languages/moduleLanguages'
import moduleCurrencies from '@/store/currencies/moduleCurrencies'
import CatalogApiRequest from './catalogApiRequest'

interface CatalogState {
  chosenIndustry: string | null,
  chosenLanguage: string | null,
  chosenCurrency: string | null,
  catalogLink: string | null,
  catalogName: string | null,

  isLoadingCatalog: boolean,
}

const getInitialState = (): CatalogState => ({
  chosenIndustry: null,
  chosenLanguage: null,
  catalogLink: null,
  catalogName: null,
  chosenCurrency: null,
  isLoadingCatalog: false,
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setChosenIndustry(state: CatalogState, industry: string | null) {
      state.chosenIndustry = industry
    },
    setChosenLanguage(state: CatalogState, chosenLanguage: string | null) {
      state.chosenLanguage = chosenLanguage
    },
    setChosenCurrency(state: CatalogState, chosenCurrency: string | null) {
      state.chosenCurrency = chosenCurrency
    },
    setCatalogLink(state: CatalogState, link: string | null) {
      state.catalogLink = link
    },
    setCatalogName(state: CatalogState, name: string | null) {
      state.catalogName = name
    },
    setCatalogSettings(state: CatalogState, catalogSettings: any) {
      state.chosenIndustry = catalogSettings.industryId
      state.chosenLanguage = catalogSettings.languageCode.toLowerCase()
      state.chosenCurrency = catalogSettings.currencyCode
      state.catalogLink = catalogSettings.catalogUri
      state.catalogName = catalogSettings.catalogName
    },
    resetCatalogSettings(state: CatalogState) {
      Object.assign(state, getInitialState())
    },
  },

  actions: {
    loadCatalogSettings(context: any) {
      const response = CatalogApiRequest.getWizardCatalogSettings()

      return response
        .then(result => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'catalog.errorReceivingCatalogSettings', { root: true })
            return
          }

          const { data } = result

          if (data === undefined) {
            context.commit('resetCatalogSettings')
            return
          }

          context.commit('setCatalogSettings', data)
        })
        .catch(error => {
          Notifications.commit('addError', error.message, { root: true })
        })
    },
    async saveCatalogSettings(context: any) {
      const catalogSettings = context.getters.getCatalogSettings
      return CatalogApiRequest.setWizardCatalogSettings(catalogSettings)
    },
    initCatalogSettings(context: any) {
      return Promise.all([
        moduleIndustries.dispatch('loadIndustries', {}, { root: true }),
        moduleLanguages.dispatch('loadLanguages', {}, { root: true }),
        moduleCurrencies.dispatch('loadCurrencies', {}, { root: true }),
        context.dispatch('loadCatalogSettings'),
      ])
    },
    setCatalogName(context: any, name: string | null) {
      context.commit('setCatalogName', name)
    },
    setCatalogLink(context: any, link: string) {
      context.commit('setCatalogLink', link)
    },
  },

  getters: {
    getCatalogSettings(state: CatalogState) {
      return {
        languageCode: state.chosenLanguage,
        currencyCode: state.chosenCurrency,
        industryId: state.chosenIndustry,
        linkUri: state.catalogLink,
        name: state.catalogName,
      }
    },
    getChosenIndustry(state: CatalogState) {
      return state.chosenIndustry
    },

    getChosenLanguage(state: CatalogState) {
      return state.chosenLanguage
    },

    getChosenCurrency(state: CatalogState) {
      return state.chosenCurrency
    },

    getCatalogLink(state: CatalogState) {
      return state.catalogLink
    },

    getCatalogName(state: CatalogState) {
      return state.catalogName
    },
  },
})
