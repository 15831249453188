import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import locale from './locale'
import moduleSearch from './search/moduleSearch'
import moduleSearchCatalogWizard from './searchCatalogWizard/moduleSearchCatalogWizard'
import moduleSearchCatalogMapping from './searchCatalogMapping/moduleSearchCatalogMapping'
import moduleAuth from './auth/moduleAuth'
import searchPhraseReport from './searchReport/phrase'
import searchReportPhraseWithNoResults from './searchReport/phraseWithNoResults'
import searchProductReport from './searchReport/product'
import blockedPhrase from './blockedPhrases/blokedPhrases'
import blockedPhraseEditPopup from './blockedPhrases/editPopup'
import htmlTemplate from './htmlTemplate/htmlTemplate'
import htmlTemplateEditor from './htmlTemplate/htmlTemplateEditor'
import catalogRefreshSettings from './catalog/catalogRefreshSettings'
import suggestion from './suggestion/suggestion'
import sharedFields from './sharedFields/sharedFields'
import searchWeight from './searchWeight/searchWeight'
import facetedMenu from './searchFacatedMenu/facetedMenu'
import authorizationWhitelist from './searchResults/authorizationWhitelist'
import authorizationKey from './searchResults/authorizationKey'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    locale,
    auth: moduleAuth,
    search: moduleSearch,
    catalogWizard: moduleSearchCatalogWizard,
    searchCatalogMapping: moduleSearchCatalogMapping,
    searchPhraseReport,
    searchReportPhraseWithNoResults,
    searchProductReport,
    blockedPhrase,
    blockedPhraseEditPopup,
    htmlTemplate,
    htmlTemplateEditor,
    catalogRefreshSettings,
    suggestion,
    sharedFields,
    searchWeight,
    facetedMenu,
    authorizationWhitelist,
    authorizationKey,
  },
  strict: process.env.DEV,
})
