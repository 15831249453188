import i18n from '@/libs/i18n'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import { AvailableFields } from '@/store/search/AvailableFields'
import Notifications from '@/store/notifications/notifications'
import SearchInstanceStore from '@/store/search/searchInstance'

interface searchItemWeightInterface {
  field: string,
  weight: number,
  exact: boolean,
  fullMatch: boolean,
  match: boolean,
}

interface searchWeightInterface {
  showDescription: boolean;
  availableFields: Array<any>;
  weights: Array<any>;
  disabledFields: Array<any>;
  storeTimeout: null | any
}

export default {
  namespaced: true,

  state: <searchWeightInterface>{
    showDescription: false,
    availableFields: [],
    weights: [],
    disabledFields: ['price', 'image_link', 'link'],
    storeTimeout: null,
  },
  mutations: {
    setAvailableFields(state: searchWeightInterface, data: any) {
      state.availableFields = data.filter((field: any) => state.disabledFields.indexOf(field) < 0)
    },
    setWeights(state: searchWeightInterface, data: any) {
      state.weights = data.data
    },
    addWeight(state: searchWeightInterface, data: any) {
      if (state.weights === null) {
        state.weights = []
      }
      let find = false
      state.weights.forEach((element: any) => {
        if (element.field === data.field) {
          find = true
        }
      })
      if (!find) {
        state.weights.push(data)
      }
    },
    setWeightKey(state: searchWeightInterface, data: any) {
      state.weights[data.key].weight = data.value
    },
    removeWeight(state: searchWeightInterface, data: any) {
      state.weights.splice(data.key, 1)
    },
    setShowDescription(state: searchWeightInterface, data: boolean) {
      state.showDescription = data
    },
    setStoreTimeout(state: searchWeightInterface, timeout: any) {
      state.storeTimeout = timeout
    },
  },
  getters: {
    getWeights(state: searchWeightInterface) {
      return state.weights
    },
    getShowDescription(state: searchWeightInterface) {
      return state.showDescription
    },
    getStoreTimeout(state: searchWeightInterface) {
      return state.storeTimeout
    },
    getFixedStrategyWeights(state: searchWeightInterface) {
      const fixedWeights = state.weights.map((itemWeight : searchItemWeightInterface) => {
        itemWeight.match = !itemWeight.fullMatch
        itemWeight.exact = false
        return itemWeight
      })
      return fixedWeights
    },
  },
  actions: {
    getData(context: any, searchId: string) {
      SearchApiRequest.getAvailableFields(searchId)
        .then((result: any) => {
          context.commit('setAvailableFields', AvailableFields.getAvailableFieldsFromResponse(result.data))
          SearchApiRequest.getFieldWeights(searchId)
            .then((weightResponse: any) => {
              context.commit('setWeights', weightResponse.data)
            })
        })
    },
    storeWeights(context: any, searchId: string) {
      const actualTimeout = context.getters.getStoreTimeout
      if (actualTimeout) {
        clearTimeout(actualTimeout)
      }
      const timeout = setTimeout(() => {
        SearchApiRequest.setFieldWeights({
          searchId,
          weights: context.getters.getFixedStrategyWeights,
        }).then(() => {
          Notifications.commit('addPopupInfo', {
            name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
            message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
          }, { root: true })
        })
      }, 2000)

      context.commit('setStoreTimeout', timeout)
    },
    add(context: any, data: any) {
      context.commit('addWeight', data)
      context.dispatch('storeWeights', SearchInstanceStore.state.searchInstanceId)
    },
    setWeightKey(context: any, data: any) {
      context.commit('setWeightKey', data)
      context.dispatch('storeWeights', SearchInstanceStore.state.searchInstanceId)
    },
    removeWeight(context: any, data: any) {
      context.commit('removeWeight', data)
      context.dispatch('storeWeights', SearchInstanceStore.state.searchInstanceId)
    },
    setShowDescription(context: any, data: boolean) {
      context.commit('setShowDescription', data)
    },
  },
}
