import CatalogApiRequest from '@/store/catalog/catalogApiRequest'
import i18n from '@/libs/i18n'
import Notifications from '@/store/notifications/notifications'

interface CustomerPeriods extends Array<any> {
  date: Array<string | null>
}

interface CustomerPeriodsPayload {
  periods: Array<string>
  catalogId?: string
}

const preparePayload = (customerPeriods: CustomerPeriods): CustomerPeriodsPayload => {
  const output: CustomerPeriodsPayload = {
    periods: [],
  }

  const arr: any[] = []
  customerPeriods.forEach(period => {
    const { date } = period
    const hour = date.split(':')[0]
    const minutes = date.split(':')[1]
    arr.push(`${(`0${hour}`).substr(-2)}:${(`0${minutes}`).substr(-2)}`)
  })
  output.periods = [...new Set(arr)]

  return output
}

export default {
  namespaced: true,
  state: {
    customerPeriods: [],
  },
  mutations: {
    setCustomerPeriods(state: any, customerPeriods: CustomerPeriods) {
      state.customerPeriods = customerPeriods
    },
    addNewDate(state: any, date: string) {
      state.customerPeriods.push(date)
    },
  },
  actions: {
    async storeCatalogPeriodicData(context: any, catalogId: string): Promise<void> {
      const actualSettings = preparePayload(context.getters.getCatalogRefreshSettings)
      if (catalogId !== undefined) {
        actualSettings.catalogId = catalogId
      }
      await CatalogApiRequest.setCatalogRefreshSettings(actualSettings)
        .then(() => {
          Notifications.commit('addPopupInfo', {
            name: i18n.t('catalog.refreshSettings.success'),
            message: i18n.t('catalog.refreshSettings.periodsSaved'),
          }, { root: true })
        }).catch(error => {
          Notifications.commit('addPopupError', error, { root: true })
        })
    },
    async getCatalogPeriodicData(context: any, catalogId: string): Promise<void> {
      const catalogFromBase = (catalogId === undefined)
        ? await CatalogApiRequest.getCatalogRefreshSettings()
        : await CatalogApiRequest.getImportPeriodsForCatalogInstance(catalogId)
      const periods = catalogFromBase?.data?.periods
      const customerPeriods = periods.length > 0
        ? periods.map((date: string) => ({
          date,
        })) : [{
          date: `${new Date().getHours()}:${new Date().getMinutes()}`,
        }]
      context.commit('setCustomerPeriods', customerPeriods)
    },

    addNewDate(context: any, date: string) {
      context.commit('addNewDate', date)
    },

  },
  getters: {
    getCatalogRefreshSettings(state: any): CustomerPeriods {
      return state.customerPeriods
    },
  },

}
