import i18n from '@/libs/i18n'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import { AvailableFields } from '@/store/search/AvailableFields'
import Notifications from '@/store/notifications/notifications'
import SearchInstanceStore from "@/store/search/searchInstance";

export default {
  namespaced: true,
  state: {
    availableFields: [],
    facetedItems: [],
    disabledFields: ['image_link', 'link', 'title', 'id'],
  },
  mutations: {
    setAvailableFields(state:any, data:any) {
      state.availableFields = data.filter((field: any) => state.disabledFields.indexOf(field.path) < 0)
    },
    setFacetedItems(state:any, data:any) {
      state.facetedItems = data.facetedmenu
    },
    addItem(state:any, data:any) {
      state.facetedItems.push(data.item)
    },
    moveItem(state:any, data:any) {
      if (data.to < 0) {
        return
      }
      if (data.to > state.facetedItems.length) {
        return
      }
      state.facetedItems.splice(data.to, 0, state.facetedItems.splice(data.from, 1)[0])
    },
    removeItem(state:any, data:any) {
      state.facetedItems.splice(data.key, 1)
    },
  },
  getters: {
    getFacetedItems(state:any) {
      return state.facetedItems
    },
  },
  actions: {
    getData(context: any, searchId: string) {
      SearchApiRequest.getAvailableFields(searchId).then((result: any) => {
        context.commit('setAvailableFields', result.data)
        SearchApiRequest.getFacetedMenu(searchId).then((facetedMenuResponse: any) => {
          context.commit('setFacetedItems', facetedMenuResponse.data)
        })
      })
    },
    save(context:any, searchId: string) {
      SearchApiRequest.setFacetedMenu({
        searchId,
        facetedmenu: context.getters.getFacetedItems,
      }).then(facetedMenuResponse => {
        Notifications.commit('addPopupInfo', {
          name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
          message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
        }, { root: true })
      })
    },
    add(context: any, data: any) {
      context.commit('addItem', data)
      context.dispatch('save', SearchInstanceStore.state.searchInstanceId)
    },
    move(context: any, data: any) {
      context.commit('moveItem', data)
      context.dispatch('save', SearchInstanceStore.state.searchInstanceId)
    },
    remove(context: any, data: any) {
      context.commit('removeItem', data)
      context.dispatch('save', SearchInstanceStore.state.searchInstanceId)
    },
  },
}
