import apiRequest from '@/apiRequest'
import jwt from '@/auth/qonJwt'

export default {
  namespaced: true,
  mutations: {
    SET_BEARER(state, accessToken) {
      apiRequest.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    },
  },
  actions: {
    /**
     * Get token data by hash provided from old control panel
     * @param commit
     * @param {string} hash
     * @returns {Promise}
     */
    getTokenDataByHash({ commit }, hash) {
      return new Promise((resolve, reject) => {
        jwt.getTokenDataByHash(hash)
          .catch(error => reject(error))
          .then(response => {
            if (!response.data.access_token) {
              reject({ message: 'Wrong credentials.' })
            }
            commit('SET_BEARER', response.data.access_token)
            resolve(response.data)
          })
      })
    },

    /**
     * Get profile data
     * @returns {Promise}
     */
    getUserProfile() {
      return jwt.getProfile()
    },

    /**
     * Fetch access token
     * @returns {Promise}
     */
    refreshToken({ commit }) {
      return new Promise((resolve, reject) => {
        jwt.refreshToken()
          .catch(error => reject(error))
          .then(response => {
            if (!response.data.access_token) {
              reject({ message: 'Bad token.' })
            }
            commit('SET_BEARER', response.data.access_token)
            resolve(response.data)
          })
      })
    },

    /**
     * Logout
     * @returns {Promise}
     */
    logout() {
      return jwt.logout()
    },
  },
  getters: {},
}
