import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface SuggestedField {
  description: string,
  qonId: string,
  catalogField: string,
  dataType: string,
  isDeletable: boolean
  separator?: string,
}

interface SuggestedFieldsState {
  SuggestedFields: SuggestedField[]
}

const SuggestedFields = new Vuex.Store({
  state: (): SuggestedFieldsState => (
    {
      SuggestedFields: [
        {
          description: '',
          qonId: 'old_price',
          catalogField: 'g:old_price',
          dataType: 'number',
          separator: '',
          isDeletable: true,
        },
        {
          description: '',
          qonId: 'omnibus_price',
          catalogField: 'g:omnibus_price',
          dataType: 'number',
          separator: '',
          isDeletable: true,
        },
      ],
    }
  ),
})

export default SuggestedFields
