import apiRequest from '@/apiRequest'
import { getInitialState, HtmlTemplate } from '@/store/htmlTemplate/htmlTemplate'
import { AxiosResponse } from 'axios'
import store from '@/store'

export interface HtmlTemplateEditor {
  numberOfProducts: number,
  widgetId: number,
  editedTemplate: HtmlTemplate,
  customerTemplates: Array<HtmlTemplate>,
  snippetPopupSettings: PopupSettings,
  editSettingsPopup: PopupSettings,
  templatesCodePopup: PopupSettings
}

export interface PopupSettings {
  active: boolean,
  rowIndex: number | null
}

export default {
  namespaced: true,
  state: {
    numberOfProducts: 6,
    widgetId: 14418,
    editedTemplate: getInitialState(),
    customerTemplates: [],
    snippetPopupSettings: {
      active: false,
      rowIndex: null,
    },
    editSettingsPopup: {
      active: false,
      rowIndex: null,
    },
    templatesCodePopup: {
      active: false,
      rowIndex: null,
    },
  },
  mutations: {
    setEditedTemplate(state: HtmlTemplateEditor, rowIndex: number) {
      state.editedTemplate = state.customerTemplates[rowIndex]
    },
    setNumberOfProducts(state: HtmlTemplateEditor, numberOfProducts: number) {
      state.numberOfProducts = numberOfProducts
    },
    setHtml(state: HtmlTemplateEditor, html: string) {
      state.editedTemplate.html = html
    },
    setWidgetId(state: HtmlTemplateEditor, widgetId: number) {
      state.widgetId = widgetId
    },
    async getAllTemplates(state: HtmlTemplateEditor): Promise<void> {
      state.customerTemplates = []
      apiRequest
        .get('htmlTemplate/getAllTemplates')
        .then((response: AxiosResponse<Array<HtmlTemplate>>) => {
          state.customerTemplates = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    setSnippetPopupSettings(state: HtmlTemplateEditor, popupSettings: PopupSettings) {
      state.snippetPopupSettings = popupSettings
    },
    setEditSettingsPopup(state: HtmlTemplateEditor, popupSettings: PopupSettings) {
      state.editSettingsPopup = popupSettings
    },
    setTemplatesCodePopup(state: HtmlTemplateEditor, popupSettings: PopupSettings) {
      state.templatesCodePopup = popupSettings
    },
    updateDataInRow(state: HtmlTemplateEditor, rowIndex: number): void {
      Object.assign(state.customerTemplates[rowIndex], state.editedTemplate)
    },
    async updateTemplate(state: HtmlTemplateEditor, rowIndex: number): Promise<void> {
      const payload = state.editedTemplate
      apiRequest
        .post('htmlTemplate/update', payload)
        .then(() => {
          store.commit('htmlTemplateEditor/updateDataInRow', rowIndex)
          store.commit('notifications/addPopupInfo', {
            name: 'Sukces',
            message: 'Szablon został zmieniony',
          })
          console.log('htmlTemplate stored', payload)
        })
        .catch(error => {
          store.commit('notifications/addPopupError', {
            name: 'Błąd',
            message: 'Szablon nie został zapisany',
          })
          console.log(error)
        })
    },
  },
  actions: {
    storeHtmlTemplate(context: any): void {
      context.commit('storeHtmlTemplate')
    },
    updateDataInRow(context: any, rowIndex: number): void {
      context.commit('updateDataInRow', rowIndex)
    },
    updateTemplate(context: any, rowIndex: number): void {
      context.commit('updateTemplate', rowIndex)
    },
    storeSnippetPopupSettings(context: any, popupSettings: PopupSettings) {
      context.commit('setSnippetPopupSettings', popupSettings)
    },
    storeEditSettingsPopup(context: any, popupSettings: PopupSettings) {
      context.commit('setEditSettingsPopup', popupSettings)
    },
    storeTemplatesCodePopup(context: any, popupSettings: PopupSettings) {
      context.commit('setTemplatesCodePopup', popupSettings)
    },
    getAllTemplates(context: any): void {
      context.commit('getAllTemplates')
    },
    setEditedTemplate(context: any, rowIndex: number): void {
      context.commit('setEditedTemplate', rowIndex)
    },
    setWidgetId(context: any, widgetId: number): void {
      context.commit('setWidgetId', widgetId)
    },
    setNumberOfProducts(context: any, numberOfProducts: string): void {
      context.commit('setNumberOfProducts', numberOfProducts)
    },
    setHtml(context: any, html: string): void {
      context.commit('setHtml', html)
    },
  },
  getters: {
    getWidgetId(state: HtmlTemplateEditor): number {
      return state.widgetId
    },
    getSnippetPopupSettings(state: HtmlTemplateEditor): PopupSettings {
      return state.snippetPopupSettings
    },
    getEditSettingsPopup(state: HtmlTemplateEditor): PopupSettings {
      return state.editSettingsPopup
    },
    getTemplatesCodePopup(state: HtmlTemplateEditor): PopupSettings {
      return state.templatesCodePopup
    },
    getNumberOfProductsFromStore(state: HtmlTemplateEditor): number {
      return state.numberOfProducts
    },
    getEditedTemplate(state: HtmlTemplateEditor): HtmlTemplate {
      return state.editedTemplate
    },
    getCustomersTemplates(state: HtmlTemplateEditor): Array<HtmlTemplate> {
      return state.customerTemplates
    },
    getHeightWithPxPostfix(state: HtmlTemplateEditor): string {
      return state.editedTemplate.height.toString().concat('px')
    },
    getWidthWithPxPostfix(state: HtmlTemplateEditor): string {
      return state.editedTemplate.width.toString().concat('px')
    },
    getProductPreviewSnippet(state: HtmlTemplateEditor): string {
      return state.editedTemplate.html
        .replaceAll('%%image%%', 'https://semilac.pl/media/catalog/product/cache/56044bb122288a1b09e2c2cd466f7c22/6/5/658_1200.jpg')
        .replaceAll('%%price%%', '34,99')
        .replaceAll('%%title%%', '658 PYŁEK SEMILAC FLASH HOLO INSTINCT')
        .replaceAll('%%oldPriceHidden%%', '')
        .replaceAll('%%oldPrice%%', '45.99')
    },
  },
}
