import apiRequest from '@/apiRequest'
import i18n from '@/libs/i18n'
import Notifications from '@/store/notifications/notifications'

interface PhraseRequest {
  phrase: string
  index: number
}

interface BlockedPhrases {
  blocked: Array<string>
}

export default {
  namespaced: true,
  state: {
    dataLoading: true,
    totalPages: 1,
    limit: 50,
    results: [],
    page: 1,
    sort: 'asc',
    orderBy: 'phrase',
  },
  mutations: {
    setDataLoading(state:any, isLoading:boolean) {
      state.dataLoading = isLoading
    },
    setResults(state:any, results:any) {
      state.results = results
      state.dataLoading = false
    },
    setTotalPages(state:any, totalPages:number) {
      state.totalPages = Math.ceil(totalPages / state.limit)
    },
    updateDataInRow(state:any, updateData:PhraseRequest) {
      console.dir(state.results[updateData.index])
      console.dir(updateData.phrase)

      state.results[updateData.index].phrase = updateData.phrase
      console.dir(state.results[updateData.index])
    },
    deleteIndex(state:any, index:number) {
      state.results.splice(index, 1)
    },
    setPage(state:any, page:string) {
      state.page = page
    },
    setSort(state:any, sort:string) {
      state.sort = sort
    },
    setOrderBy(state:any, orderBy:string) {
      state.orderBy = orderBy
    },
  },
  actions: {
    getData(context: any) {
      context.commit('setDataLoading', true)
      const requestGetParams = new URLSearchParams({
        sort: context.state.sort,
        orderBy: context.state.orderBy,
        page: context.state.page,
      })
      apiRequest
        .get(`search/blockedPhrase?${requestGetParams.toString()}`)
        .then(result => {
          context.commit('setTotalPages', result.data.total)
          context.commit('setResults', result.data.data)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
          context.commit('setDataLoading', false)
        })
    },
    async storePhrases(context:any, phrases: BlockedPhrases) {
      context.commit('setDataLoading', true)
      apiRequest
        .post('search/blockedPhrase', phrases)
        .then(() => {
          Notifications.commit('addPopupInfo', {
            name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
            message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
          }, { root: true })

          context.dispatch('getData')
            .finally(
              context.commit('setDataLoading', false),
            )
        })
        .catch(error => {
          Notifications.commit('addPopupError', error, { root: true })
          context.commit('setDataLoading', false)
        })
    },
    updatePhrase(context:any, storeRequest: PhraseRequest) {
      context.commit('setDataLoading', true)

      const actualPhrase = context.state.results[storeRequest.index].phrase
      apiRequest
        .put(`search/blockedPhrase/${actualPhrase}`, {
          blockedPhrase: storeRequest.phrase,
        })
        .then(() => {
          context.commit('updateDataInRow', storeRequest)
          context.commit('blockedPhraseEditPopup/hideEditPopup', {}, { root: true })
          context.commit('setDataLoading', false)
          Notifications.commit('addPopupInfo', {
            name: i18n.t('search.blockedPhrase.saveSuccessfulNotificationTitle'),
            message: i18n.t('search.blockedPhrase.saveSuccessfulNotification'),
          }, { root: true })
        })
        .catch(error => {
          Notifications.commit('addPopupError', error, { root: true })
          context.commit('setDataLoading', false)
        })
    },
    deletePhrase(context:any, deletePhrase: PhraseRequest) {
      context.commit('setDataLoading', true)

      if (context.state.results.length === 1 && context.state.page > 1) {
        context.commit('setPage', context.state.page - 1)
      }
      apiRequest
        .delete(`search/blockedPhrase/${deletePhrase.phrase}`)
        .then(() => {
          context.commit('deleteIndex', deletePhrase.index)
          context.dispatch('getData')
            .finally(
              context.commit('setDataLoading', false),
            )
        })
        .catch(error => {
          Notifications.commit('addPopupError', error, { root: true })
          context.commit('setDataLoading', false)
        })
    },
  },

}
