const userDataStoreKey = 'qonUserInfo'
const userDefaults = { features: [], modules: [], ...JSON.parse(localStorage.getItem(userDataStoreKey)) ?? {} }

export default {
  namespaced: true,
  state: {
    appActiveUser: userDefaults,
  },
  getters: {
    userData(state) {
      return state.appActiveUser
    },
  },
  mutations: {
    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
      // Get Data localStorage
      const userInfo = { ...state.appActiveUser, ...JSON.parse(localStorage.getItem(userDataStoreKey)) ?? {} }
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.appActiveUser
          state.appActiveUser[property] = payload[property]

          // Update key in localStorage
          userInfo[property] = payload[property]
        }
      }
      // Store data in localStorage
      localStorage.setItem(userDataStoreKey, JSON.stringify(userInfo))
    },
  },
}
