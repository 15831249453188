import apiRequest from '@/apiRequest'
import i18n from '@/libs/i18n'
import Notifications from '@/store/notifications/notifications'

export default {
  namespaced: true,
  state: {
    url: '',
    key: '',
  },
  mutations: {
    setAuthKey(state:any, data:any) {
      state.url = data.url
      state.key = data.key
    },
  },
  actions: {
    getData(context: any) {
      return apiRequest
        .get('catalog/getAuthKey')
        .then(result => {
          context.commit('setAuthKey', result.data)
          return result.data
        })
        .catch(error => {
        })
    },
    resetKey(context: any) {
      apiRequest
        .get('catalog/resetAuthKey')
        .then(result => {
          context.commit('setAuthKey', result.data)
          Notifications.commit('addPopupInfo', {
            name: i18n.t('notifications.apiKeyReset.name'),
            message: i18n.t('notifications.apiKeyReset.message'),
          }, { root: true })
        })
        .catch(error => {
        })
    },
  },
}
